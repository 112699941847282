import { OutBannerDTO } from "@app/bf-api";

export interface IExtendedBannerDTO extends OutBannerDTO {
  showAlternativeBanner: boolean;
  usage: number;
}

export interface ILocalStorageBanner {
  id: number;
  usage: number;
}

export const getBannersFromLocalStorage = (banners: OutBannerDTO[]): IExtendedBannerDTO[] => {
  const bannerJSON = localStorage.getItem("banners") || "";
  const localStorageBanners: ILocalStorageBanner[] = bannerJSON ? JSON.parse(bannerJSON) : [];

  const extendedBanners: IExtendedBannerDTO[] = banners.map(banner => {
    const localStorageBanner = localStorageBanners.find(localBanner => localBanner.id === banner.id);

    return { ...banner, showAlternativeBanner: false, usage: localStorageBanner ? localStorageBanner.usage : 0 };
  });

  extendedBanners.sort((a, b) => {
    if (b.usage > a.usage) {
      return -1;
    }

    return 0;
  });

  return extendedBanners;
};

export const saveBannerSeenInLocalStorage = (id: number) => {
  const bannerJSON = localStorage.getItem("banners") || "";
  let localStorageBanners: ILocalStorageBanner[] = bannerJSON ? JSON.parse(bannerJSON) : [];

  const localBanner = localStorageBanners.find(item => item.id === id);

  if (!localBanner) {
    localStorageBanners.push({
      id,
      usage: 1
    });
  } else {
    localStorageBanners = localStorageBanners.map(item => {
      if (item.id === id) {
        item.usage += 1;
      }

      return item;
    });
  }

  localStorage.setItem("banners", JSON.stringify(localStorageBanners));
};
