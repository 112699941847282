import React, { useEffect, useState } from "react";
import { Sticky } from "react-sticky";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";

import { INavBarModule } from "@app/api/modules/nav-bar/navbar.module";
import { ImageComponent } from "@app/core";
import { usePrevious, useScrollPosition } from "@app/util";
import { formatMessage } from "@app/translations/intl";
import { NAVIGATION_HEIGHT_DEFAULT } from "@app/constants/overviews-numbers";

import { MenuItems } from "./menu-items/menu-items.component";
import { NavbarSearch } from "./navbar-search/navbar-search.component";
import styles from "./new-nav-bar-component.module.scss";

export interface INewNavBarComponentProps {
  navBarModule: INavBarModule;
}

// isolate NavBar as a stateless component,
// so that React.memo (within ImageComponent) won't re-render like it shouldn't.
const NavBar = (props: INewNavBarComponentProps & RouteComponentProps) => {
  const onHomeClick = () => {
    props.history.push(formatMessage({ id: "global.homepageLink" }));
    window.scroll(0, 0);
  };

  return (
    <div className={styles.navbar}>
      <div className="uk-container">
        <div className={styles.top}>
          <div className={styles.logo}>
            <a role="button" onClick={onHomeClick}>
              <ImageComponent
                src={props.navBarModule.logo.src}
                alt={props.navBarModule.logo.alt}
                title={props.navBarModule.logo.title}
                isBlocking
              />
            </a>
          </div>
          <NavbarSearch
            popularMerchants={props.navBarModule.popularMerchants}
            popularDeals={props.navBarModule.popularDeals}
          />
          <Link to={props.navBarModule.navBarLink || ""} className={styles.rightText}>
            <span className={styles.title}>{props.navBarModule.navBarTitle}</span>
            <span className={styles.subtitle}>{props.navBarModule.navBarSubTitle}</span>
          </Link>
        </div>
      </div>
      <div className={styles.bottom}>
        <div className="uk-container">
          <MenuItems items={props.navBarModule.menuItems} />
        </div>
      </div>
    </div>
  );
};

const component = (props: INewNavBarComponentProps & RouteComponentProps) => {
  const [isStickyHook, setIsSticky] = useState<boolean>(false);
  const [showSticky, setShowSticky] = useState<boolean>(true);

  // tslint:disable-next-line: no-shadowed-variable

  const scrollPos = useScrollPosition(0);
  const prevScrollPosition = usePrevious(scrollPos) || 0;

  useEffect(() => {
    if (scrollPos < NAVIGATION_HEIGHT_DEFAULT) {
      setIsSticky(false);
    } else {
      setIsSticky(true);
    }
    if (prevScrollPosition > scrollPos) {
      setShowSticky(true);
    } else if (prevScrollPosition < scrollPos) {
      setShowSticky(false);
    }
  }, [scrollPos]);

  if (isStickyHook) {
    return (
      <Sticky isActive={false}>
        {({ style, isSticky }) => (
          <div
            style={{
              ...style,
              zIndex: 500,
              transform: showSticky ? "translateY(0%)" : "translateY(-100%)",
              transition: "0s"
            }}
          >
            <NavBar {...props} />
          </div>
        )}
      </Sticky>
    );
  }

  return <NavBar {...props} />;
};

export const DesktopNavBar = withRouter(component);
