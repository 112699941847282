import React, { useEffect, useState } from "react";
import clsx from "clsx";

import DealIcon from "@assets/icons/medal.svg";
import { FeaturedDealsModule } from "@app/api/modules/featured-deals/featured-deals.module";
import { IOutFeaturedDealDTO } from "@app/core/new-deal-card/deal-card.interface";
import {
  getCorrectFeaturedDeals,
  getFeaturedDealsTitleWithWildCards,
  mapDealsToFeaturedDeals
} from "@app/api/wp-page-fetcher/module-selector/module-mappers";
import { useAppSelector } from "@app/redux/store";
import { ViewType } from "@app/redux/reducers/settings";
import { LinkComponent } from "@app/core/link";
import { getArrow } from "@app/constants/icons";
import Typography from "@app/components/typography";

import { DealCardWrapper } from "../deal-card-wrapper/deal-card-wrapper";
import { SeeMoreCardWrapper } from "../see-more-card-wrapper/see-more-card-wrapper";
import styles from "./featured-deals-component.module.scss";

export interface IFeaturedDealsComponentProps {
  featuredDealsModule: FeaturedDealsModule;
}

const AMOUNT_OF_ITEMS_MOBILE = 8;

const FeaturedDeals = (props: IFeaturedDealsComponentProps) => {
  const { currentMerchant } = useAppSelector(state => state.merchantOverview);
  const { screenSize, platformId } = useAppSelector(state => state.settings);
  const [merchantSingleFilters, setMerchantSingleFilters] = useState<string | undefined>(undefined);
  const title = getFeaturedDealsTitleWithWildCards(props.featuredDealsModule.title, currentMerchant?.name);

  const {
    useSeeMoreCard,
    topCtaTitle,
    ctaButton,
    ctaUnderButton,
    ctaDescription,
    featuredDeals,
    isMerchantSinglePage,
    amountOfDeals,
    filterIds,
    dealSelection,
    selectionChoice: isDealSelection,
    pageUrl,
    labelFilter
  } = props.featuredDealsModule;

  const [deals, setDeals] = useState<IOutFeaturedDealDTO[]>(featuredDeals || []);

  useEffect(() => {
    if (currentMerchant && isMerchantSinglePage) {
      const merchantFilterIds = currentMerchant.categories?.map(item => item.id);
      const merchantFilters = merchantFilterIds?.join(";");
      setMerchantSingleFilters(merchantFilters);
      getDeals(merchantFilters, true);
    } else {
      getDeals(undefined, false);
    }
    setMerchantSingleFilters(undefined);
  }, [pageUrl]);

  const getDeals = async (merchantFilters?: string, merchantSinglePage?: boolean): Promise<void> => {
    const newDeals = await getCorrectFeaturedDeals({
      platformId,
      dealSelectionCount: amountOfDeals,
      filterIds,
      selectionChoice: isDealSelection,
      dealSelection,
      merchantId: currentMerchant?.id.toString(),
      isMerchantSinglePage: merchantSinglePage,
      merchantSingleFilters: merchantFilters || merchantSingleFilters,
      labelFilter
    });

    setDeals(mapDealsToFeaturedDeals(newDeals || []));
  };

  const dealsToUse = deals && deals.length > 0 ? deals : featuredDeals;
  const viewType = screenSize ? screenSize.viewType : ViewType.Desktop;

  if (!dealsToUse || dealsToUse.length === 0) {
    return <React.Fragment />;
  }

  const AMOUNT_OF_ITEMS_DESKTOP = 5;
  const amountOfRows = Math.ceil((dealsToUse.length + 1) / AMOUNT_OF_ITEMS_DESKTOP);

  const maxAmountOfItems = {
    1200: amountOfRows * 4 - 1,
    959: amountOfRows * 3 - 1,
    768: 3,
    640: props.featuredDealsModule.amountOfDealsMobile || AMOUNT_OF_ITEMS_MOBILE
  };

  const hideItemOn1200 = (index: number) => index > maxAmountOfItems["1200"] && styles["hide-1200"];
  const hideItemOn959 = (index: number) => index > maxAmountOfItems["959"] && styles["hide-959"];
  const hideItemOn768 = (index: number) => index > maxAmountOfItems["768"] && styles["hide-768"];
  const hideItemOn640 = (index: number) => index > maxAmountOfItems["640"] && styles["hide-640"];

  if (!deals || deals.length === 0) {
    return <></>;
  }

  return (
    <div className={clsx(styles["featured-deals"], "uk-container")}>
      <div className={styles["top-container"]}>
        {title && (
          <Typography className={styles.title} tag="h2">
            {title}
          </Typography>
        )}
        <div className={styles["top-cta-link"]}>
          {topCtaTitle?.title && (
            <LinkComponent to={topCtaTitle.url} icon={getArrow(false)} animated variant="primary-new-colored-black">
              {topCtaTitle.title}
            </LinkComponent>
          )}
        </div>
      </div>
      <div className={styles.list}>
        {dealsToUse.map((deal, index) => (
          <div
            className={clsx(
              styles.item,
              hideItemOn1200(index + 1),
              hideItemOn959(index + 1),
              hideItemOn768(index + 1),
              hideItemOn640(index + 1)
            )}
            key={deal.id}
          >
            <DealCardWrapper deal={deal} viewType={viewType} />
          </div>
        ))}
        {useSeeMoreCard && (
          <div className={clsx(styles.seeMoreCard, styles.new)}>
            <SeeMoreCardWrapper
              title={ctaDescription || ""}
              CTAButtonText={ctaButton?.title || ""}
              CTAButtonLink={ctaButton?.url || ""}
              UnderCTAButtonText={ctaUnderButton?.title || ""}
              UnderCTAButtonLink={ctaUnderButton?.url || ""}
              icon={DealIcon}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export { FeaturedDeals };
