import React, { useState, useEffect } from "react";
import { Parser, ProcessNodeDefinitions } from "html-to-react";
import { Link } from "react-router-dom";

export interface IHtmlRenderComponentProps {
  html: string;
}

const getEssentialUrl = (url: string) => {
  const splittedUrl = url.split(".");
  const splittedUrlLength = url.split(".").length;

  return splittedUrlLength > 1
    ? `${splittedUrl[splittedUrlLength - 2]}.${splittedUrl[splittedUrlLength - 1]}`
    : `${splittedUrl[splittedUrlLength - 1]}`;
};

const processingInstructions = [
  {
    shouldProcessNode: (node: any) => {
      if (typeof window !== "undefined") {
        return node.name && node.name === "a";
      }
    },
    processNode: (node: any, children: any, index: number) => {
      if (typeof window !== "undefined" && node.attribs.href && node.attribs.href !== "") {
        const currentBaseUrl = window ? window.location.host : "";
        const checkIfUrlStartsWithSlash = node.attribs.href[0] === "/";
        if (checkIfUrlStartsWithSlash) {
          return (
            <Link key={index} to={node.attribs.href}>
              {children}
            </Link>
          );
        }

        const checkIfUrlHasHttp = node.attribs.href.match(/https|http/gi);
        const urlWithoutHttp = checkIfUrlHasHttp
          ? node.attribs.href.replace("https://", "").replace("http://", "")
          : node.attribs.href;

        const firstPartOfUrl = urlWithoutHttp.split("/")[0];
        const urlIsSameAsBase = getEssentialUrl(firstPartOfUrl) === getEssentialUrl(currentBaseUrl);

        if (urlIsSameAsBase) {
          const removedBaseUrl = urlWithoutHttp.replace(firstPartOfUrl, "");

          return (
            <Link key={index} to={removedBaseUrl}>
              {children}
            </Link>
          );
        }

        return React.createElement(
          "a",
          { key: index, href: node.attribs.href, target: "_blank", rel: "noreferrer" },
          children
        );
      }

      if (node.attribs.href === "" || !node.attribs.href) {
        return <React.Fragment>{children}</React.Fragment>;
      }

      return node;
    }
  },
  {
    // Anything else
    shouldProcessNode: (node: any) => true,
    processNode: ProcessNodeDefinitions().processDefaultNode
  }
];

const fixAnchorParentheses = (html: string) => {
  const findDoubleParentheseRegex = /<a href="".[^>]*"">|<a href="""">/gi;
  const foundErrors = html.match(findDoubleParentheseRegex);
  if (foundErrors) {
    let editedHtml = html;
    const fixedErrors = foundErrors.map(item => item.replace(/""/gi, `"`));
    fixedErrors.forEach((item, index) => {
      editedHtml = editedHtml.replace(foundErrors[index], item);
    });

    return editedHtml;
  }

  return html;
};

const HtmlRenderComponent = (props: IHtmlRenderComponentProps) => {
  const [html, setHtml] = useState("");
  const [parsedHtml, setParsedHtml] = useState<any>(null);

  useEffect(() => {
    if (props.html !== html) {
      setHtml(fixAnchorParentheses(props.html));
    }
  }, [props.html]);

  useEffect(() => {
    if (window) {
      setParsedHtml(Parser().parseWithInstructions(html, () => true, processingInstructions));
    } else {
      setParsedHtml(Parser().parse(html));
    }
  }, [html]);

  if (typeof window === "object") {
    return <React.Fragment>{parsedHtml}</React.Fragment>;
  }

  return <React.Fragment>{Parser().parse(fixAnchorParentheses(props.html))}</React.Fragment>;
};

export { HtmlRenderComponent };
