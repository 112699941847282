import React from "react";

import styles from "./merchant-seo-component.module.scss";
import { HtmlRenderComponent } from "@app/core/html-render";
import { useSelector } from "react-redux";
import { merchantsState } from "@app/redux/reducers/merchants";

export interface IMerchantSeoProps {}

const MerchantSeo = (props: IMerchantSeoProps) => {
  const merchants = useSelector(merchantsState);
  const { currentMerchant } = merchants;

  if (!currentMerchant) {
    return <React.Fragment />;
  }

  return (
    <div className={`${styles.seoContainer} uk-container`}>
      <div className={styles.seoDescription}>
        <HtmlRenderComponent
          html={currentMerchant.seo && currentMerchant.seo.pageText ? currentMerchant.seo.pageText : ""}
        />
      </div>
    </div>
  );
};

export { MerchantSeo };
