import React from "react";
import { createPortal } from "react-dom";
import styles from "./old-cookie-popup-component.module.scss";
import { ClickableComponent } from "../clickable";

export interface ICookiePopupComponentProps {
  children: any;
  closeText: string;
  isOpen: boolean;
  onClose: () => void;
}

const OldCookiePopupComponent = (props: ICookiePopupComponentProps) => {
  if (props.isOpen) {
    return createPortal(
      <div data-cy="cookie-popup" className={styles.cookieWrapper}>
        <div className={styles.cookiePopupContainer}>
          <div className={styles.cookiePopupContent}>{props.children}</div>
          <div className={styles.cookiePopupButtonContainer}>
            <ClickableComponent
              dataCy="cookie-popup-button"
              variant="primary-default"
              size={40}
              fullWidth
              onClick={props.onClose}
              title={props.closeText}
            />
          </div>
        </div>
      </div>,
      document.body
    );
  }

  return null;
};

export { OldCookiePopupComponent };
