import React, { useEffect, useState, useRef } from "react";
import BottomScrollListener from "react-bottom-scroll-listener";
import { StoreOverviewModule } from "@app/api/modules/store-overview/store-overview.module";
import styles from "./merchant-overview-component.module.scss";
import { BannerComponent } from "@app/core/banner";
import { PageProgressBarComponent } from "@app/core/page-progress-bar";
import { StickyContainer, Sticky } from "react-sticky";
import {
  IExtendedBannerDTO,
  getBannersFromLocalStorage,
  getOffset,
  saveBannerSeenInLocalStorage,
  usePrevious,
  useScrollPosition,
  addFiltersToGTM
} from "@app/util";
import { CtaSmallComponent } from "@app/core/cta-small/cta-small.component";
import { FilterItem } from "@app/api/core/filter/filter-item";
import {
  FIRST_MERCHANT_TAKE,
  MERCHANT_TAKE,
  BOTTOM_SCROLL_DEBOUNCE,
  NAVIGATION_HEIGHT_DEFAULT,
  SHOW_AD_EVERY_LINES,
  NAVIGATION_HEIGHT_MOBILE_SEARCH
} from "@app/constants/overviews-numbers";
import { AnimatedEllipsisLoader } from "@app/core/animated-ellipsis-loader/animated-ellipsis-loader.component";
import clsx from "clsx";
import { MerchantCardWrapper } from "@app/modules/merchant-card-wrapper/merchant-card-wrapper";
import { FilterBar } from "@app/core/filter-bar";
import { useAppSelector } from "@app/redux/store";
import { useDispatch } from "react-redux";
import { getAllFilters, getBanners, getMerchants } from "@app/redux/thunks/merchant-overview.thunk";
import { clearAllFilters, setCurrentTotalMerchants } from "@app/redux/reducers/merchants";
import { ViewType } from "@app/redux/reducers/settings";
import { IOutFeaturedMerchantDTO } from "@app/core/new-merchant-card/featured-merchants.interface";
import { OverviewTabsWrapper } from "@app/components/overview-tabs/overview-tabs-wrapper";
import { PlatformAlert } from "@app/core/platform-alert";
/* tslint:disable:no-magic-numbers */

export interface IMerchantOverviewComponentProps {
  storeOverviewModule: StoreOverviewModule;
}

export interface IOverviewItem {
  banner: IExtendedBannerDTO | undefined;
  merchant: IOutFeaturedMerchantDTO;
}

let previousSkip: undefined | number;

const component = (props: IMerchantOverviewComponentProps) => {
  const dispatch = useDispatch();
  const {
    banners: rdxBanners,
    brandFilterItems,
    categoryFilterItems,
    merchants,
    sortBy,
    totalResults
  } = useAppSelector(state => state.merchantOverview);
  const { screenSize, platformId } = useAppSelector(state => state.settings);
  const { overviewMessages } = props.storeOverviewModule;
  const topDivRef = useRef<any>(null);
  const mainDivRef = useRef<any>(null);
  const storeOverviewTopRef = useRef<any>(null);
  const bottomCallbackRef = useRef<HTMLDivElement>(null);

  const isFirstRender = useRef(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [progressPage, setProgressPage] = useState<number>(1);
  const [overviewItems, setOverviewItems] = useState<IOverviewItem[] | undefined>(undefined);
  const [totalHeight, setTotalHeight] = useState<number>(0);
  const [showSticky, setShowSticky] = useState<boolean>(true);
  const [banners, setBanners] = useState<IExtendedBannerDTO[]>([]);
  const [isMobileFilterOpened, setIsMobileFilterOpened] = useState<boolean>(false);
  const [offSet, setOffset] = useState<number>(0);

  const scrollPos = useScrollPosition(0);
  const prevScrollPosition = usePrevious(scrollPos) || 0;
  useEffect(() => {
    if (prevScrollPosition > scrollPos) {
      setShowSticky(true);
    } else if (prevScrollPosition < scrollPos) {
      setShowSticky(false);
    }

    setNewOffset();
  }, [scrollPos]);

  const viewType = screenSize ? screenSize.viewType : ViewType.Desktop;
  const isTablet =
    viewType === ViewType.Mobile ||
    viewType === ViewType.MobileBig ||
    viewType === ViewType.Tablet ||
    viewType === ViewType.Desktop;

  useEffect(() => {
    window.scroll(0, 0);
    setInitialValues();
    setShowSticky(false);

    isFirstRender.current = false;

    if (merchants.length === 0) {
      dispatch(getMerchants(0, FIRST_MERCHANT_TAKE, categoryFilterItems, brandFilterItems, platformId, sortBy));
    }
  }, []);

  useEffect(() => {
    const height = getTotalHeight(viewType, totalResults);
    const amountOfBanners = Math.floor(totalResults / getAmountOfAdsPerItems(viewType));
    const bannerHeight = amountOfBanners * getBannerHeight(viewType);
    const allItemsHeight = height + bannerHeight;

    if (allItemsHeight > 0) {
      setTotalHeight(allItemsHeight);
    }
  }, [screenSize, totalResults]);

  useEffect(() => {
    if (filtersAreDifferent() || allFiltersAndStoresAreEmpty([categoryFilterItems, brandFilterItems], merchants)) {
      setProgressPage(1);
      previousSkip = 0;
      const allFiltersCombined = [
        ...categoryFilterItems.filter(item => item.isSelected),
        ...brandFilterItems.filter(item => item.isSelected)
      ];
      addFiltersToGTM(allFiltersCombined);
      dispatch(getBanners(platformId, categoryFilterItems, brandFilterItems));

      if (!isFirstRender) dispatch(setCurrentTotalMerchants(0));
      const actualScrollPosition = getActualScrollPosition();
      if (mainDivRef && mainDivRef.current && actualScrollPosition > 0) {
        window.scroll(0, 0);
      }
    }
  }, [brandFilterItems, categoryFilterItems, sortBy]);

  useEffect(() => {
    if (merchants.length > 0) {
      const overviewItemsResult = getOverviewItems(viewType, merchants, banners);
      setOverviewItems(overviewItemsResult);
    } else {
      setIsLoading(false);
    }
  }, [merchants, screenSize, banners]);

  useEffect(() => {
    const extendedBanners: IExtendedBannerDTO[] = getBannersFromLocalStorage(rdxBanners);

    setBanners(extendedBanners);
  }, [rdxBanners]);

  const bottomPageCallback = () => {
    const newSkip = FIRST_MERCHANT_TAKE + (progressPage - 1) * MERCHANT_TAKE;
    if (merchants.length < totalResults && !isFirstRender.current && previousSkip !== newSkip) {
      // totalcurrentmerchants is async and will sometimes give the wrong amount;
      // const skip = props.totalCurrentMerchants || 0;
      previousSkip = newSkip;
      setIsLoading(true);
      setProgressPage(progressPage + 1);
      dispatch(getMerchants(newSkip, MERCHANT_TAKE, categoryFilterItems, brandFilterItems, platformId, sortBy));
    } else {
      setIsLoading(false);
    }
  };

  const setNewOffset = () => {
    if (bottomCallbackRef?.current) {
      const distanceFromTop = bottomCallbackRef.current.offsetTop;
      const totalWindowHeight = window.document.body.offsetHeight;

      let newOffset = totalWindowHeight - distanceFromTop;
      newOffset += 500; // add 500 pixels to the actual offset from the bottom to load merchants before loader comes into view.

      if (newOffset !== offSet) {
        setOffset(newOffset);
      }
    }
  };

  const getActualScrollPosition = () => {
    const position = getOffset(mainDivRef.current);
    const currentScrollPosition = document.body.scrollTop || document.documentElement.scrollTop;
    const actualScrollPosition = currentScrollPosition - position;

    return actualScrollPosition;
  };

  const mobileFilterToggle = (isOpen: boolean) => {
    setIsMobileFilterOpened(isOpen);
  };

  const prevCategoryFilterItems = usePrevious(categoryFilterItems);
  const prevBrandFilterItems = usePrevious(brandFilterItems);
  const prevSortBy = usePrevious(sortBy);
  const filtersAreDifferent = (): boolean => {
    if (!prevCategoryFilterItems && !prevBrandFilterItems) {
      return false;
    }

    const categoryFiltersAreDifferent = singleFiltersAreDifferent(prevCategoryFilterItems || [], categoryFilterItems);
    const brandFiltersAreDifferent = singleFiltersAreDifferent(prevBrandFilterItems || [], brandFilterItems);
    const sortByIsDifferent = prevSortBy !== sortBy;

    return categoryFiltersAreDifferent || brandFiltersAreDifferent || sortByIsDifferent;
  };

  const overviewItemsToUse = overviewItems || getOverviewItems(viewType, merchants, []);

  const setInitialValues = () => {
    if (allFiltersAndStoresAreEmpty([categoryFilterItems, brandFilterItems], merchants, true)) {
      dispatch(getAllFilters(platformId));
    }

    dispatch(getBanners(platformId));
  };

  return (
    <div>
      <div className={styles["merchants-overview__tab"]} ref={topDivRef}>
        <OverviewTabsWrapper
          tabs={props.storeOverviewModule.tabs}
          switchButtons={props.storeOverviewModule.switchButtons}
        />
      </div>

      <StickyContainer>
        <div ref={mainDivRef}>
          <Sticky>
            {({ style, isSticky }) => (
              <div
                style={{
                  ...style,
                  transform: isMobileFilterOpened ? "none" : "translateZ(0px)",
                  marginTop:
                    isSticky && showSticky
                      ? isTablet
                        ? NAVIGATION_HEIGHT_MOBILE_SEARCH
                        : NAVIGATION_HEIGHT_DEFAULT
                      : 0,
                  transition: "0s",
                  zIndex: 99
                }}
                className={clsx(styles.filterBar, styles.filterBarBackground)}
              >
                <FilterBar onMobileFilterOpen={mobileFilterToggle} />
                {isSticky && <PageProgressBarComponent totalHeight={totalHeight} mainDivRef={mainDivRef} />}
              </div>
            )}
          </Sticky>
          <PlatformAlert platform={props.storeOverviewModule.platform} />
        </div>

        <div className={styles["store-overview"]}>
          <div className="uk-container" ref={storeOverviewTopRef}>
            {overviewMessages.onTop.isActive && (
              <div className={styles["no-black-friday"]}>
                <h2>{overviewMessages.onTop.message}</h2>
              </div>
            )}

            {merchants && merchants.length > 0 ? (
              <div data-cy="merchant-overview" className={styles["stores-overview__body__list"]}>
                {overviewItemsToUse.map(overviewItem => {
                  const { merchant, banner } = overviewItem;

                  return (
                    <React.Fragment key={merchant.id}>
                      <div
                        data-cy="merchant-card"
                        className={clsx(
                          styles["stores-overview__body__cards"],
                          styles["stores-overview__body__cards__new"]
                        )}
                      >
                        <MerchantCardWrapper merchant={merchant} viewType={viewType} />
                      </div>
                      {banner && (
                        <div
                          data-bannername={banner.slug}
                          className={clsx(
                            styles[`stores-overview__body__banner`],
                            styles["stores-overview__body__banner__new"]
                          )}
                        >
                          <BannerComponent
                            banner={banner}
                            showAlternativeBanner={banner.showAlternativeBanner}
                            variant="default"
                          />
                        </div>
                      )}
                    </React.Fragment>
                  );
                })}

                <BottomScrollListener debounce={BOTTOM_SCROLL_DEBOUNCE} onBottom={bottomPageCallback} offset={offSet} />
              </div>
            ) : !allFiltersAndStoresAreEmpty([categoryFilterItems, brandFilterItems], merchants) && !isLoading ? (
              <div>
                <CtaSmallComponent
                  buttonTitle={overviewMessages.noResultsWithFilter.buttonText}
                  onClick={() => {
                    // Clear filters in case a selection has been made and a subset is selected
                    dispatch(getAllFilters(platformId));

                    dispatch(clearAllFilters());
                  }}
                  ctaText={overviewMessages.noResultsWithFilter.text}
                  icon={props.storeOverviewModule.emptyStateIcon}
                />
              </div>
            ) : (
              !isLoading &&
              overviewMessages.emptyResults.isActive && (
                <div className={styles["no-black-friday"]}>
                  <h2>{overviewMessages.emptyResults.message}</h2>
                </div>
              )
            )}
            {isLoading && (
              <div data-cy="merchant-overview_loading-spinner" className={styles["loading-spinner"]}>
                <AnimatedEllipsisLoader />
              </div>
            )}
          </div>
        </div>
        <div ref={bottomCallbackRef} />
      </StickyContainer>
    </div>
  );
};

export const getTotalHeight = (viewType: ViewType, totalStores: number) => {
  const lineHeight = getLineHeight(viewType);
  const storesPerLine = getAmountOfItemsPerLine(viewType);
  const totalNumberOfLines = getTotalNumberOfLines(storesPerLine, totalStores);

  return lineHeight * totalNumberOfLines;
};

const getLineHeight = (viewType: ViewType): number => {
  switch (viewType) {
    case ViewType.DesktopFull:
      return 250;
    case ViewType.DesktopLarge:
      return 250;
    case ViewType.Desktop:
      return 250;
    case ViewType.Tablet:
      return 172;
    case ViewType.MobileBig:
      return 134;
    case ViewType.Mobile:
      return 134;
    default:
      return 134;
  }
};

const getAmountOfItemsPerLine = (viewType: ViewType): number => {
  switch (viewType) {
    case ViewType.DesktopFull:
      return 5;
    case ViewType.DesktopLarge:
      return 4;
    case ViewType.Desktop:
      return 3;
    case ViewType.Tablet:
      return 2;
    default:
      return 1;
  }
};

const getTotalNumberOfLines = (storesPerLine: number, totalStores: number) => {
  let result = totalStores / storesPerLine;
  result = Math.ceil(result);

  return result;
};

const getBannerHeight = (viewType: ViewType): number => {
  switch (viewType) {
    case ViewType.DesktopFull:
      return 197;
    case ViewType.DesktopLarge:
      return 197;
    case ViewType.Desktop:
      return 197;
    case ViewType.Tablet:
      return 161;
    case ViewType.MobileBig:
      return 143;
    default:
      return 143;
  }
};

const getAmountOfAdsPerItems = (viewType: ViewType) => {
  switch (viewType) {
    case ViewType.DesktopFull:
      return SHOW_AD_EVERY_LINES * 5;

    case ViewType.DesktopLarge:
      return SHOW_AD_EVERY_LINES * 4;

    case ViewType.Desktop:
      return SHOW_AD_EVERY_LINES * 3;

    case ViewType.Mobile:
    case ViewType.MobileBig:
    case ViewType.Tablet:
      return SHOW_AD_EVERY_LINES * 2;
    default:
      return 0;
  }
};

const getOverviewItems = (
  viewType: ViewType,
  merchants: IOutFeaturedMerchantDTO[],
  banners: IExtendedBannerDTO[]
): IOverviewItem[] => {
  bannerIndex = 0;
  const overviewItemsResult: IOverviewItem[] = [];
  const showAdEvery = getAmountOfAdsPerItems(viewType);

  let storeIndex = 1;
  let showAlternativeBanner = false;

  merchants.forEach(merchant => {
    if (storeIndex === showAdEvery && banners.length > 0) {
      overviewItemsResult.push({
        banner: { ...getNextBanner(banners), showAlternativeBanner },
        merchant
      });
      storeIndex = 1;
      showAlternativeBanner = !showAlternativeBanner;
    } else {
      overviewItemsResult.push({
        banner:
          merchants.length < showAdEvery && banners.length > 0 && storeIndex === merchants.length
            ? { ...getNextBanner(banners), showAlternativeBanner }
            : undefined,
        merchant
      });
      storeIndex += 1;
    }
  });

  return overviewItemsResult;
};

let bannerIndex = 0;
const getNextBanner = (banners: IExtendedBannerDTO[]): IExtendedBannerDTO => {
  if (bannerIndex >= banners.length) {
    bannerIndex = 0;
  }
  const result = banners[bannerIndex];

  saveBannerSeenInLocalStorage(result.id);

  bannerIndex += 1;

  return result;
};

const allFiltersAndStoresAreEmpty = (
  allFilterItems: FilterItem[][],
  merchants: IOutFeaturedMerchantDTO[],
  excludeMerchants?: boolean
) => {
  const totalSelecteditems = allFilterItems.reduce(
    (count, filterItems) => count + filterItems.filter(item => item.isSelected).length,
    0
  );

  if (totalSelecteditems > 0) {
    return false;
  }

  if (excludeMerchants || merchants.length === 0) {
    return true;
  }

  return false;
};

const singleFiltersAreDifferent = (oldFilters: FilterItem[], newFilters: FilterItem[]) => {
  for (let i = 0; i < newFilters.length; i += 1) {
    if (!oldFilters[i]) {
      return false;
    }
    if (oldFilters[i].isSelected !== newFilters[i].isSelected) {
      return true;
    }
  }

  return false;
};

const MerchantOverview = React.memo(component);
export { MerchantOverview };
