import { IWPFeaturedCategoriesModuleFull, IWPFeaturedCategoriesTiles } from "./featured-categories-module.interface";
import { getWPModuleSpacing } from "@app/api/wp-page-fetcher/utils/get-wp-module-spacing";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import { FeaturedCategoriesModule } from "@app/api/modules/featured-categories/featured-categories.module";
import { IArrowPanelProps } from "@app/core";
import { IModuleFetcher } from "@app/api/wp-page-fetcher/module-selector/module-selector";
import Logger from "@app/util/logger";

export interface IFeaturedCategoriesModuleMapper extends IModuleFetcher {
  module: IWPFeaturedCategoriesModuleFull;
}

export const featuredCategoriesModuleMapper = async (
  props: IFeaturedCategoriesModuleMapper
): Promise<FeaturedCategoriesModule | undefined> => {
  try {
    const {
      module,
      module: { data }
    } = props;
    if (!data) return moduleMapperThrowMessage("No data found in featuredCategoriesModuleMapper");

    return {
      id: "9",
      name: "FeaturedCategoriesModule",
      ...getWPModuleSpacing(module.data, module.setMargins),
      background: { backgroundColour: data.bgColor },
      categoryItems: mapCategoryItems(data.tiles),
      title: data.title
    };
  } catch (e) {
    Logger.logError(e, "Error in: featuredCategoriesModuleMapper");

    return undefined;
  }
};

const mapCategoryItems = (tiles?: IWPFeaturedCategoriesTiles[]): IArrowPanelProps[] | [] => {
  if (!tiles) return [];

  return tiles.map(tile => ({
    icon: tile.image?.url || "",
    link: {
      title: tile.linkAndText?.title || "",
      url: tile.linkAndText?.url || "",
      newTab: tile.linkAndText?.target === "_blank"
    }
  }));
};
