/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface PlatformDTO
 */
export interface PlatformDTO {
  /**
   *
   * @type {number}
   * @memberof PlatformDTO
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof PlatformDTO
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof PlatformDTO
   */
  googleExperimentId: string;
  /**
   *
   * @type {number}
   * @memberof PlatformDTO
   */
  wctPlatformId: number;
  /**
   *
   * @type {string}
   * @memberof PlatformDTO
   */
  googleOptimizeId?: string;
  /**
   *
   * @type {string}
   * @memberof PlatformDTO
   */
  headerText?: string;
  /**
   *
   * @type {string}
   * @memberof PlatformDTO
   */
  navBarTitle?: string;
  /**
   *
   * @type {string}
   * @memberof PlatformDTO
   */
  navBarSubTitle?: string;
  /**
   *
   * @type {string}
   * @memberof PlatformDTO
   */
  navBarLink?: string;
  /**
   *
   * @type {string}
   * @memberof PlatformDTO
   */
  mailchimpApiKey?: string;
  /**
   *
   * @type {string}
   * @memberof PlatformDTO
   */
  mailchimpAudienceId?: string;
  /**
   *
   * @type {string}
   * @memberof PlatformDTO
   */
  merchantPrefix?: string;
  /**
   *
   * @type {string}
   * @memberof PlatformDTO
   */
  alertMessage?: string;
  /**
   *
   * @type {string}
   * @memberof PlatformDTO
   */
  alertStartDate?: string;
  /**
   *
   * @type {string}
   * @memberof PlatformDTO
   */
  alertEndDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof PlatformDTO
   */
  showAlert?: boolean;
}

export function PlatformDTOFromJSON(json: any): PlatformDTO {
  return PlatformDTOFromJSONTyped(json, false);
}

export function PlatformDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlatformDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    name: json["name"],
    googleExperimentId: json["googleExperimentId"],
    wctPlatformId: json["wctPlatformId"],
    googleOptimizeId: !exists(json, "googleOptimizeId") ? undefined : json["googleOptimizeId"],
    headerText: !exists(json, "headerText") ? undefined : json["headerText"],
    navBarTitle: !exists(json, "navBarTitle") ? undefined : json["navBarTitle"],
    navBarSubTitle: !exists(json, "navBarSubTitle") ? undefined : json["navBarSubTitle"],
    navBarLink: !exists(json, "navBarLink") ? undefined : json["navBarLink"],
    mailchimpApiKey: !exists(json, "mailchimpApiKey") ? undefined : json["mailchimpApiKey"],
    mailchimpAudienceId: !exists(json, "mailchimpAudienceId") ? undefined : json["mailchimpAudienceId"],
    merchantPrefix: !exists(json, "merchantPrefix") ? undefined : json["merchantPrefix"],
    alertMessage: !exists(json, "alertMessage") ? undefined : json["alertMessage"],
    alertStartDate: !exists(json, "alertStartDate") ? undefined : json["alertStartDate"],
    alertEndDate: !exists(json, "alertEndDate") ? undefined : json["alertEndDate"],
    showAlert: !exists(json, "showAlert") ? undefined : json["showAlert"]
  };
}

export function PlatformDTOToJSON(value?: PlatformDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    googleExperimentId: value.googleExperimentId,
    wctPlatformId: value.wctPlatformId,
    googleOptimizeId: value.googleOptimizeId,
    headerText: value.headerText,
    navBarTitle: value.navBarTitle,
    navBarSubTitle: value.navBarSubTitle,
    navBarLink: value.navBarLink,
    mailchimpApiKey: value.mailchimpApiKey,
    mailchimpAudienceId: value.mailchimpAudienceId,
    merchantPrefix: value.merchantPrefix,
    alertMessage: value.alertMessage,
    alertStartDate: value.alertStartDate,
    alertEndDate: value.alertEndDate,
    showAlert: value.showAlert
  };
}
