import { configureStore, ThunkAction, AnyAction } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useSelector } from "react-redux";
// Reducers
import settingsReducer from "@app/redux/reducers/settings";
import pageReducer from "@app/redux/reducers/page";
import navbarSearchReducer from "@app/redux/reducers/search";
import platformReducer from "@app/redux/reducers/platforms";
import localesReducer from "@app/redux/reducers/locales";
import dealOverviewReducer from "@app/redux/reducers/deals";
import merchantOverviewReducer from "@app/redux/reducers/merchants";
import navBarReducer from "@app/redux/reducers/navbar";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getStoreWithPreloadedState = (state: any | undefined) => {
  // tslint:disable-next-line: no-shadowed-variable
  const store = configureStore({
    reducer: {
      locales: localesReducer,
      search: navbarSearchReducer,
      pages: pageReducer,
      settings: settingsReducer,
      merchantOverview: merchantOverviewReducer,
      dealOverview: dealOverviewReducer,
      platform: platformReducer,
      navbar: navBarReducer
    },
    preloadedState: state,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false
      })
  });

  return store;
};

export const store = configureStore({
  reducer: {
    locales: localesReducer,
    search: navbarSearchReducer,
    pages: pageReducer,
    settings: settingsReducer,
    merchantOverview: merchantOverviewReducer,
    dealOverview: dealOverviewReducer,
    platform: platformReducer,
    navbar: navBarReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;
export type AppDispatch = typeof store.dispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
