import React, { useEffect, useState } from "react";
import clsx from "clsx";
import DealIcon from "@assets/icons/medal.svg";
import { mapDealsToFeaturedDeals } from "@app/api/wp-page-fetcher/module-selector/module-mappers";
import { useAppSelector } from "@app/redux/store";
import { ViewType } from "@app/redux/reducers/settings";
import { FeaturedDealsSearchModule } from "@app/api/modules/featured-deals-search/featured-deals-search.module";
import { SearchApi } from "@app/bf-api";
import { IOutFeaturedDealDTO } from "@app/core/new-deal-card";
import Typography from "@app/components/typography";
import { DealCardWrapper } from "../deal-card-wrapper/deal-card-wrapper";
import { SeeMoreCardWrapper } from "../see-more-card-wrapper/see-more-card-wrapper";
import { combineFilters } from "@app/api/wp-page-fetcher/utils/combine-filters";

import styles from "./featured-deals-search-component.module.scss";

export interface IFeaturedDealsComponentProps {
  featuredDealsModule: FeaturedDealsSearchModule;
}

const AMOUNT_OF_ITEMS_MOBILE = 8;

const FeaturedDealsSearch = (props: IFeaturedDealsComponentProps) => {
  const { screenSize, platformId } = useAppSelector(state => state.settings);

  const {
    useSeeMoreCard,
    ctaButton,
    ctaUnderButton,
    ctaDescription,
    title,
    featuredDeals,
    pageUrl,
    amountOfDeals,
    searchValue,
    searchValueEAN,
    brandSelection,
    categorySelection
  } = props.featuredDealsModule;
  const [deals, setDeals] = useState<IOutFeaturedDealDTO[]>(featuredDeals || []);
  const filterIds = combineFilters([brandSelection, categorySelection]) || undefined;

  useEffect(() => {
    getDeals();
  }, [pageUrl]);

  const getDeals = async (): Promise<void> => {
    const api = new SearchApi();
    const result = await api.searchGet(amountOfDeals, 0, platformId, filterIds, searchValueEAN, searchValue, "deals");
    setDeals(mapDealsToFeaturedDeals(result.deals || []));
  };

  const dealsToUse = deals && deals.length > 0 ? deals : featuredDeals;
  const viewType = screenSize ? screenSize.viewType : ViewType.Desktop;

  if (!dealsToUse || dealsToUse.length === 0) {
    return <React.Fragment />;
  }

  const AMOUNT_OF_ITEMS_DESKTOP = 5;
  const amountOfRows = Math.ceil((dealsToUse.length + 1) / AMOUNT_OF_ITEMS_DESKTOP);

  const maxAmountOfItems = {
    1200: amountOfRows * 4 - 1,
    959: amountOfRows * 3 - 1,
    768: 3,
    640: props.featuredDealsModule.amountOfDealsMobile || AMOUNT_OF_ITEMS_MOBILE
  };

  const hideItemOn1200 = (index: number) => index > maxAmountOfItems["1200"] && styles["hide-1200"];
  const hideItemOn959 = (index: number) => index > maxAmountOfItems["959"] && styles["hide-959"];
  const hideItemOn768 = (index: number) => index > maxAmountOfItems["768"] && styles["hide-768"];
  const hideItemOn640 = (index: number) => index > maxAmountOfItems["640"] && styles["hide-640"];

  if (!deals || deals.length === 0) {
    return <></>;
  }

  return (
    <div className={clsx(styles["featured-deals"], "uk-container")}>
      {title && (
        <Typography className={styles.title} tag="h2">
          {title}
        </Typography>
      )}
      <div className={styles.list}>
        {dealsToUse.map((deal, index) => (
          <div
            className={clsx(
              styles.item,
              hideItemOn1200(index + 1),
              hideItemOn959(index + 1),
              hideItemOn768(index + 1),
              hideItemOn640(index + 1)
            )}
            key={deal.id}
          >
            <DealCardWrapper deal={deal} viewType={viewType} />
          </div>
        ))}
        {useSeeMoreCard && (
          <div className={clsx(styles.seeMoreCard, styles.new)}>
            <SeeMoreCardWrapper
              icon={DealIcon}
              title={ctaDescription || ""}
              CTAButtonText={ctaButton?.title || ""}
              CTAButtonLink={ctaButton?.url || ""}
              UnderCTAButtonText={ctaUnderButton?.title || ""}
              UnderCTAButtonLink={ctaUnderButton?.url || ""}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export { FeaturedDealsSearch };
