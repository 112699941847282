import * as React from "react";

import { OldCookiePolicyComponent } from "@app/core/cookie-policy/old-cookie-policy.component";

export interface ICookieWrapperProps {
  platformId: number;
}

const CookieWrapper = (props: ICookieWrapperProps) => <OldCookiePolicyComponent platformId={props.platformId} />;

export { CookieWrapper };
