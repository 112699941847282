import { wpPageFetcher } from "@app/api/wp-page-fetcher/wp-page-fetcher";
import { setBasePageInformation } from "@app/api/wp-page-fetcher/utils/set-base-page-information";
import { getCorrectUrl } from "@app/api/wp-page-fetcher/utils/get-correct-url";
import Logger from "@app/util/logger";
import { AppThunk } from "../store";
import { setCurrentPage, setIsLoading } from "../reducers/page";

export const getPage = (page: string, platformVersion: number): AppThunk<any> => async (
  dispatch,
  getState
): Promise<string> => {
  try {
    dispatch(setIsLoading(true));
    const pageToUse = getCorrectUrl(page, platformVersion);

    const baseModules = getState().pages.baseModules;

    const {
      errorPages,
      shopPageUrlName,
      dealPageUrlName,
      locale,
      platformId,
      urlAddition
    } = await setBasePageInformation(platformVersion);

    const currentPage = await wpPageFetcher({
      dealPageUrlName,
      locale,
      errorPages,
      platformId,
      shopPageUrlName,
      pageUrl: pageToUse,
      dispatch,
      baseModules,
      urlAddition
    });

    if (currentPage === 302) {
      return `${urlAddition}/${shopPageUrlName}`;
    }

    dispatch(setCurrentPage(currentPage));
    dispatch(setIsLoading(false));

    return "";
  } catch (e) {
    Logger.logError(e, "Error in: getPage in page thunk");

    return "";
  }
};

export const pageThunks = {
  getPage
};
