import { SearchInput } from "@app/components/search-input/search-input";
import React, { useState } from "react";
import SearchIcon from "@assets/icons/search.svg";
import { IconComponent } from "@app/core";
import styles from "../new-nav-bar-component.module.scss";
import { useDispatch } from "react-redux";
import { searchItems, searchPages } from "@app/redux/thunks/navbar-search.thunk";
import { formatMessage } from "@app/translations/intl";
import { OutDealDTO } from "@app/bf-api";
import { SearchResults } from "../../search-results/search-results";
import { useOnClickOutside } from "@app/util/hooks/use-on-click-outside";
import CrossIcon from "@assets/icons/search-cross.svg";
import { IOutFeaturedMerchantDTO } from "@app/core/new-merchant-card/featured-merchants.interface";

interface IProps {
  popularDeals?: OutDealDTO[];
  popularMerchants?: IOutFeaturedMerchantDTO[];
}

export const NavbarSearch = (props: IProps) => {
  const searchResultsRef = React.useRef(null);

  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const dispatch = useDispatch();

  const platformId = Number(formatMessage({ id: "global.platformId" }));
  const [searchValue, setSearchValue] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  React.useEffect(() => {
    dispatch(searchItems(searchValue, platformId, 6));
    dispatch(searchPages(searchValue, platformId, 8));
  }, [searchValue]);

  const openSearch = () => {
    setIsSearchOpen(true);
  };

  const closeSearch = () => {
    setSearchValue("");
    setIsSearchOpen(false);
  };

  const handleDelete = () => {
    setSearchValue("");
  };

  React.useEffect(() => {
    if (isSearchOpen) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isSearchOpen]);

  useOnClickOutside(searchResultsRef, closeSearch);

  return (
    <>
      <div ref={searchResultsRef} className={styles.search}>
        <SearchInput
          id="searchInputDesktop"
          icon={<IconComponent fillColor="white" size="14px" icon={SearchIcon} />}
          iconPosition="right"
          onChange={handleChange}
          placeholder={formatMessage({ id: "navBar.search.placeholder" })}
          type="text"
          value={searchValue}
          onFocus={openSearch}
          deleteIcon={<IconComponent fillColor="white" size="14px" icon={CrossIcon} />}
          onDelete={handleDelete}
        />
        {isSearchOpen && (
          <div>
            <div className={styles.searchResultsContainer}>
              <SearchResults
                searchValue={searchValue}
                popularDeals={props.popularDeals}
                popularMerchants={props.popularMerchants}
                closeSearch={closeSearch}
              />
            </div>
          </div>
        )}
      </div>
      {isSearchOpen && <div className={styles.overlay} />}
    </>
  );
};
