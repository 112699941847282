import { IPlatformSpecificInfo } from "../platform";
import bfLogo from "@assets/logos/bf-landing.svg";
import { WEBSITE_COLORS } from "../colors";
import FavIcon from "@assets/favicons/favicon.png";
import instagramIcon from "@assets/icons/new-instagram.svg";
import facebookIcon from "@assets/icons/new-facebook.svg";
import mailIcon from "@assets/icons/new-mail.svg";
import CheckmarkIcon from "@assets/icons/usp-check-mark.svg";
import Paper from "@assets/icons/paper.svg";

export const landingPagePlatform: IPlatformSpecificInfo = {
  id: 8,
  domain: "blackfriday.expert",
  merchantOverviewLink: "/stores/",
  dealOverviewLink: "/deals/",
  urlAddition: "",
  isLandingPage: false,
  defaultBackButtonText: "Back",
  breadcrumbHomeName: "Home",
  breadcrumbHomeUrl: "/",
  readingTimeText: {
    singular: "minute readingtime",
    plural: "minutes readingtime"
  },
  newsletterButtonTitle: "Subscribe",
  newsletterModalTitle: "Subscribe to newsletter",
  fallBackHeaderImage: "https://media.blackfridaynederland.nl/wordpress/20200430114412/default-blog-image-be.jpg",
  bottomFooter: {
    items: [
      {
        title: "Contacter",
        url: "/fr/contacter/"
      },
      {
        title: "Privacy",
        url: "/fr/privacy/"
      },
      {
        title: "Cookies",
        url: "/fr/cookies/"
      },
      {
        title: "Sitemap",
        url: "/fr/sitemap/"
      }
    ],
    logo: {
      src: bfLogo,
      alt: "",
      title: ""
    },
    logoLink: "/uk/"
  },
  middleFooter: {
    links: [
      {
        id: "TRENDING_BLOCK",
        title: "More about Black Friday",
        links: []
      },
      {
        id: "SHOPS_BLOCK",
        title: "Most viewed pages",
        links: []
      },
      {
        id: "THIRD_BLOCK",
        title: "Stores with the best deals",
        links: []
      }
    ],
    newsletter: {
      title: "Subscribe to the newsletter",
      text: "and receive the best deals",
      buttonText: "Newsletter",
      buttonIcon: Paper
    },
    socialMedia: {
      id: "SOCIAL_BLOCK",
      title: "Follow us",
      socialItem: [
        {
          image: {
            src: facebookIcon,
            alt: "",
            title: ""
          },
          link: "https://www.facebook.com/BlackFridayUnitedKingdom/"
        },
        {
          image: {
            src: instagramIcon,
            alt: "",
            title: ""
          },
          link: "https://www.instagram.com/blackfridayexpert_uk/"
        },
        {
          link: "info@blackfriday.expert",
          image: {
            src: mailIcon,
            alt: "",
            title: ""
          }
        }
      ]
    }
  },
  fallbackMerchantHeader: {
    src: "https://media.blackfridaynederland.nl/wordpress/20200421102650/Black-Friday-Winkels-Nederland.jpg",
    alt: "Store header",
    title: "Header storepage"
  },
  logo: {
    src: bfLogo,
    alt: "Black Friday Logo",
    title: "Black Friday Logo"
  },
  uspModule: {
    uniqueSellingPoints: [
      {
        icon: CheckmarkIcon,
        link: {
          url: "/uk/stores/",
          title: "All promotions in one overview"
        }
      },
      {
        icon: CheckmarkIcon,
        link: {
          url: "/uk/deals/",
          title: "Easily score the best deals"
        }
      },
      {
        icon: CheckmarkIcon,
        link: {
          url: "/uk/black-friday/",
          title: "Don't miss out on Black Friday"
        }
      }
    ]
  },
  storeTabs: [],
  dealTabs: [],
  colors: {
    "--colour-version-website": WEBSITE_COLORS.ORANGE,
    "--colour-version-website-RGB": WEBSITE_COLORS.ORANGE_RGB,
    "--colour-version-website-darken7": WEBSITE_COLORS.ORANGE_DARKEN_7,
    "--colour-version-website-darken16": WEBSITE_COLORS.ORANGE_DARKEN_16,
    "--colour-version-website-darken35": WEBSITE_COLORS.ORANGE_DARKEN_35,
    "--colour-version-website-lighten51": WEBSITE_COLORS.ORANGE_LIGHTEN_40,
    "--colour-version-website-lightAccent": WEBSITE_COLORS.NL_LIGHT_ACCENT
  },
  locale: "en",
  tagManagerScriptLocation: "/static/googletagmanager-landing.js",
  weCanTrackScriptLocation: "/static/wecantrack-landing.js",
  favIcon: FavIcon,
  favIconPublic: "https://media.blackfridaynederland.nl/static/FavIcons/favicon-bf-expert-int.png",
  gtm: "GTM-5KG6LNX"
};
