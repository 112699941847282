import { getWPModuleSpacing } from "@app/api/wp-page-fetcher/utils/get-wp-module-spacing";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import Logger from "@app/util/logger";
import { ICategoryBlocksModule } from "@app/api/modules/category-blocks/category-blocks.modules";

import { IModuleFetcher } from "../../module-selector";
import { IWPCategoryBlocksModuleFull } from "./category-blocks-module.interface";

export interface ICategoryBlocksModuleMapper extends IModuleFetcher {
  module: IWPCategoryBlocksModuleFull;
}

export const categoryBlocksModuleMapper = async (
  props: ICategoryBlocksModuleMapper
): Promise<ICategoryBlocksModule | undefined> => {
  try {
    const {
      module,
      module: { data }
    } = props;
    if (!data) {
      return moduleMapperThrowMessage("No data found in categoryBlocksModuleMapper");
    }

    return {
      id: "29",
      name: "CategoryBlocksModule",
      ...getWPModuleSpacing(module.data, module.setMargins),
      background: { backgroundColour: data?.bgColor },
      moduleTitle: data?.moduleTitle || "",
      categoryBlocks: data?.categories || []
    };
  } catch (e) {
    Logger.logError(e, "Error in: whenIsModuleMapper");

    return undefined;
  }
};
