import { IWPFeaturedDealsModuleFull } from "./featured-deals-module.interface";
import { getWPModuleSpacing } from "@app/api/wp-page-fetcher/utils/get-wp-module-spacing";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import {
  FeaturedDealsModule,
  FeaturedDealsSelectionChoiceType
} from "@app/api/modules/featured-deals/featured-deals.module";
import { combineFilters } from "@app/api/wp-page-fetcher/utils/combine-filters";
import {
  getMerchantSingleFilters,
  checkIfMerchantSinglePage
} from "@app/api/wp-page-fetcher/utils/get-merchant-single-filters";
import { IGetFeaturedDealsMapper, getFeaturedDealsMapper } from "./get-featured-deals-mapper";
import { IModuleFetcher } from "@app/api/wp-page-fetcher/module-selector/module-selector";
import { OutDealDTO } from "@app/bf-api";
import { IOutFeaturedDealDTO } from "@app/core/new-deal-card/deal-card.interface";
import Logger from "@app/util/logger";
import { getUniqueFromArray } from "@app/util/get-unique-from-array";
import { setSingleMerchantInStore } from "../merchant-single-module";

const DEFAULT_AMOUNT_OF_DEALS = 9;

export interface IFeaturedDealsModuleMapper extends IModuleFetcher {
  module: IWPFeaturedDealsModuleFull;
}

interface IGetCorrectFeaturedDeals extends IGetFeaturedDealsMapper {
  filterIds?: string;
  dealSelectionCount?: number;
  isMerchantSinglePage?: boolean;
  merchantSingleFilters?: string;
  labelFilter?: string;
}

export const featuredDealsModuleMapper = async (
  props: IFeaturedDealsModuleMapper
): Promise<FeaturedDealsModule | undefined> => {
  try {
    const {
      module,
      module: { data },
      res,
      splittedUrl,
      shopPageUrlName,
      platformId,
      pageUrl
    } = props;
    if (!data) return moduleMapperThrowMessage("No data found in featuredDealsModuleMapper");

    const filterIds = combineFilters([data.filters?.brandSelection, data.filters?.categorySelection]);
    const dealSelectionCount = Number(data.filters?.dealsSelectionAmount) || DEFAULT_AMOUNT_OF_DEALS;
    const isMerchantSinglePage = checkIfMerchantSinglePage(splittedUrl, shopPageUrlName);
    let featuredDeals: OutDealDTO[] | undefined = [];
    const selectionChoice = data.selectionChoice as FeaturedDealsSelectionChoiceType;

    // Check if server
    if (res) {
      const merchantSingleFilters = isMerchantSinglePage
        ? await getMerchantSingleFilters(platformId, splittedUrl, shopPageUrlName)
        : undefined;

      featuredDeals = await getCorrectFeaturedDeals({
        platformId,
        filterIds,
        dealSelectionCount,
        selectionChoice,
        dealSelection: data.deals?.dealSelection,
        isMerchantSinglePage,
        merchantSingleFilters: merchantSingleFilters?.filters,
        merchantId: merchantSingleFilters?.merchantId,
        labelFilter: data.label?.labelFilter
      });

      if (!featuredDeals || featuredDeals.length === 0)
        // moduleMapperThrowMessage("No featured deals found in featuredDealsModuleMapper");
        return undefined;
    }

    const merchantName = await getMerchantName(props, isMerchantSinglePage);

    return {
      id: "17",
      name: "FeaturedDealsModule",
      ...getWPModuleSpacing(module.data, module.setMargins),
      background: { backgroundColour: data.bgColor },
      useSeeMoreCard: data.useSeeMoreCard,
      topCtaTitle: {
        title: data.topCtaTitle?.title || "",
        url: data.topCtaTitle?.url || ""
      },
      ctaButton: {
        title: data.cta?.title || "",
        url: data.cta?.url || ""
      },
      ctaUnderButton: {
        title: data.ctaUnderButton?.title || "",
        url: data.ctaUnderButton?.url || ""
      },
      ctaDescription: data.ctaDescription || "",
      title: getFeaturedDealsTitleWithWildCards(data.title, merchantName),
      filterIds: filterIds,
      amountOfDeals: dealSelectionCount,
      featuredDeals: mapDealsToFeaturedDeals(featuredDeals || []),
      isMerchantSinglePage: isMerchantSinglePage,
      amountOfDealsMobile: data.amountOfDealsMobile,
      dealSelection: data.deals?.dealSelection,
      selectionChoice,
      labelFilter: data.label?.labelFilter,
      pageUrl
    };
  } catch (e) {
    Logger.logError(e, "Error in: featuredDealsModuleMapper");

    return undefined;
  }
};

export const getFeaturedDealsTitleWithWildCards = (text?: string, merchantName?: string): string => {
  if (!text || !merchantName) return text || "";

  const currentYear = new Date().getFullYear();

  const replacedMerchantNames = text.replace(/{merchantName}/, merchantName);
  const replacedYear = replacedMerchantNames.replace(/{currentYear}/, `${currentYear}`);

  return replacedYear;
};

const getMerchantName = async (props: IFeaturedDealsModuleMapper, isMerchantSinglePage?: boolean): Promise<string> => {
  if (isMerchantSinglePage && props.res) {
    const merchantStateName = props.res.locals.store.getState()?.merchantOverview?.currentMerchant?.name;
    if (!merchantStateName) {
      const merchant = await setSingleMerchantInStore(props);
      if (merchant !== 302 && merchant) {
        return merchant.name;
      }
    }

    return merchantStateName;
  }

  return "";
};

export const getCorrectFeaturedDeals = async (props: IGetCorrectFeaturedDeals) => {
  const {
    platformId,
    dealSelectionCount,
    filterIds,
    selectionChoice,
    dealSelection,
    merchantId,
    isMerchantSinglePage,
    merchantSingleFilters,
    labelFilter
  } = props;

  if (isMerchantSinglePage) {
    // If it's the merchant single page, we fetch all deals based on merchantId and filters.
    // If the result is 0 or less than the amount of deals we want to show, we fetch more deals based ONLY on the filters.
    // If the result after this fetch is 0 again, we return an empty array, because no deals have been found.
    // If we would fetch deals without any filters, the result might be too different from the desired deals.

    let deals: OutDealDTO[] | [] = [];
    deals =
      (await getFeaturedDealsMapper({
        filterIds: merchantSingleFilters,
        platformId,
        dealSelectionCount,
        merchantId,
        labelFilter
      })) || [];

    if (!deals || deals.length === 0 || deals.length < (dealSelectionCount || 0)) {
      const newDeals =
        (await getFeaturedDealsMapper({
          filterIds: merchantSingleFilters,
          platformId,
          dealSelectionCount: (dealSelectionCount || 0) - deals.length,
          labelFilter
        })) || [];
      deals = getUniqueFromArray([...deals, ...newDeals], "id");
    }

    return getUniqueFromArray(deals, "id");
  }

  return await getFeaturedDealsMapper({
    filterIds,
    platformId,
    dealSelectionCount,
    selectionChoice,
    dealSelection,
    labelFilter
  });
};

export const mapDealsToFeaturedDeals = (deals: OutDealDTO[]): IOutFeaturedDealDTO[] =>
  deals.map(deal => {
    const mappedDeal: IOutFeaturedDealDTO = {
      id: deal.id,
      name: deal.name,
      discount: deal.discount,
      image: { url: deal.image?.url || "", title: deal.image?.title, alt: deal.image?.alt },
      price: deal.price,
      beforePrice: deal.beforePrice,
      currency: deal.currency,
      merchant: {
        name: deal.merchant?.name || "",
        slug: deal.merchant?.slug || "",
        logoImage: deal.merchant?.logoImage
      },
      featuredFilter: { name: deal.featuredFilter?.name || "", label: deal.featuredFilter?.label },
      sponsoredPosition: deal.sponsoredPosition
    };
    return mappedDeal;
  });
