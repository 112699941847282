import { SearchInput } from "@app/components/search-input/search-input";
import { IconComponent } from "@app/core";
import React, { useEffect, useState } from "react";
import SearchIcon from "@assets/icons/search.svg";
import styles from "../mobile-nav-bar-component.module.scss";
import ChevronLeftIcon from "@assets/icons/chevron-left.svg";
import { Icon } from "@app/components/icon/icon";
import { formatMessage } from "@app/translations/intl";
import { OutDealDTO } from "@app/bf-api";
import { searchItems, searchPages } from "@app/redux/thunks/navbar-search.thunk";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import { SearchResults } from "../../search-results/search-results";
import CrossIcon from "@assets/icons/search-cross.svg";
import { IOutFeaturedMerchantDTO } from "@app/core/new-merchant-card/featured-merchants.interface";

interface IProps {
  popularDeals?: OutDealDTO[];
  popularMerchants?: IOutFeaturedMerchantDTO[];
  isSearchOpen: boolean;
  closeSearch: () => void;
  openSearch: () => void;
}

export const Search = (props: IProps) => {
  const dispatch = useDispatch();

  const platformId = Number(formatMessage({ id: "global.platformId" }));
  const [searchValue, setSearchValue] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const closeSearch = () => {
    setSearchValue("");
    props.closeSearch();
  };

  const handleDelete = () => {
    setSearchValue("");
  };

  React.useEffect(() => {
    dispatch(searchItems(searchValue, platformId, 3));
    dispatch(searchPages(searchValue, platformId, 5));
  }, [searchValue]);

  useEffect(() => {
    if (props.isSearchOpen) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [props.isSearchOpen]);

  return (
    <div className={clsx(styles.search, props.isSearchOpen && styles.searchIsOpen)}>
      <div className={styles.top}>
        {props.isSearchOpen && (
          <button onClick={closeSearch} className={styles.icon}>
            <Icon icon={ChevronLeftIcon} />
          </button>
        )}
        <div className={styles.input}>
          <SearchInput
            id="searchInputMobile"
            icon={<IconComponent fillColor="white" size="14px" icon={SearchIcon} />}
            iconPosition="right"
            onChange={handleChange}
            placeholder={formatMessage({ id: "navBar.search.placeholder" })}
            type="text"
            value={searchValue}
            onFocus={props.openSearch}
            deleteIcon={<IconComponent fillColor="white" size="14px" icon={CrossIcon} />}
            onDelete={handleDelete}
          />
        </div>
      </div>
      {props.isSearchOpen && (
        <SearchResults
          searchValue={searchValue}
          popularDeals={props.popularDeals}
          popularMerchants={props.popularMerchants}
          closeSearch={closeSearch}
        />
      )}
    </div>
  );
};
