import React from "react";
import IconChevronRight from "@assets/icons/chevron-right-sharp.svg";
import { RouteComponentProps, withRouter } from "react-router";
import { ISearchPageData } from "@app/api/core/search-page-data";
import { ArrowPanel } from "@app/core/arrow-panel";
import styles from "../search-results.module.scss";

interface IProps {
  headerText: string;
  pages: ISearchPageData[];
  closeSearch: () => void;
}

// tslint:disable-next-line: arrow-return-shorthand
const component = (props: IProps & RouteComponentProps) => {
  const handleClick = () => {
    props.closeSearch();
  };

  return (
    <div className={styles.searchResultContainer}>
      <div className={styles.header}>{props.headerText}</div>

      <div className={styles["pagePanel"]}>
        {props.pages.map((page, index) => (
          <div key={index} role="button" onClick={handleClick}>
            <ArrowPanel
              icon={IconChevronRight}
              image={page.icon}
              link={{ title: page.title ? page.title : "", url: page.permalink ? page.permalink : "" }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export const NavBarResultPages = withRouter(component);
