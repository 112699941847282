import React from "react";
import { OutDealDTO } from "@app/bf-api";
import { useAppSelector } from "@app/redux/store";
import { formatMessage } from "@app/translations/intl";
import GreenCheckmarkIcon from "@assets/icons/green-checkmark.svg";

import styles from "./search-results.module.scss";
import { Icon } from "@app/components/icon/icon";
import { NavBarResultMerchants } from "./navbar-result-merchants/navbar-result-merchants";
import { NavBarResultDeals } from "./navbar-result-deals/navbar-result-deals";
import { IOutFeaturedMerchantDTO } from "@app/core/new-merchant-card/featured-merchants.interface";
import { NavBarResultPages } from "./navbar-result-pages/navbar-result-pages";

interface IProps {
  popularDeals?: OutDealDTO[];
  popularMerchants?: IOutFeaturedMerchantDTO[];
  searchValue: string;
  closeSearch: () => void;
}

export const SearchResults = (props: IProps) => {
  const { foundDeals, foundMerchants, foundPages } = useAppSelector(state => state.search);
  const popularDeals = props.popularDeals;
  const popularMerchants = props.popularMerchants;

  const showNoSearchResults = foundMerchants.length === 0 && foundDeals.length === 0 && props.searchValue !== "";
  const showPopular = foundMerchants.length === 0 && foundDeals.length === 0 && props.searchValue === "";
  const showSearchResults = foundMerchants.length > 0 || foundDeals.length > 0;
  const showFoundMerchants = foundMerchants.length > 0;
  const showFoundDeals = foundDeals.length > 0;
  const showFoundPages = foundPages.length > 0;

  return (
    <div className={styles.searchResults}>
      {showSearchResults && showFoundMerchants && (
        <NavBarResultMerchants
          button={{
            title: formatMessage({ id: "navbar.search.result.button.merchants" })
          }}
          merchants={foundMerchants}
          headerText={formatMessage({ id: "navbar.search.result.merchants" })}
          closeSearch={props.closeSearch}
        />
      )}
      {showSearchResults && showFoundDeals && (
        <NavBarResultDeals
          button={{
            title: formatMessage({ id: "navbar.search.result.button.deals" })
          }}
          deals={foundDeals}
          headerText={formatMessage({ id: "navbar.search.result.deals" })}
          closeSearch={props.closeSearch}
        />
      )}
      {showPopular && (
        <div className={styles.popularResults}>
          <NavBarResultMerchants
            button={{
              title: formatMessage({ id: "navbar.search.result.button.merchants" })
            }}
            merchants={popularMerchants || []}
            headerText={formatMessage({ id: "navbar.search.noResult.merchants" })}
            closeSearch={props.closeSearch}
          />
          <NavBarResultDeals
            button={{
              title: formatMessage({ id: "navbar.search.result.button.deals" })
            }}
            deals={popularDeals || []}
            headerText={formatMessage({ id: "navbar.search.noResult.deals" })}
            closeSearch={props.closeSearch}
          />
        </div>
      )}

      {showNoSearchResults && (
        <div className={styles.noResults}>
          <div className={styles.foundWPPages}>
            {showFoundPages && (
              <NavBarResultPages
                pages={foundPages}
                headerText={formatMessage({ id: "navbar.search.result.pages" })}
                closeSearch={props.closeSearch}
              />
            )}
          </div>
          <div className={styles.title}>
            {formatMessage(
              { id: "navbar.search.noResult.text" },
              {
                searchValue: props.searchValue
              }
            )}
          </div>
          <ul className={styles.steps}>
            <li>
              <span className={styles.icon}>
                <Icon icon={GreenCheckmarkIcon} />
              </span>
              <span>{formatMessage({ id: "navbar.search.noResult.tipOne" })}</span>
            </li>
            <li>
              <span className={styles.icon}>
                <Icon icon={GreenCheckmarkIcon} />
              </span>
              <span>{formatMessage({ id: "navbar.search.noResult.tipTwo" })}</span>
            </li>
          </ul>

          <NavBarResultMerchants
            button={{
              title: formatMessage({ id: "navbar.search.result.button.merchants" })
            }}
            merchants={popularMerchants || []}
            headerText={formatMessage({ id: "navbar.search.noResult.merchants" })}
            closeSearch={props.closeSearch}
          />
          <NavBarResultDeals
            button={{
              title: formatMessage({ id: "navbar.search.result.button.deals" })
            }}
            deals={popularDeals || []}
            headerText={formatMessage({ id: "navbar.search.noResult.deals" })}
            closeSearch={props.closeSearch}
          />
        </div>
      )}

      {!showNoSearchResults && showFoundPages && (
        <NavBarResultPages
          pages={foundPages}
          headerText={formatMessage({ id: "navbar.search.result.pages" })}
          closeSearch={props.closeSearch}
        />
      )}
    </div>
  );
};
