import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import { ShopSingleHeaderModule } from "@app/api/modules/shop-single-header/shop-single-header.module";
import { AnimatedEllipsisLoader } from "@app/core/animated-ellipsis-loader/animated-ellipsis-loader.component";
import { BreadcrumbComponent } from "@app/core/breadcrumb";
import { setCurrentMerchant } from "@app/redux/reducers/merchants";
import { useAppSelector } from "@app/redux/store";

import { MerchantHeaderMain } from "./main/merchant-header-main";
import { MerchantHeaderSidebar } from "./sidebar/merchant-header-sidebar";
import { MerchantHeaderSticky } from "./sticky-header/merchant-header-sticky";
import styles from "./merchant-single-header-v2-component.module.scss";
import { GoogleAdSense } from "@app/components/google-ad-sense/google-ad-sense";

export interface IMerchantSingleHeaderV2ComponentProps {
  shopSingleHeaderModule: ShopSingleHeaderModule;
}

const currentYear = new Date().getFullYear();

export const MerchantSingleHeaderV2Component = (props: IMerchantSingleHeaderV2ComponentProps) => {
  const { breadcrumbProps } = props.shopSingleHeaderModule;

  const dispatch = useDispatch();
  const dealsButtonRef = useRef<HTMLDivElement>(null);
  const merchant =
    props.shopSingleHeaderModule.merchant || useAppSelector(state => state.merchantOverview.currentMerchant);

  useEffect(() => {
    /* tslint:disable-next-line arrow-return-shorthand */
    return () => {
      dispatch(setCurrentMerchant(undefined));
    };
  }, []);

  if (!merchant) {
    return (
      <div className="uk-container">
        <div className={styles.productDetail}>
          <div className={styles.loadingSpinner}>
            <AnimatedEllipsisLoader />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="uk-container">
        <div className={styles.productDetail}>
          <div className={styles.head}>
            {breadcrumbProps && (
              <div className={styles.breadcrumbs}>
                <BreadcrumbComponent
                  variant={breadcrumbProps.variant}
                  breadcrumbs={breadcrumbProps.breadcrumbs}
                  backButton={breadcrumbProps.backButton}
                />
              </div>
            )}

            <h1>{`${merchant.name} Black Friday ${currentYear}`}</h1>
          </div>
          {merchant.adSenseScript && (
            <div className={styles.adsenseBanner}>
              <GoogleAdSense script={merchant.adSenseScript} />
            </div>
          )}
          <div className={styles.wrapper}>
            <MerchantHeaderMain
              shopSingleHeaderModule={props.shopSingleHeaderModule}
              merchant={merchant}
              dealsButtonRef={dealsButtonRef}
              className={styles.main}
            />
            <MerchantHeaderSidebar
              shopSingleHeaderModule={props.shopSingleHeaderModule}
              merchant={merchant}
              className={styles.sidebar}
            />
          </div>
        </div>
      </div>
      <MerchantHeaderSticky
        shopSingleHeaderModule={props.shopSingleHeaderModule}
        merchant={merchant}
        dealsButtonRef={dealsButtonRef}
      />
    </>
  );
};
