import * as React from "react";
import cssVars from "css-vars-ponyfill";
import { formatMessage } from "@app/translations/intl";

export interface IColorSetterProps {}

const ColorSetter = (props: IColorSetterProps): JSX.Element => {
  cssVars({
    variables: {
      "--colour-version-website": formatMessage({ id: "global.colors.normal" }),
      "--colour-version-website-RGB": formatMessage({ id: "global.colors.rgb" }),
      "--colour-version-website-darken7": formatMessage({ id: "global.colors.darken7" }),
      "--colour-version-website-darken16": formatMessage({ id: "global.colors.darken16" }),
      "--colour-version-website-darken35": formatMessage({ id: "global.colors.darken35" }),
      "--colour-version-website-lighten51": formatMessage({ id: "global.colors.lighten51" }),
      "--colour-version-website-lightAccent": formatMessage({ id: "global.colors.lightAccent" })
    }
  });

  return <></>;
};

export { ColorSetter };
