import { OutMerchantLinkDTO } from "@app/api/modules/merchant-link-list/merchant-link-list";
import { getBulkMerchantLinksApi } from "@app/middleware/api/bulk-merchants-get.api";

export interface IMerchantLinkList {
  type: "title" | "item";
  title: string;
  id: string;
  url?: string;
}

export const getMerchantList = (merchants?: OutMerchantLinkDTO[]): IMerchantLinkList[] => {
  if (!merchants || merchants.length === 0) return [];

  return (
    merchants.reduce((result: IMerchantLinkList[], curr: OutMerchantLinkDTO) => {
      const isTitle = getTitle(result?.[result.length - 1]?.title, curr.title);

      const titleItem = isTitle && { type: "title", title: isTitle, id: `${curr.id}-title` };

      const newListItem: IMerchantLinkList = {
        type: "item",
        title: curr.title || "",
        id: `${curr.id}`,
        url: curr.url
      };

      const newItems = [titleItem, newListItem].filter(Boolean) as IMerchantLinkList[];

      return [...result, ...newItems];
    }, []) || []
  );
};

const getTitle = (prevWord?: string, currWord?: string): string | undefined => {
  if (!prevWord && !currWord) return undefined;

  const isALetterRegex = /[a-z]*/gi;
  const prevFirstCharacter = prevWord?.trim()?.[0];
  const currIsALetter = (currWord?.trim()?.[0]?.match(isALetterRegex)?.filter(Boolean).length || 0) > 0;
  const currFirstCharacter = currIsALetter ? currWord?.trim()?.[0] : "#";

  if (!prevFirstCharacter) return currFirstCharacter;

  const isNewCharacter = prevFirstCharacter?.toLowerCase() !== currFirstCharacter?.toLowerCase();
  const prevIsALetter = (prevFirstCharacter?.match(isALetterRegex)?.filter(Boolean).length || 0) > 0;

  if (isNewCharacter && prevIsALetter) return currFirstCharacter;

  if (prevIsALetter && !currIsALetter) return currFirstCharacter;

  if (isNewCharacter && !prevIsALetter && currIsALetter) return currFirstCharacter;

  return undefined;
};

export const getMerchantLinkList = async (platformId: number): Promise<IMerchantLinkList[]> => {
  const merchantList = await getBulkMerchantLinksApi(platformId);

  return getMerchantList(merchantList);
};
