import { JsonSchemaModule } from "./../modules/json-schema/json-schema.module";
import * as express from "express";
import { PageModel } from "../pagebuilder/page.model";
import { urlSplitter } from "./utils/url-splitter";
import { HeaderInfoModule } from "../modules/header-info/header-info.module";
import { moduleSelector } from "./module-selector/module-selector";
import { postsWordPressApiGet } from "./wordpress-api/base-wp-api";
import { IBasePageInformation } from "@app/api/wp-page-fetcher/utils/set-base-page-information";
import { WordPressPostModule } from "../modules/wordpress-module/wordpress-module";
import { checkMerchantSinglePage } from "./utils/check-merchant-single-page";
import { MetaData, MetaDataRobots } from "../pagebuilder/meta-data";
import { IWPPageMeta, IWPPageMetaHrefLang } from "./module-selector/main-wp.interface";
import { BlogSingleHeaderModule } from "../modules/blog-single-header/blog-single-header";
import { HomepageHeader } from "../modules/homepage-header/homepage-header.module";
import { MediumHeroModule } from "../modules/medium-hero/medium-hero";
import { ShopSingleHeaderModule } from "../modules/shop-single-header/shop-single-header.module";
import Logger from "@app/util/logger";
import { IBaseModules } from "@app/redux/reducers/page";

import { ThunkDispatch } from "redux-thunk";
import { formatMessage } from "@app/translations/intl";

export interface IPageFetcherProps extends IBasePageInformation {
  baseModules?: IBaseModules;
  dispatch?: ThunkDispatch<any, any, any>;
  pageUrl: string;
  res?: express.Response;
  splittedUrl?: string[];
}

export interface IMerchantSingleMetaData {
  description?: string;
  hrefLang?: string;
  image?: string;
  robots?: MetaDataRobots;
  title?: string;
  jsonSchema?: string;
}

export const wpPageFetcher = async (props: IPageFetcherProps): Promise<PageModel | 302> => {
  const { pageUrl } = props;
  const splittedUrl = urlSplitter(pageUrl);

  return await returnWPPage({ ...props, splittedUrl });
};

const returnWPPage = async (props: IPageFetcherProps): Promise<PageModel | 302> => {
  const { errorPages, pageUrl, res, platformId, shopPageUrlName, splittedUrl } = props;

  try {
    const fixedUrl = fixWPUrl(platformId, pageUrl, shopPageUrlName, splittedUrl);
    const wpPage = await postsWordPressApiGet(platformId, fixedUrl);

    if (wpPage === 404) return pageNotFound(await errorPages.notFoundPage(), pageUrl, res);

    if (wpPage === 503 || !wpPage || wpPage.length === 0) {
      return pageNotAvailable(await errorPages.unavailablePage(), pageUrl, res);
    }

    const moduleList = await moduleSelector({ ...props, pageData: wpPage[0] });
    const merchantSingleHeader = moduleList?.filter(module => module?.name === "ShopSingleHeaderModule");

    const checkModuleOffline = moduleList?.find(module => module.status === 302);

    if (checkModuleOffline) {
      return 302;
    }

    if ((!merchantSingleHeader || merchantSingleHeader.length === 0) && fixedUrl === `/${shopPageUrlName}/single/`)
      return pageNotFound(await errorPages.notFoundPage(), pageUrl, res);
    if (!moduleList || moduleList.length === 0)
      return pageNotAvailable(await errorPages.unavailablePage(), pageUrl, res);

    const [page] = wpPage;

    return {
      id: `${page.data?.post?.ID}`,
      pageRes: 200,
      metaData: getPageMeta(
        page.meta,
        merchantSingleMetaData(
          splittedUrl,
          platformId,
          shopPageUrlName,
          moduleList.find(module => module.name === "ShopSingleHeaderModule") as ShopSingleHeaderModule
        ),
        moduleList
      ),
      route: pageUrl,
      wordPressPostModules: moduleList
    };
  } catch (e) {
    Logger.logError(e, "Error in: returnWPPage");

    return pageNotAvailable(await errorPages.unavailablePage(), pageUrl, res);
  }
};

export const merchantSingleMetaData = (
  splittedUrl?: string[],
  platformId?: number,
  shopPageUrlName?: string,
  shopSingleHeaderModule?: ShopSingleHeaderModule
): IMerchantSingleMetaData | undefined => {
  if (!splittedUrl || !platformId || !checkMerchantSinglePage(shopPageUrlName, splittedUrl) || !shopSingleHeaderModule)
    return undefined;

  return {
    title: shopSingleHeaderModule.seoData?.title,
    description: shopSingleHeaderModule.seoData?.description,
    image: shopSingleHeaderModule.merchant?.logoImage?.url || shopSingleHeaderModule.fallbackHeaderImage?.src,
    hrefLang: shopSingleHeaderModule.seoData?.alternateHTML,
    jsonSchema: shopSingleHeaderModule.seoData?.jsonSchema,
    robots: { follow: 0, index: 0 }
  };
};

const getPageMeta = (
  metaData?: IWPPageMeta,
  merchantMeta?: IMerchantSingleMetaData,
  moduleList?: WordPressPostModule[]
): MetaData => {
  const jsonSchemaModule = moduleList?.find(module => module.name === "jsonSchemaModule") as JsonSchemaModule;

  const metaTitle = merchantMeta?.title || metaData?.seoTitle || "";
  const metaDescription = merchantMeta?.description || metaData?.seoMetaDescription || "";
  const ogTitle = merchantMeta?.title || metaData?.facebookTitle || metaTitle;
  const ogDescription = merchantMeta?.description || metaData?.facebookDescription || metaDescription;
  const ogImage =
    merchantMeta?.image || metaData?.facebookImage || metaData?.featuredImage || getOgImageInHeaderModules(moduleList);
  const hrefLang = merchantMeta?.hrefLang || getHrefLangs(metaData?.hreflang);
  const robots = {
    index: merchantMeta?.robots?.index ?? metaData?.metaRobotIndex,
    follow:
      merchantMeta?.robots?.follow || merchantMeta?.robots?.follow === 0
        ? merchantMeta.robots.follow
        : metaData?.metaRobotFollow,
    advanced: metaData?.metaRobotAdvanced
  };
  const jsonSchema = merchantMeta?.jsonSchema || jsonSchemaModule?.jsonSchema;

  return {
    description: metaDescription,
    title: metaTitle,
    hrefLang: hrefLang,
    og: {
      title: ogTitle,
      description: ogDescription,
      image: ogImage
    },
    canonicalUrl: metaData?.canonicalUrl,
    twitter: {
      title: metaData?.twitterTitle,
      description: metaData?.twitterDescription,
      image: metaData?.twitterImage
    },
    jsonSchema,
    robots
  };
};

const getHrefLangs = (hrefLang?: IWPPageMetaHrefLang[]): string => {
  if (!hrefLang) return "";

  const mappedHrefs = hrefLang.map(href => {
    if (!href.value || !href.lang) return undefined;

    const hrefLang = href.lang.replace("_", "-").toLowerCase();

    return `<link rel="alternate" href="${href.value}" hreflang="${hrefLang}">`;
  });

  const hrefsToString = mappedHrefs.filter(mappedHref => mappedHref).join("");

  return hrefsToString;
};

const pageNotAvailable = (notAvailablePage: PageModel, pageUrl: string, res?: express.Response) => {
  Logger.logMessage(`Page is incomplete: ${pageUrl}`);
  if (res) {
    res.status(503);
  }
  return { ...notAvailablePage, wordPressPostModules: notAvailablePage.wordPressPostModules.filter(Boolean) };
};

const pageNotFound = (notFoundPage: PageModel, pageUrl: string, res?: express.Response) => {
  Logger.logMessage(`Page not found: ${pageUrl}`);

  if (res) {
    res.status(404);
  }
  return { ...notFoundPage, wordPressPostModules: notFoundPage.wordPressPostModules.filter(Boolean) };
};

const fixWPUrl = (
  platformId: number,
  pageUrl: string,
  shopPageUrlName: string,
  splittedUrl?: string[]
): string | undefined => {
  const merchantSinglePage = `/${shopPageUrlName}/single/`;

  if (!splittedUrl) return pageUrl;

  if (pageUrl === merchantSinglePage) return undefined;

  if (checkMerchantSinglePage(shopPageUrlName, splittedUrl)) {
    return merchantSinglePage;
  }

  const urlAddition = formatMessage({ id: "global.urlAddition" });

  if (splittedUrl[0] && splittedUrl[0].toLowerCase() === urlAddition) {
    splittedUrl.shift();
    const urlFromSplit = splittedUrl.join("/");

    return splittedUrl.length > 0 ? `/${urlFromSplit}/` : "/";
  }

  return pageUrl;
};

const getOgImageInHeaderModules = (modules?: WordPressPostModule[]): string => {
  if (!modules) return "";

  let image;

  modules.forEach(module => {
    switch (module.name) {
      case "HeaderInfo":
        const headerInfoModule = module as HeaderInfoModule;
        image = headerInfoModule.image?.src;
        break;
      case "BlogSingleHeaderModule":
        const blogSingleHeaderModule = module as BlogSingleHeaderModule;
        image = blogSingleHeaderModule.image?.src;
        break;
      case "HomepageHeader":
        const headerHomepage = module as HomepageHeader;
        image = headerHomepage.slides[0].image;
        break;
      case "MediumHeroModule":
        const headerSmallImage = module as MediumHeroModule;
        image = headerSmallImage.image?.src;
        break;
      case "ShopSingleHeaderModule":
        const shopSingleHeaderModule = module as ShopSingleHeaderModule;
        image = shopSingleHeaderModule.fallbackHeaderImage?.src;
        break;
      default:
        return "";
    }
  });

  return image || "";
};
