import * as React from "react";

import styles from "./landing-page-component.module.scss";
import { ImageComponent, IconComponent, ArrowPanel } from "@app/core";
import greenCheckmark from "@assets/icons/green-checkmark.svg";
import { LandingPageModule } from "@app/api/modules/landing-page/landing-page";

export interface ILandingPageModuleProps {
  landingPageModule: LandingPageModule;
}

const LandingPageComponent = (props: ILandingPageModuleProps) => (
  <div className={styles.landingPage}>
    <div className={styles.left}>
      <div className={styles.logo}>
        <ImageComponent src={props.landingPageModule.topLogo?.src} />
      </div>
      <div className={styles.header}>{props.landingPageModule.title}</div>
      <div className={styles.checkMarks}>
        {props.landingPageModule.perks.map((perk, index) => (
          <span className={styles.checkMark} key={index}>
            <IconComponent icon={greenCheckmark} size="13px" />
            <span className={styles.checkMarkSpan}>{perk}</span>
          </span>
        ))}
      </div>
      <div className={styles.countryCards}>
        {props.landingPageModule.countries?.map(country => (
          <div className={styles.countryCard} key={country.country}>
            <ArrowPanel
              link={{
                title: country?.country || "",
                url: country?.link || ""
              }}
              icon={country?.icon?.src}
              isExternalUrl
            />
          </div>
        ))}
      </div>
    </div>
    <div className={styles.right}>
      <img src={props.landingPageModule.rightImage?.src} alt={props.landingPageModule.rightImage?.alt} />
    </div>
  </div>
);

export { LandingPageComponent };
