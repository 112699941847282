import { BlogBodyTextModule } from "@app/api/core/blog-bodytext/blog-bodytext";
import { IWPBlogBodyTextModuleFull } from "./blog-body-text-module.interface";
import { getWPModuleSpacing } from "@app/api/wp-page-fetcher/utils/get-wp-module-spacing";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import { sidebarComponentMapper } from "@app/api/wp-page-fetcher/module-selector/component-mappers/sidebar-component";
import { SeoDTO } from "@app/bf-api";

import { stripHtmlString } from "@app/api/wp-page-fetcher/utils/strip-html-string";
import { checkMerchantSinglePage } from "@app/api/wp-page-fetcher/utils/check-merchant-single-page";
import { IModuleFetcher } from "@app/api/wp-page-fetcher/module-selector/module-selector";
import Logger from "@app/util/logger";
import { formatMessage } from "@app/translations/intl";

import { setSingleMerchantInStore } from "../merchant-single-module";

export interface IBlogBodyTextModuleMapper extends IModuleFetcher {
  module: IWPBlogBodyTextModuleFull;
}

export const blogBodyTextModuleMapper = async (
  props: IBlogBodyTextModuleMapper
): Promise<BlogBodyTextModule | undefined> => {
  try {
    const {
      module,
      module: { data },
      platformId,
      res,
      shopPageUrlName,
      splittedUrl
    } = props;

    if (!data) return moduleMapperThrowMessage("No data found in blogBodyTextModuleMapper");

    const sidebar = await sidebarComponentMapper({ platformId, sidebarData: data.bodyTextSidebar, res });
    const isMerchantSinglePage = checkMerchantSinglePage(shopPageUrlName, splittedUrl);

    const bodyText =
      (await checkAndGetMerchantSingleBodyText(
        props,
        isMerchantSinglePage,
        shopPageUrlName,
        splittedUrl,
        data.bodyText
      )) || data.bodyText;

    if (!sidebar && !stripHtmlString(bodyText || "") && !isMerchantSinglePage) return undefined;

    return {
      id: "1",
      name: "BlogBodyTextModule",
      ...getWPModuleSpacing(module.data, module.setMargins),
      background: { backgroundColour: data.bgColor },
      bodyText: {
        html: bodyText || "",
        id: "1",
        name: "BlogBodyText"
      },
      useMerchantSingleSeoData: checkMerchantSinglePage(shopPageUrlName, splittedUrl),
      showSharingOptions: data.sharingOptions,
      dateTime: getReadingTimeText(platformId, Number(data.readingTime)),
      ...(sidebar && { sidebarItems: sidebar })
    };
  } catch (e) {
    Logger.logError(e, "Error in: blogBodyTextModuleMapper");

    return undefined;
  }
};

const checkAndGetMerchantSingleBodyText = async (
  props: IBlogBodyTextModuleMapper,
  isMerchantSinglePage: boolean,
  shopPageUrlName?: string,
  splittedUrl?: string[],
  bodytext?: string
): Promise<string | undefined> => {
  if (!splittedUrl || !shopPageUrlName || !isMerchantSinglePage) return undefined;

  try {
    if (!bodytext) {
      const merchantSeo = await getMerchantSeo(props);

      return merchantSeo?.pageText || "";
    }
  } catch (e) {
    Logger.logError(e, "Error in: checkAndGetMerchantSingleBodyText");
    return undefined;
  }
};

const getMerchantSeo = async (props: IBlogBodyTextModuleMapper): Promise<SeoDTO | undefined> => {
  if (props.res) {
    const merchantStateSeo = props.res.locals.store.getState()?.merchantOverview?.currentMerchant?.name;

    if (!merchantStateSeo) {
      const merchant = await setSingleMerchantInStore(props);
      if (merchant !== 302 && merchant?.seo) {
        return merchant?.seo;
      }
    }

    return merchantStateSeo;
  }

  return undefined;
};

const getReadingTimeText = (platformId: number, minutes?: number): string => {
  if (!minutes) return "";

  return minutes === 1
    ? `${minutes} ${formatMessage({ id: "readingTimeText.singular" })}`
    : `${minutes} ${formatMessage({ id: "readingTimeText.plural" })}`;
};
