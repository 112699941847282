import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";

import instagramIcon from "@assets/icons/new-instagram.svg";
import facebookIcon from "@assets/icons/new-facebook.svg";
import mailIcon from "@assets/icons/new-mail.svg";
import youtubeIcon from "@assets/icons/new-youtube.svg";
import pinterestIcon from "@assets/icons/new-pinterest.svg";
import NewsletterArrow from "@assets/icons/newsletter-arrow-down.svg";
import CheckIcon from "@assets/icons/new-green-checkmark.svg";

import { IconComponent, ImageComponent, ResourceTextComponent } from "@app/core/";
import { breakPointMobileBig } from "@app/util/detect-view";
import { FooterModule } from "@app/api/modules/footer/footer.module";
import { LinkComponent } from "@app/core/link";
import { INewNewsletterFormValues, NewNewsletterFormComponent } from "@app/core/newsletter";
import { subscribeToNewsletter } from "@app/redux/thunks/newsletter.thunk";
import { useAppSelector } from "@app/redux/store";
import { ReCaptcha } from "@app/core/recaptcha/recaptcha.component";
import { getTotalAmountOfMerchants } from "@app/redux/thunks/merchant-overview.thunk";
import { getTotalAmountOfDeals } from "@app/redux/thunks/deal-overview.thunk";
import { formatMessage } from "@app/translations/intl";

import { FooterMobileComponent } from "./footer-mobile/footer-mobile.component";
import { FooterDesktopComponent } from "./footer-desktop/footer-desktop.component";
import styles from "./footer-component.module.scss";

export interface IFooterComponentProps {
  footerModule: FooterModule;
}

const component = (props: IFooterComponentProps & RouteComponentProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { totalAmountOfMerchants: rdxTotalAmountOfMerchants } = useAppSelector(state => state.merchantOverview);
  const { platformId, screenSize } = useAppSelector(state => state.settings);
  const { totalAmountOfDeals: rdxTotalAmountOfDeals } = useAppSelector(state => state.dealOverview);

  const [totalAmountOfMerchants, setTotalAmountOfMerchants] = useState<number>(
    props.footerModule.totalAmountOfMerchants || 0
  );
  const [totalAmountOfDeals, setTotalAmountOfDeals] = useState<number>(props.footerModule.totalAmountOfDeals || 0);

  const [recaptchaToken, setRecaptchaToken] = React.useState("");
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const onSubmit = (values: INewNewsletterFormValues) => {
    if (values.emailAddress) {
      dispatch(subscribeToNewsletter(values, recaptchaToken));
      setIsSubmitted(true);
    }
  };

  useEffect(() => {
    if (!totalAmountOfMerchants) {
      dispatch(getTotalAmountOfMerchants(platformId));
      setTotalAmountOfMerchants(rdxTotalAmountOfMerchants);
    }
    if (!totalAmountOfDeals) {
      dispatch(getTotalAmountOfDeals(platformId));
      setTotalAmountOfDeals(rdxTotalAmountOfDeals);
    }
  }, [rdxTotalAmountOfDeals, rdxTotalAmountOfMerchants]);

  const socialItems = [
    {
      image: {
        src: facebookIcon,
        alt: "",
        title: ""
      },
      link: intl.messages["footer.middle.social.link.facebook"]
        ? formatMessage({ id: "footer.middle.social.link.facebook" })
        : ""
    },
    {
      image: {
        src: instagramIcon,
        alt: "",
        title: ""
      },
      link: intl.messages["footer.middle.social.link.instagram"]
        ? formatMessage({ id: "footer.middle.social.link.instagram" })
        : ""
    },
    {
      image: {
        src: mailIcon,
        alt: "",
        title: ""
      },
      link: intl.messages["footer.middle.social.link.mail"]
        ? formatMessage({ id: "footer.middle.social.link.mail" })
        : ""
    },
    {
      image: {
        src: youtubeIcon,
        alt: "",
        title: ""
      },
      link: intl.messages["footer.middle.social.link.youtube"]
        ? formatMessage({ id: "footer.middle.social.link.youtube" })
        : ""
    },
    {
      image: {
        src: pinterestIcon,
        alt: "",
        title: ""
      },
      link: intl.messages["footer.middle.social.link.pinterest"]
        ? formatMessage({ id: "footer.middle.social.link.pinterest" })
        : ""
    }
  ].filter(socialItem => socialItem.link);

  const ifMobile = screenSize && screenSize.breakpointPixels <= breakPointMobileBig;

  const renderFooterBody = (
    <div>
      <div className={styles["footer__column-body"]}>
        <h3 className={styles["footer__column-title"]}>
          <ResourceTextComponent resourceKey={"footer.newsLetter.title"} />
        </h3>
        <div className={styles["footer__column-description-section"]}>
          <div className={styles["footer__column-description"]}>
            <span className={styles["footer__column-description-item"]}>
              <div className={styles["footer__column-description-item-icon"]}>
                <IconComponent size={"12px"} icon={CheckIcon} />
              </div>
              <ResourceTextComponent resourceKey="footer.newsLetter.description.item.one" />
            </span>
            <span className={styles["footer__column-description-item"]}>
              <div className={styles["footer__column-description-item-icon"]}>
                <IconComponent size={"12px"} icon={CheckIcon} />
              </div>
              <ResourceTextComponent resourceKey="footer.newsLetter.description.item.two" />
            </span>
            <span className={styles["footer__column-description-item"]}>
              <div className={styles["footer__column-description-item-icon"]}>
                <IconComponent size={"12px"} icon={CheckIcon} />
              </div>
              <ResourceTextComponent resourceKey="footer.newsLetter.description.item.three" />
            </span>
          </div>
          <div className={styles.arrowIcon}>
            <IconComponent size="" icon={NewsletterArrow} />
          </div>
        </div>

        <div className={styles.applyForm}>
          <ReCaptcha action="newsletter" callBackToken={setRecaptchaToken} />
          <NewNewsletterFormComponent intl={intl} onSubmit={onSubmit} isSubmitted={isSubmitted} footerVersion />
        </div>
      </div>
    </div>
  );

  const onHomeClick = () => {
    props.history.push(props.footerModule.bottomFooter.logoLink);
    window.scroll(0, 0);
  };

  return (
    <div className={styles["footer"]}>
      {ifMobile ? (
        <FooterMobileComponent
          footerModule={props.footerModule}
          socialItems={socialItems}
          totalAmountOfMerchants={totalAmountOfMerchants}
          totalAmountOfDeals={totalAmountOfDeals}
          footerBody={renderFooterBody}
        />
      ) : (
        <FooterDesktopComponent
          footerModule={props.footerModule}
          socialItems={socialItems}
          totalAmountOfMerchants={totalAmountOfMerchants}
          totalAmountOfDeals={totalAmountOfDeals}
          footerBody={renderFooterBody}
        />
      )}

      <div className={styles["footer__bottom"]}>
        <div className="uk-container">
          <div className={styles["footer__bottom-holder"]}>
            <div className={styles["footer__logo-box"]}>
              <a
                role="button"
                aria-label="Logo link"
                onClick={onHomeClick}
                className={`uk-visible@m ${styles["footer__logo"]}`}
              >
                <ImageComponent
                  src={props.footerModule.bottomFooter.logo.src}
                  alt={props.footerModule.bottomFooter.logo.alt}
                  title={props.footerModule.bottomFooter.logo.title}
                />
              </a>
              <span>
                <ResourceTextComponent
                  resourceKey={"footer.bottom.copyright"}
                  values={{ currentYear: new Date().getFullYear() }}
                />
              </span>
            </div>
            <ul className={styles["footer__nav"]}>
              {props.footerModule.bottomFooter.items.map((link, key) => (
                <li key={key}>
                  <LinkComponent to={link.url}>{link.title}</LinkComponent>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FooterComponent = withRouter(component);
