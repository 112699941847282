import * as React from "react";
import { Helmet } from "react-helmet";
import clsx from "clsx";

import { MetaData, MetaDataSocial, MetaDataRobots } from "@app/api/pagebuilder/meta-data";
import { PageResTypes } from "@app/api/pagebuilder/page.model";
import { findPlatformContent } from "@app/api/wp-page-fetcher/utils/platform-content-array";

export interface IHelmetComponentProps {
  metaData?: MetaData;
  pageRes?: PageResTypes;
  pageUrl?: string;
  platformId?: number;
  rootUrl?: string;
}

interface IAlternateHtml {
  href: string;
  hrefLang: string;
}
type IState = {
  alternateHtmlsManual: IAlternateHtml[];
};

// This is a class component because helmet shits the bed when using Hooks
export class HelmetComponent extends React.Component<IHelmetComponentProps, IState> {
  private alternateHtmlsSSR: IAlternateHtml[] | undefined;
  public constructor(props: IHelmetComponentProps) {
    super(props);

    this.state = {
      alternateHtmlsManual: []
    };

    // set this only on server.
    if (typeof document === "undefined") {
      this.alternateHtmlsSSR = this.getAlternateHtmlObjects(this.props);
    }
  }

  public componentDidUpdate(props: IHelmetComponentProps) {
    // update the hrefs when new props are received.
    this.setHrefLangsManually(props);
  }

  public render() {
    const { metaData, platformId } = this.props;
    const canonicalUrl = `${this.props.rootUrl}${metaData?.canonicalUrl}`;

    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{metaData?.title || ""}</title>
          <meta name="description" content={metaData?.description} />

          <meta property="og:title" content={metaData?.og?.title} />
          <meta property="og:description" content={metaData?.og?.description} />
          <meta property="og:image" content={metaData?.og?.image} />

          {this.props.metaData?.jsonSchema && (
            <script type="application/ld+json">{this.props.metaData?.jsonSchema}</script>
          )}

          {this.getTwitterMetaData(metaData?.twitter)?.map(twitterMeta => twitterMeta)}
          {this.getRobotMetaData(metaData?.robots)}

          {this.props.pageRes === 200 && (
            <link rel="canonical" href={metaData?.canonicalUrl ? canonicalUrl : this.props.pageUrl} />
          )}

          <link rel="icon" type="image/png" href={this.getFavIconForPlatform(platformId)} />
          <link rel="shortcut icon" href={this.getFavIconForPlatform(platformId)} />
          {/* render this only on the server so Helmet can set these properties */}
          {this.alternateHtmlsSSR &&
            this.alternateHtmlsSSR.length > 0 &&
            this.alternateHtmlsSSR.map((alternateHtml, index) => (
              <link key={index} rel="alternate" href={alternateHtml.href} hrefLang={alternateHtml.hrefLang} />
            ))}
        </Helmet>
      </div>
    );
  }

  // this is ugly and relies on the user filling in texts that contain href and hreflang.
  private getAlternateHtmlObjects(props: IHelmetComponentProps): IAlternateHtml[] {
    if (!props.metaData || !props.metaData.hrefLang) return [];

    const alternateHtmls: IAlternateHtml[] = [];
    const hrefRegex = /href="[^"]*"/g;
    const hrefs = props.metaData.hrefLang.match(hrefRegex);

    const hrefLangRegex = /hreflang="[^"]*"/g;
    const hrefsLangs = props.metaData.hrefLang.match(hrefLangRegex);

    if (!hrefs || !hrefsLangs) return [];

    hrefs.forEach((item: string, index: number) => {
      let href = item.substring(6, item.length - 1);
      if (!!href && href.substring(0, 1) === "/") {
        href = `${props.rootUrl}${href}`;
      }
      alternateHtmls.push({
        href,
        hrefLang: hrefsLangs[index] ? hrefsLangs[index].substring(10, hrefsLangs[index].length - 1) : ""
      });
    });

    return alternateHtmls;
  }

  // 1 = nederland, 2 = belgie (nl), 3 = belgie (fr), 4 = frans
  private getFavIconForPlatform(platformId?: number): string {
    const platform = platformId && findPlatformContent(platformId);

    if (!platform) {
      return "https://media.blackfridaynederland.nl/static/FavIcons/favicon.png";
    }

    return platform.favIconPublic;
  }

  private getRobotMetaData(robotMetaData?: MetaDataRobots): JSX.Element | undefined {
    if (!robotMetaData) return undefined;
    const index = robotMetaData.index || 0;
    const follow = robotMetaData.follow || 0;
    const { advanced } = robotMetaData;

    const indexArray = ["index", "noindex", "index"];
    const followArray = ["follow", "nofollow"];

    const content = clsx(indexArray[index], followArray[follow], advanced).split(" ").join(",");

    return <meta name="robots" content={content} />;
  }

  private getTwitterMetaData(twitterMetaData?: MetaDataSocial): JSX.Element[] | undefined {
    if (!twitterMetaData) return undefined;
    const { title, description, image } = twitterMetaData;
    if (!title && !description && !image) return undefined;

    const metaDataItems = [<meta key="twitter:card" name="twitter:card" content="summary" />];

    if (title) {
      metaDataItems.push(<meta key="twitter:title" name="twitter:title" content={title} />);
    }
    if (description) {
      metaDataItems.push(<meta key="twitter:description" name="twitter:description" content={description} />);
    }
    if (image) {
      metaDataItems.push(<meta key="twitter:image" name="twitter:image" content={image} />);
    }

    return metaDataItems;
  }

  // Set the hrefs manually in the header because helmet doesnt update more than once.
  private setHrefLangsManually(props: IHelmetComponentProps) {
    if (typeof document === "undefined") {
      return;
    }

    const alternateHtmls = this.getAlternateHtmlObjects(props);
    if (JSON.stringify(alternateHtmls) === JSON.stringify(this.state.alternateHtmlsManual)) {
      return;
    }

    const links: any = document.querySelectorAll("link[rel*='alternate']");
    links.forEach((link: any) => {
      try {
        document.getElementsByTagName("head")[0].removeChild(link);
      } catch {
        return;
      }
    });

    if (!alternateHtmls || alternateHtmls.length === 0) {
      this.setState({ alternateHtmlsManual: [] });

      return;
    }

    alternateHtmls.forEach(alternateHtml => {
      const link = document.createElement("link");
      link.rel = "alternate";

      link.href = alternateHtml.href;
      link.hreflang = alternateHtml.hrefLang;

      document.getElementsByTagName("head")[0].appendChild(link);
    });

    this.setState({ alternateHtmlsManual: alternateHtmls });
  }
}
