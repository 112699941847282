import React, { useEffect, useState } from "react";
import LazyLoad from "react-lazyload";
import styles from "./image-component.module.scss";
import IconDefault from "@assets/icons/broken-image.svg";
import { IconComponent } from "@app/core/icon";
import { ResourceTextComponent } from "../resource-text";
import { validateUrl } from "@app/util/validate-url";

const OFFSET_LAZYLOADING = 2000;
export interface IImageComponentProps
  extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  className?: string;
  errorImage?: string;
  errorMessage?: string | true;
  forceImageTag?: boolean;
  isBlocking?: boolean;
  src?: string;
}

const isImageValid = (src: string) => {
  const promise = new Promise(resolve => {
    if (!validateUrl(src || "")) resolve(false);

    const img = document.createElement("img");
    img.onerror = () => resolve(false);
    img.onload = () => resolve(true);
    img.src = src;
  });

  return promise;
};

const component = (
  props: IImageComponentProps & React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
) => {
  const [errorState, setErrorState] = useState<boolean>(false);
  const { errorImage, errorMessage, isBlocking, className, forceImageTag, ...other } = props;
  const classNameStyle = className ? className : "";
  const computedErrorMessage =
    errorMessage === true ? <ResourceTextComponent resourceKey={"image.errorMessage"} /> : errorMessage;
  const computedErrorImage = errorImage || IconDefault;

  useEffect(() => {
    if (!validateUrl(props.src || "")) {
      return undefined;
    }

    if (props.src) {
      isImageValid(props.src).then(isValid => {
        if (!isValid) {
          setErrorState(true);
        } else {
          setErrorState(false);
        }
      });
    }
  }, [props.src]);

  if (errorState || !validateUrl(props.src || "")) {
    return (
      <div className={styles["placeholder"]}>
        <IconComponent icon={computedErrorImage} size="50%" />
        {computedErrorMessage && <span className={styles["placeholder-text"]}>{computedErrorMessage}</span>}
      </div>
    );
  }

  if (isBlocking) {
    return <img className={classNameStyle} {...other} />;
  }

  return (
    <LazyLoad offset={OFFSET_LAZYLOADING}>
      <img className={classNameStyle} {...other} />
    </LazyLoad>
  );
};

const ImageComponent = React.memo(component);
export { ImageComponent };
