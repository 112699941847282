import { MenuItem } from "@app/api/core/menu-item/menu-item";
import { OutDealDTO } from "@app/bf-api";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { ISearchPageData } from "@app/api/core/search-page-data";
import { IOutFeaturedMerchantDTO } from "@app/core/new-merchant-card/featured-merchants.interface";

export interface INavbarSearchCards {
  deals: OutDealDTO[];
  merchants: IOutFeaturedMerchantDTO[];
}

export interface INavbarSearchState {
  searchValue: string;
  foundDeals: OutDealDTO[];
  foundMerchants: IOutFeaturedMerchantDTO[];
  foundPages: ISearchPageData[];
  menuIsOpen: boolean;
  searchIsOpen: boolean;
  selectedMenuItem?: MenuItem;
}

const initialState: INavbarSearchState = {
  searchValue: "",
  foundDeals: [],
  foundMerchants: [],
  foundPages: [],
  menuIsOpen: false,
  searchIsOpen: false
};

export const slice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setNavbarSearchItems: (state, action: PayloadAction<INavbarSearchCards>) => {
      state.foundMerchants = action.payload.merchants || [];
      state.foundDeals = action.payload.deals || [];
    },
    setNavbarSearchText: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
    },
    setNavbarPages: (state, action: PayloadAction<ISearchPageData[]>) => {
      state.foundPages = action.payload || [];
    }
  }
});

export const navbarSearchState = (state: RootState) => state.search;

export const { setNavbarSearchItems, setNavbarSearchText, setNavbarPages } = slice.actions;

export default slice.reducer;
