import sanitizeHtml from "sanitize-html";

const cleanAdSenseScript = (script?: string): string => {
  const scriptComment = getAdSenseComment(script);
  const parsedScript = getCleanHtml(script);
  const scriptWithComment = [scriptComment, parsedScript].filter(Boolean).join(" ");

  if (!parsedScript) {
    return "";
  }

  return scriptWithComment;
};

export { cleanAdSenseScript };

const getAdSenseComment = (script?: string): string => {
  const regex = /<!--(.*?)-->/g;

  const match = (script || "")?.match(regex);

  return match?.[0] || "";
};

const getCleanHtml = (dirtyHtml?: string): string => {
  try {
    let cleanHtml = sanitizeHtml(dirtyHtml || "", {
      allowedTags: ["ins"],
      allowedAttributes: {
        ins: ["class", "style", "data-ad-client", "data-ad-slot", "data-ad-format", "data-full-width-responsive"]
      }
    });

    // Add data-ad-test="on" to the ins tag if we are a non-production environment
    if (
      process.env.API_URL.startsWith("test") ||
      process.env.API_URL.startsWith("acceptatie") ||
      process.env.HOST?.includes("localhost")
    ) {
      cleanHtml = cleanHtml.replace("></ins>", ' data-ad-test="on"></ins>');
    }

    return cleanHtml;
  } catch (error) {
    // tslint:disable-next-line:no-console
    console.error(error);
    return "";
  }
};
