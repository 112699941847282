import React from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";

import { MenuItem as IMenuItem } from "@app/api/core/menu-item/menu-item";
import { IconComponent } from "@app/core";
import ChevronDownIcon from "@assets/icons/chevron-down-navbar.svg";
import { ClickableComponent } from "@app/core/clickable";

import styles from "../new-new-nav-bar-component.module.scss";

interface IProps {
  item: IMenuItem;
  index: number;
}

export const NewMenuItem = (props: IProps) => {
  const [showMenu, setShowMenu] = React.useState(false);

  const hasSubCategories = props.item.children && props.item.children.length > 0;
  const mainCategories = props.item.children;

  const handleMouseEnter = () => {
    setShowMenu(true);
  };

  const handleMouseLeave = () => {
    setShowMenu(false);
  };

  const handleClick = () => {
    setShowMenu(false);
  };

  return (
    <>
      {(props.index === 0 && !hasSubCategories && !mainCategories) ||
      (props.index === 1 && !hasSubCategories && !mainCategories) ? (
        <div className={styles.menuItemButton}>
          <ClickableComponent title={props.item.title} variant="tertiary" href={props.item.url} />
        </div>
      ) : (
        <div className={styles.menuItem} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <Link to={props.item.url || "/"} onClick={handleClick}>
            {props.item.title}
          </Link>
          {hasSubCategories ? (
            <span className={styles.chevron}>
              <IconComponent size="6px" icon={ChevronDownIcon} />
            </span>
          ) : null}
          <div className={clsx(styles.content, showMenu ? styles.show : styles.hide)}>
            <div className="uk-container-keep-padding">
              <div className={styles.grid}>
                {mainCategories?.map((mainCategory, idx) => (
                  <div key={idx} className={styles.column}>
                    <Link onClick={handleClick} className={styles.main} to={mainCategory.url || "/"}>
                      {mainCategory.title}
                    </Link>
                    <ul>
                      {mainCategory?.children?.map((subCategory, subcatergoryIdx) => (
                        <li key={subcatergoryIdx}>
                          <Link onClick={handleClick} className={styles.sub} to={subCategory.url || "/"}>
                            {subCategory.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
