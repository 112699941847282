import React, { useRef } from "react";

import styles from "./dropdown-component.module.scss";
import { ClickableComponent } from "../clickable";
import useOutsideClick from "@app/util/outside-click";
import ChevronDown from "@assets/icons/chevron-down.svg";
import clsx from "clsx";

export interface IDropdownComponentProps {
  animated?: boolean;
  children: any;
  hasSelectedItems?: boolean;
  iconLeft?: string;
  iconRight?: string;
  isOpen?: boolean;
  isShareDropDown?: boolean;
  isFilterDropDown?: boolean;
  setIsOpen: (isOpen: boolean) => void;
  title: string;
  isRounded?: boolean;
}

const DropdownComponent = (props: IDropdownComponentProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const zIndexWhenOpen = 1021;

  useOutsideClick(wrapperRef, () => {
    if (props.isOpen) {
      props.setIsOpen(false);
    }
  });

  const getVariant = () => {
    if (props.isFilterDropDown) {
      return "white";
    }
    if (props.isOpen) {
      if (props.isShareDropDown) {
        return "secondary";
      }
      return "primary-inverted";
    }
    if (props.hasSelectedItems) {
      return "primary-brand";
    }

    return "secondary";
  };

  const onButtonClick = () => {
    if (props.isOpen) {
      props.setIsOpen(false);
    } else {
      props.setIsOpen(true);
    }
  };

  return (
    <div
      className={clsx(
        styles.dropdown,
        props.isOpen && styles["dropdown--open"],
        props.isShareDropDown && styles["dropdown--open--share"]
      )}
      ref={wrapperRef}
    >
      <div
        className={clsx(
          styles.dropdownChild,
          props.hasSelectedItems && !props.isOpen && styles["dropdownChild--has-selected"],
          props.isOpen && styles["dropdownChild--open"],
          props.isShareDropDown && styles["dropdownChild--share"],
          styles[`dropdownChild--${getVariant()}`]
        )}
      >
        <ClickableComponent
          onClick={onButtonClick}
          title={props.title}
          variant={getVariant()}
          zIndex={props.isOpen ? zIndexWhenOpen : undefined}
          iconRight={props.iconRight ? props.iconRight : ChevronDown}
          iconLeft={props.iconLeft}
          isRounded={props.isRounded}
          isFilterDropDown={props.isFilterDropDown}
        />
      </div>
      <div
        data-cy="dropdown-content"
        className={clsx(
          styles.content,
          props.isOpen && styles["content--open"],
          props.isShareDropDown && styles["content--open--share"]
        )}
      >
        {props.children}
      </div>
    </div>
  );
};

export { DropdownComponent };
