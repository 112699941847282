import { LOCALES } from "@app/constants";
import { PageModel } from "@app/api/pagebuilder/page.model";
import { PlatformType } from "@app/constants/platform";
import {
  notFoundPage,
  notAvailablePage,
  notAvailablePageUK,
  notFoundPageUK
} from "../../pagebuilder/non-wp-pages/error-pages";

export interface IErrorPages {
  notFoundPage: () => Promise<PageModel>;
  unavailablePage: () => Promise<PageModel>;
}

export interface IBasePageInformation {
  errorPages: IErrorPages;
  shopPageUrlName: string;
  dealPageUrlName: string;
  locale: string;
  platformId: number;
  urlAddition: string;
}

export const setBasePageInformation = async (platformVersion: number): Promise<IBasePageInformation> => {
  switch (platformVersion) {
    case PlatformType.NETHERLANDS:
      return {
        dealPageUrlName: "deals",
        locale: LOCALES.NL,
        errorPages: { notFoundPage: notFoundPage, unavailablePage: notAvailablePage },
        platformId: PlatformType.NETHERLANDS,
        shopPageUrlName: "winkels",
        urlAddition: ""
      };
    case PlatformType.BELGIANDUTCH:
      return {
        dealPageUrlName: "deals",
        locale: LOCALES.BENL,
        errorPages: { notFoundPage: notFoundPage, unavailablePage: notAvailablePage },
        platformId: PlatformType.BELGIANDUTCH,
        shopPageUrlName: "winkels",
        urlAddition: ""
      };
    case PlatformType.BELGIANFRENCH:
      return {
        dealPageUrlName: "offres",
        locale: LOCALES.BEFR,
        errorPages: { notFoundPage: notFoundPage, unavailablePage: notAvailablePage },
        platformId: PlatformType.BELGIANFRENCH,
        shopPageUrlName: "magasins",
        urlAddition: "fr"
      };
    case PlatformType.FRENCH:
      return {
        dealPageUrlName: "offres",
        locale: LOCALES.FR,
        errorPages: { notFoundPage: notFoundPage, unavailablePage: notAvailablePage },
        platformId: PlatformType.FRENCH,
        shopPageUrlName: "magasins",
        urlAddition: "fr"
      };
    case PlatformType.LANDINGPAGE:
      return {
        dealPageUrlName: "deals",
        locale: LOCALES.EN,
        errorPages: { notFoundPage: notFoundPage, unavailablePage: notAvailablePage },
        platformId: PlatformType.LANDINGPAGE,
        shopPageUrlName: "stores",
        urlAddition: ""
      };
    case PlatformType.UNITED_KINGDOM:
      return {
        dealPageUrlName: "deals",
        locale: LOCALES.EN,
        errorPages: { notFoundPage: notFoundPage, unavailablePage: notAvailablePage },
        platformId: PlatformType.UNITED_KINGDOM,
        shopPageUrlName: "stores",
        urlAddition: "uk"
      };
    case PlatformType.GERMANY:
      return {
        dealPageUrlName: "deals",
        locale: LOCALES.DE,
        errorPages: { notFoundPage: notFoundPage, unavailablePage: notAvailablePage },
        platformId: PlatformType.GERMANY,
        shopPageUrlName: "shops",
        urlAddition: "de"
      };
    case PlatformType.SWEDEN:
      return {
        dealPageUrlName: "erbjudanden",
        locale: LOCALES.SE,
        errorPages: { notFoundPage: notFoundPage, unavailablePage: notAvailablePage },
        platformId: PlatformType.SWEDEN,
        shopPageUrlName: "butiker",
        urlAddition: "se"
      };

    case PlatformType.SPAIN:
      return {
        dealPageUrlName: "ofertas",
        locale: LOCALES.ES,
        errorPages: { notFoundPage: notFoundPage, unavailablePage: notAvailablePage },
        platformId: PlatformType.SPAIN,
        shopPageUrlName: "tiendas",
        urlAddition: "es"
      };
    case PlatformType.ITALY:
      return {
        dealPageUrlName: "offerte",
        locale: LOCALES.IT,
        errorPages: { notFoundPage: notFoundPage, unavailablePage: notAvailablePage },
        platformId: PlatformType.ITALY,
        shopPageUrlName: "negozi",
        urlAddition: "it"
      };
    default:
      return {
        dealPageUrlName: "deals",
        locale: LOCALES.EN,
        errorPages: { notFoundPage: notFoundPageUK, unavailablePage: notAvailablePageUK },
        platformId: PlatformType.LANDINGPAGE,
        shopPageUrlName: "stores",
        urlAddition: ""
      };
  }
};
