import { WordPressPostModule } from "@app/api/modules/wordpress-module/wordpress-module";
import { WP_MODULES } from "./module-names";
import {
  headerBlogSingleModuleMapper,
  blogBodyTextModuleMapper,
  featuredBlogsModuleMapper,
  ctaSmallModuleMapper,
  featuredMerchantsModuleMapper,
  footerModuleMapper,
  notYetImplementedModuleMapper,
  headerHomepageModuleMapper,
  headerSmallImageModuleMapper,
  featuredDealsModuleMapper,
  featuredCategoriesModuleMapper,
  headerCTAModuleMapper,
  bannerModuleMapper,
  blogOverviewModuleMapper,
  merchantOverviewModuleMapper,
  dealOverviewModuleMapper,
  merchantSingleHeaderModuleMapper,
  landingPageModuleMapper,
  linkBoxesModuleMapper,
  merchantLinkListModuleMapper,
  UspModuleMapper,
  authorityModuleMapper,
  navBarModuleMapper,
  whenIsModuleMapper,
  categoryBlocksModuleMapper
} from "./module-mappers";
import { IWordPressPage } from "@app/api/wp-page-fetcher/module-selector/main-wp.interface";
import { IPageFetcherProps } from "@app/api/wp-page-fetcher/wp-page-fetcher";
import { IModuleOptions } from "./module-options.type";
import { PlatformType } from "@app/constants/platform";
import { IBaseModules } from "@app/redux/reducers/page";
import { headerInfoModuleMapper } from "./module-mappers/header-info-module/header-info-module-mapper";
import { jsonSchemaModuleMapper } from "./module-mappers/json-schema-module";
import { featuredDealsSearchModuleMapper } from "./module-mappers/featured-deals-search-module";

export interface IModuleSelector extends IPageFetcherProps {
  pageData: IWordPressPage;
}
export interface IModuleFetcher extends IPageFetcherProps {
  module: any;
  pageData: IWordPressPage;
}

export const moduleSelector = async (props: IModuleSelector): Promise<WordPressPostModule[] | undefined> => {
  const { pageData, platformId, baseModules } = props;

  const modulePromises = pageData?.data?.modules?.map(async module => fetchCorrectModule({ ...props, module }));
  const modulesFromPromises = modulePromises && (await Promise.all(modulePromises));

  if (platformId === PlatformType.LANDINGPAGE) {
    return modulesFromPromises as WordPressPostModule[];
  }

  return fetchAllModules(platformId, modulesFromPromises, baseModules);
};

const fetchAllModules = async (platformId: number, modules?: IModuleOptions, baseModules?: IBaseModules) => {
  //const checkEmptyModules = modules && modules.filter(result => !result).length > 0; // enable if we want a 503 if there are empty modules.

  const navBarModule = baseModules?.navBarModule || (await navBarModuleMapper(platformId));
  const footerModule = baseModules?.footerModule || (await footerModuleMapper(platformId));

  if (!modules || !navBarModule || !footerModule) return undefined;

  return [navBarModule, ...modules, footerModule].filter(module => module) as WordPressPostModule[];
};

const fetchCorrectModule = (props: IModuleFetcher) => {
  const moduleName = props.module.acf_fc_layout;

  switch (moduleName) {
    case WP_MODULES.SINGLE_STORE.wpName:
      props.module.data = props.module[WP_MODULES.SINGLE_STORE.wpFunName];
      delete props.module[WP_MODULES.SINGLE_STORE.wpFunName];
      return merchantSingleHeaderModuleMapper(props);
    case WP_MODULES.HEADER_BLOG_SINGLE.wpName:
      props.module.data = props.module[WP_MODULES.HEADER_BLOG_SINGLE.wpFunName];
      delete props.module[WP_MODULES.HEADER_BLOG_SINGLE.wpFunName];
      return headerBlogSingleModuleMapper(props);
    case WP_MODULES.HEADER_BLOG_BASIC.wpName:
      props.module.data = props.module[WP_MODULES.HEADER_BLOG_BASIC.wpFunName];
      delete props.module[WP_MODULES.HEADER_BLOG_BASIC.wpFunName];
      return headerBlogSingleModuleMapper(props);
    case WP_MODULES.FEATURED_BLOGS.wpName:
      props.module.data = props.module[WP_MODULES.FEATURED_BLOGS.wpFunName];
      delete props.module[WP_MODULES.FEATURED_BLOGS.wpFunName];
      return featuredBlogsModuleMapper(props);
    case WP_MODULES.CTA_SMALL.wpName:
      props.module.data = props.module[WP_MODULES.CTA_SMALL.wpFunName];
      delete props.module[WP_MODULES.CTA_SMALL.wpFunName];
      return ctaSmallModuleMapper(props);
    case WP_MODULES.FEATURED_MERCHANTS.wpName:
      props.module.data = props.module[WP_MODULES.FEATURED_MERCHANTS.wpFunName];
      delete props.module[WP_MODULES.FEATURED_MERCHANTS.wpFunName];
      return featuredMerchantsModuleMapper(props);
    case WP_MODULES.HEADER_HOMEPAGE.wpName:
      props.module.data = props.module[WP_MODULES.HEADER_HOMEPAGE.wpFunName];
      delete props.module[WP_MODULES.HEADER_HOMEPAGE.wpFunName];
      return headerHomepageModuleMapper(props);
    case WP_MODULES.HEADER_CTA.wpName:
      props.module.data = props.module[WP_MODULES.HEADER_CTA.wpFunName];
      delete props.module[WP_MODULES.HEADER_CTA.wpFunName];
      return headerCTAModuleMapper(props);
    case WP_MODULES.HEADER_SMALL_IMAGE.wpName:
      props.module.data = props.module[WP_MODULES.HEADER_SMALL_IMAGE.wpFunName];
      delete props.module[WP_MODULES.HEADER_SMALL_IMAGE.wpFunName];
      return headerSmallImageModuleMapper(props);
    case WP_MODULES.FEATURED_DEALS.wpName:
      props.module.data = props.module[WP_MODULES.FEATURED_DEALS.wpFunName];
      delete props.module[WP_MODULES.FEATURED_DEALS.wpFunName];
      return featuredDealsModuleMapper(props);
    case WP_MODULES.BODY_TEXT.wpName:
      props.module.data = props.module[WP_MODULES.BODY_TEXT.wpFunName];
      delete props.module[WP_MODULES.BODY_TEXT.wpFunName];
      return blogBodyTextModuleMapper(props);
    case WP_MODULES.FEATURED_CATEGORIES.wpName:
      props.module.data = props.module[WP_MODULES.FEATURED_CATEGORIES.wpFunName];
      delete props.module[WP_MODULES.FEATURED_CATEGORIES.wpFunName];
      return featuredCategoriesModuleMapper(props);
    case WP_MODULES.HEADER_INFO.wpName:
      props.module.data = props.module[WP_MODULES.HEADER_INFO.wpFunName];
      delete props.module[WP_MODULES.HEADER_INFO.wpFunName];
      return headerInfoModuleMapper(props);
    case WP_MODULES.BANNER.wpName:
      props.module.data = props.module[WP_MODULES.BANNER.wpFunName];
      delete props.module[WP_MODULES.BANNER.wpFunName];
      return bannerModuleMapper(props);
    case WP_MODULES.BLOG_OVERVIEW.wpName:
      props.module.data = props.module[WP_MODULES.BLOG_OVERVIEW.wpFunName];
      delete props.module[WP_MODULES.BLOG_OVERVIEW.wpFunName];
      return blogOverviewModuleMapper(props);
    case WP_MODULES.MERCHANT_OVERVIEW.wpName:
      props.module.data = props.module[WP_MODULES.MERCHANT_OVERVIEW.wpFunName];
      delete props.module[WP_MODULES.MERCHANT_OVERVIEW.wpFunName];
      return merchantOverviewModuleMapper(props);
    case WP_MODULES.DEAL_OVERVIEW.wpName:
      props.module.data = props.module[WP_MODULES.DEAL_OVERVIEW.wpFunName];
      delete props.module[WP_MODULES.DEAL_OVERVIEW.wpFunName];
      return dealOverviewModuleMapper(props);
    case WP_MODULES.LANDING_PAGE.wpName:
      props.module.data = props.module[WP_MODULES.LANDING_PAGE.wpFunName];
      delete props.module[WP_MODULES.LANDING_PAGE.wpFunName];
      return landingPageModuleMapper(props);
    case WP_MODULES.LINK_BOXES.wpName:
      props.module.data = props.module[WP_MODULES.LINK_BOXES.wpFunName];
      delete props.module[WP_MODULES.LINK_BOXES.wpFunName];
      return linkBoxesModuleMapper(props);
    case WP_MODULES.MERCHANT_LINK_LIST.wpName:
      props.module.data = props.module[WP_MODULES.MERCHANT_LINK_LIST.wpFunName];
      return merchantLinkListModuleMapper(props);
    case WP_MODULES.USPS.wpName:
      props.module.data = props.module[WP_MODULES.USPS.wpFunName];
      delete props.module[WP_MODULES.USPS.wpFunName];
      return UspModuleMapper(props);
    case WP_MODULES.AUTHORITY.wpName:
      props.module.data = props.module[WP_MODULES.AUTHORITY.wpFunName];
      delete props.module[WP_MODULES.AUTHORITY.wpFunName];
      return authorityModuleMapper(props);
    case WP_MODULES.JSON_SCHEMA.wpName:
      props.module.data = props.module[WP_MODULES.JSON_SCHEMA.wpFunName];
      delete props.module[WP_MODULES.JSON_SCHEMA.wpFunName];
      return jsonSchemaModuleMapper(props);
    case WP_MODULES.FEATURED_DEALS_SEARCH.wpName:
      props.module.data = props.module[WP_MODULES.FEATURED_DEALS_SEARCH.wpFunName];
      delete props.module[WP_MODULES.FEATURED_DEALS_SEARCH.wpFunName];
      return featuredDealsSearchModuleMapper(props);
    case WP_MODULES.WHEN_IS.wpName:
      props.module.data = props.module[WP_MODULES.WHEN_IS.wpFunName];
      delete props.module[WP_MODULES.WHEN_IS.wpFunName];
      return whenIsModuleMapper(props);
    case WP_MODULES.CATEGORY_BLOCKS.wpName:
      props.module.data = props.module[WP_MODULES.CATEGORY_BLOCKS.wpFunName];
      delete props.module[WP_MODULES.CATEGORY_BLOCKS.wpFunName];
      return categoryBlocksModuleMapper(props);
    default:
      return notYetImplementedModuleMapper(moduleName);
  }
};
