const percentageFromDates = (currentDate: Date, startDate?: Date, endDate?: Date): number => {
  if (!startDate || !endDate) {
    return 0;
  }

  const a = startDate.getTime();
  const b = endDate.getTime();
  const c = currentDate.getTime();
  const result = Math.round(((c - a) / (b - a)) * 100);

  return result > 0 ? result : 0;
};
export { percentageFromDates };
