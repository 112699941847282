import React from "react";
import ReactTooltip from "react-tooltip";

import styles from "./tooltip-provider.module.scss";

export const TooltipProvider = () => {
  return (
    <ReactTooltip
      isCapture
      effect="solid"
      globalEventOff="click"
      type="dark"
      className={styles.tooltip}
      backgroundColor="black"
    />
  );
};
