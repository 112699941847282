import { useState, useEffect } from "react";
import { debounce } from "./debounce";

export const useScrollPosition = (delay: number): number => {
  if (typeof window === "undefined") return 0;

  // Store the state
  const [scrollPos, setScrollPos] = useState<number>(window.scrollY);

  // On Scroll
  const onScroll = () => {
    debounce(setScroll, delay);
  };

  const setScroll = () => {
    setScrollPos(window.scrollY);
  };

  // Add and remove the window listener
  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  });

  // For now we return the raw value, as the current state implementation gives issues.
  // For now all delay is set to 0, so we don't really need a state.
  // When a delay is needed, we need to refactor this component and use refs.
  /* tslint:disable-next-line */
  return window.scrollY;
};
