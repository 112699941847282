/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  OutDealDTO,
  OutDealDTOFromJSON,
  OutDealDTOFromJSONTyped,
  OutDealDTOToJSON,
  OutMerchantDTO,
  OutMerchantDTOFromJSON,
  OutMerchantDTOFromJSONTyped,
  OutMerchantDTOToJSON
} from "./";

/**
 *
 * @export
 * @interface SearchResponseDTO
 */
export interface SearchResponseDTO {
  /**
   *
   * @type {Array<OutMerchantDTO>}
   * @memberof SearchResponseDTO
   */
  merchants?: Array<OutMerchantDTO>;
  /**
   *
   * @type {Array<OutDealDTO>}
   * @memberof SearchResponseDTO
   */
  deals?: Array<OutDealDTO>;
}

export function SearchResponseDTOFromJSON(json: any): SearchResponseDTO {
  return SearchResponseDTOFromJSONTyped(json, false);
}

export function SearchResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchResponseDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    merchants: !exists(json, "merchants") ? undefined : (json["merchants"] as Array<any>).map(OutMerchantDTOFromJSON),
    deals: !exists(json, "deals") ? undefined : (json["deals"] as Array<any>).map(OutDealDTOFromJSON)
  };
}

export function SearchResponseDTOToJSON(value?: SearchResponseDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    merchants: value.merchants === undefined ? undefined : (value.merchants as Array<any>).map(OutMerchantDTOToJSON),
    deals: value.deals === undefined ? undefined : (value.deals as Array<any>).map(OutDealDTOToJSON)
  };
}
