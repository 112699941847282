import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";

import { ISidebarComponentProps, SidebarComponent } from "@app/core/sidebar";
import { BodyTextComponent } from "@app/core/bodytext";
import { BlogBodyTextModule } from "@app/api/core/blog-bodytext/blog-bodytext";
import { ShareSocialDropdownComponent } from "@app/core/share-social-dropdown";
import { IconComponent } from "@app/core";
import ClockIcon from "@assets/icons/clock.svg";
import { formatMessage } from "@app/translations/intl";
import { useAppSelector } from "@app/redux/store";
import { stripHtmlString } from "@app/api/wp-page-fetcher/utils/strip-html-string";
import { IBaseModuleProps } from "@app/core/pagebuilder/components/module.component";

import styles from "./blog-body-text-component.module.scss";

export interface IBlogBodyTextComponentProps extends IBaseModuleProps {
  blogBodyTextModule: BlogBodyTextModule;
}

export const BlogBodyTextComponent = (props: IBlogBodyTextComponentProps) => {
  const [renderSidebar, setRenderSidebar] = useState<boolean>(true);
  const [renderMerchantBodytext, setRenderMerchantBodytext] = useState<string | undefined | null>(null);
  const { currentMerchant } = useAppSelector(state => state.merchantOverview);
  const rootRef = useRef<HTMLDivElement>(null);

  const { bodyText, sidebarItems, dateTime, showSharingOptions, useMerchantSingleSeoData } = props.blogBodyTextModule;

  const backgroundColorStyle = {
    ...(props.blogBodyTextModule.background?.backgroundColour && {
      backgroundColor: props.blogBodyTextModule.background.backgroundColour
    })
  };

  useEffect(() => {
    setRenderSidebar(checkIfSidebarContainsItems(sidebarItems));
  }, [props.blogBodyTextModule]);

  useEffect(() => {
    if (currentMerchant?.seo?.pageText && stripHtmlString(currentMerchant?.seo?.pageText) && useMerchantSingleSeoData) {
      setRenderMerchantBodytext(currentMerchant.seo.pageText);
    } else {
      setRenderMerchantBodytext(undefined);
    }
  }, [currentMerchant, useMerchantSingleSeoData]);

  useEffect(() => {
    // If there is no bodytext, hide the root component
    if (!bodyText?.html && !renderSidebar && renderMerchantBodytext === undefined) {
      if (props.rootRef?.current) {
        props.rootRef.current.style.display = "none";
      }
    } else if (props.rootRef?.current) {
      props.rootRef.current.style.display = "block";
    }
  }, [bodyText?.html, renderSidebar, renderMerchantBodytext]);

  return (
    <div className={clsx("uk-container", styles["blog-body-text"])} style={backgroundColorStyle} ref={rootRef}>
      <div className={styles["blog-body-text__container"]}>
        <div className={styles["blog-body-text__content"]}>
          {dateTime && (
            <div className={styles["blog-body-text__content__header"]}>
              <IconComponent icon={ClockIcon} size="14" /> {dateTime}
            </div>
          )}
          <BodyTextComponent bodyTextModule={{ ...bodyText, html: renderMerchantBodytext || bodyText.html }} />
          {showSharingOptions && (
            <div className={styles["blog-body-text__content__footer"]}>
              <ShareSocialDropdownComponent
                buttonTitle={formatMessage({ id: "blogBodyText.shareSocialDropdown.buttonTitle" })}
              />
            </div>
          )}
        </div>
        {renderSidebar && sidebarItems && (
          <div className={styles["blog-body-text__sidebar"]}>
            <SidebarComponent
              bannerModule={sidebarItems.bannerModule}
              featuredMerchantsModule={sidebarItems.featuredMerchantsModule}
              featuredDealsModule={sidebarItems.featuredDealsModule}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const checkIfSidebarContainsItems = (sidebarItems?: ISidebarComponentProps): boolean => {
  if (!sidebarItems) return false;
  const { bannerModule, featuredMerchantsModule: featuredStoresModule, featuredDealsModule } = sidebarItems;
  const noBannerModule = !bannerModule;
  const noFeaturedStoresModule =
    !featuredStoresModule || !featuredStoresModule.featuredItems || featuredStoresModule.featuredItems.length === 0;
  const noFeaturedDealsModule =
    !featuredDealsModule || !featuredDealsModule.featuredItems || featuredDealsModule.featuredItems.length === 0;

  return !(noBannerModule && noFeaturedStoresModule && noFeaturedDealsModule);
};
