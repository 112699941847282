import React from "react";
import styles from "./filter-bar-component.module.scss";
import HandPointing from "@assets/icons/hand-pointing.svg";
import StoreIcon from "@assets/icons/store.svg";
import { IconComponent, ResourceTextComponent } from "@app/core";
import { FilterItem } from "@app/api/core/filter/filter-item";
import { SearchableCheckboxDropdown } from "../searchable-checkbox-dropdown/searchable-checkbox-dropdown.component";
import { replaceOrAdd, filtersAreDifferent, getMerchantCount, getSelectedFilters } from "@app/util";
import { FIRST_MERCHANT_TAKE } from "@app/constants/overviews-numbers";
import { useDispatch, useSelector } from "react-redux";
import { settingsState, ViewType } from "@app/redux/reducers/settings";
import { clearAllFilters, merchantsState, setBrandFilters, setCategoryFilters } from "@app/redux/reducers/merchants";
import {
  getBrandTopFilters,
  getCategoryTopFilters,
  getMerchants,
  onBrandFilterSearch as rdxOnBrandFilterSearch,
  onCategoryFilterSearch as rdxOnCategoryFilterSearch
} from "@app/redux/thunks/merchant-overview.thunk";
import { formatMessage } from "@app/translations/intl";
import { SingleMobileFilter } from "../mobile-filter/single-mobile-filter.component";

export interface IFilterBarProps {
  onMobileFilterOpen: (isOpen: boolean) => void;
}

export const FilterBar = (props: IFilterBarProps) => {
  const dispatch = useDispatch();
  const settings = useSelector(settingsState);
  const { screenSize, platformId } = settings;

  const merchants = useSelector(merchantsState);
  const {
    brandFilterItems: rdxBrandFilterItems,
    categoryFilterItems: rdxCategoryFilterItems,
    totalResults,
    totalAmountOfMerchants: rdxTotalamountOfMerchants
  } = merchants;

  const [categoryFilterItems, setCategoryFilterItems] = React.useState<FilterItem[]>([]);
  const [brandFilterItems, setBrandFilterItems] = React.useState<FilterItem[]>([]);
  const [totalNumberOfFilters, setTotalNumberOfFilters] = React.useState<number>(0);
  const [selectedCategoryFilterItems, setSelectedCategoryFilterItems] = React.useState<FilterItem[]>([]);
  const [selectedBrandFilterItems, setSelectedBrandFilterItems] = React.useState<FilterItem[]>([]);
  const [totalAmountOfMerchants, setTotalAmountOfMerchants] = React.useState<number | undefined>(undefined);

  // Initial setup
  React.useEffect(() => {
    const totalSelectedFilters =
      rdxCategoryFilterItems.filter(item => item.isSelected).length +
      rdxBrandFilterItems.filter(item => item.isSelected).length;

    setTotalNumberOfFilters(totalSelectedFilters);

    const internalSelectedCategoryItems = rdxCategoryFilterItems.filter(item => item.isSelected);
    const internalSelectedBrandItems = rdxBrandFilterItems.filter(item => item.isSelected);

    setSelectedCategoryFilterItems(internalSelectedCategoryItems);
    setSelectedBrandFilterItems(internalSelectedBrandItems);

    const newCategoryFilterItems = getSelectedFilters(rdxCategoryFilterItems, internalSelectedCategoryItems);
    setCategoryFilterItems(newCategoryFilterItems);
    const newBrandFilterItems = getSelectedFilters(rdxBrandFilterItems, internalSelectedBrandItems);
    setBrandFilterItems(newBrandFilterItems);
  }, []);

  // New Category filter items received, set the selected items and add them to internal state.
  React.useEffect(() => {
    const newCategoryFilterItems = getSelectedFilters(rdxCategoryFilterItems, selectedCategoryFilterItems);
    setCategoryFilterItems(newCategoryFilterItems);
  }, [rdxCategoryFilterItems]);

  // New Brand filter items received, set the selected items and add them to internal state.
  React.useEffect(() => {
    const newBrandFilterItems = getSelectedFilters(rdxBrandFilterItems, selectedBrandFilterItems);
    setBrandFilterItems(newBrandFilterItems);
  }, [rdxBrandFilterItems]);

  const onCategoryFilterItemsChanged = (items: FilterItem[]) => {
    if (items && items.length === 0) {
      return;
    }

    const newFilters = replaceOrAdd(items, selectedCategoryFilterItems);

    if (filtersAreDifferent(newFilters, categoryFilterItems)) {
      dispatch(setCategoryFilters([...newFilters]));

      dispatch(getBrandTopFilters(newFilters, platformId));
      dispatch(getMerchants(0, FIRST_MERCHANT_TAKE, selectedCategoryFilterItems, selectedBrandFilterItems, platformId));
      setNewTotalAmountOfSelectedFilters(selectedCategoryFilterItems, selectedBrandFilterItems);
    }
  };

  const onBrandFilterItemsChanged = (items: FilterItem[]) => {
    if (items && items.length === 0) {
      return;
    }
    const newFilters = replaceOrAdd(items, selectedBrandFilterItems);

    if (filtersAreDifferent(newFilters, brandFilterItems)) {
      dispatch(setBrandFilters([...newFilters]));

      dispatch(getCategoryTopFilters(selectedBrandFilterItems, platformId));

      dispatch(getMerchants(0, FIRST_MERCHANT_TAKE, selectedCategoryFilterItems, selectedBrandFilterItems, platformId));
      setNewTotalAmountOfSelectedFilters(selectedCategoryFilterItems, selectedBrandFilterItems);
    }
  };

  const onBrandFilterSearch = (value: string) => {
    if (value) {
      dispatch(rdxOnBrandFilterSearch(value, categoryFilterItems));
    } else {
      dispatch(getBrandTopFilters(categoryFilterItems, platformId));
    }
  };

  const onCategoryFilterSearch = (value: string) => {
    if (value) {
      dispatch(rdxOnCategoryFilterSearch(value, brandFilterItems));
    } else {
      dispatch(getCategoryTopFilters(brandFilterItems, platformId));
    }
  };

  const onCategorySelection = (item: FilterItem) => {
    let newSelectedCategories: FilterItem[] = [];
    selectedCategoryFilterItems.find(selectedItem => selectedItem.id === item.id)
      ? (newSelectedCategories = selectedCategoryFilterItems.filter(selectedItem => selectedItem.id !== item.id))
      : (newSelectedCategories = [...selectedCategoryFilterItems, { ...item }]);

    setSelectedCategoryFilterItems(newSelectedCategories);
    setAmountOfMerchants(newSelectedCategories, selectedBrandFilterItems);
  };

  const onBrandSelection = (item: FilterItem) => {
    let newSelectedBrands: FilterItem[] = [];
    selectedBrandFilterItems.find(selectedItem => selectedItem.id === item.id)
      ? (newSelectedBrands = selectedBrandFilterItems.filter(selectedItem => selectedItem.id !== item.id))
      : (newSelectedBrands = [...selectedBrandFilterItems, { ...item }]);

    setAmountOfMerchants(selectedCategoryFilterItems, newSelectedBrands);
    setSelectedBrandFilterItems(newSelectedBrands);
  };

  const onCategorySelectionCleared = () => {
    setSelectedCategoryFilterItems([]);
    dispatch(getMerchants(0, FIRST_MERCHANT_TAKE, [], selectedBrandFilterItems, platformId));
    setNewTotalAmountOfSelectedFilters([], selectedBrandFilterItems);
    setAmountOfMerchants([], selectedBrandFilterItems);
  };

  const onBrandSelectionCleared = () => {
    setSelectedBrandFilterItems([]);
    dispatch(getMerchants(0, FIRST_MERCHANT_TAKE, selectedCategoryFilterItems, [], platformId));
    setNewTotalAmountOfSelectedFilters(selectedCategoryFilterItems, []);
    setAmountOfMerchants(selectedCategoryFilterItems, []);
  };

  const setAmountOfMerchants = async (categoryFilters: FilterItem[], brandFilters: FilterItem[]) => {
    const total = await getMerchantCount(platformId, categoryFilters, brandFilters);
    rdxTotalamountOfMerchants === total ? setTotalAmountOfMerchants(undefined) : setTotalAmountOfMerchants(total);
  };

  const setNewTotalAmountOfSelectedFilters = (
    selectedCategoryFilters: FilterItem[],
    selectedBrandFilters: FilterItem[]
  ) => {
    const totalSelectedFilters = selectedCategoryFilters.length + selectedBrandFilters.length;

    setTotalNumberOfFilters(totalSelectedFilters);
  };

  const onMobileBrandFilterChanged = (filters: FilterItem[]) => {
    let brandFilters: FilterItem[] = [];
    brandFilters = filters.filter(item => item.isSelected);

    const selectedCurrentBrandIds = selectedBrandFilterItems.map(item => item.id).join(";");
    const selectedNewBrandIds = brandFilters.map(item => item.id).join(";");

    setSelectedBrandFilterItems(brandFilters);
    dispatch(getBrandTopFilters(categoryFilterItems, platformId));

    const brandsChanged = selectedCurrentBrandIds !== selectedNewBrandIds;

    if (brandsChanged) {
      setAmountOfMerchants(categoryFilterItems, brandFilters);
      setNewTotalAmountOfSelectedFilters(categoryFilterItems, brandFilters);
    }
  };

  const onMobileCategoryFilterChanged = (filters: FilterItem[]) => {
    let categoryFilters: FilterItem[] = [];
    categoryFilters = filters.filter(item => item.isSelected);

    const selectedCurrentCategoryIds = selectedCategoryFilterItems.map(item => item.id).join(";");
    const selectedNewCategoryIds = categoryFilters.map(item => item.id).join(";");

    setSelectedCategoryFilterItems(categoryFilters);
    dispatch(getCategoryTopFilters(brandFilterItems, platformId));

    const categoryChanged = selectedCurrentCategoryIds !== selectedNewCategoryIds;

    if (categoryChanged) {
      setAmountOfMerchants(categoryFilters, brandFilterItems);
      setNewTotalAmountOfSelectedFilters(categoryFilters, brandFilterItems);
    }
  };

  const onMobileFilterOpenChanged = (isOpen: boolean): void => {
    if (!isOpen) {
      const newBrandFilterItems = getSelectedFilters(brandFilterItems, selectedBrandFilterItems);
      dispatch(setBrandFilters([...newBrandFilterItems]));
      const newCategoryFilterItems = getSelectedFilters(categoryFilterItems, selectedCategoryFilterItems);
      dispatch(setCategoryFilters([...newCategoryFilterItems]));

      dispatch(getMerchants(0, FIRST_MERCHANT_TAKE, selectedCategoryFilterItems, selectedBrandFilterItems, platformId));
    }
    setNewTotalAmountOfSelectedFilters(selectedCategoryFilterItems, selectedBrandFilterItems);
    props.onMobileFilterOpen(isOpen);
  };

  return (
    <div className={`${styles["filter-bar"]}  uk-container`}>
      {screenSize && screenSize.viewType > ViewType.Tablet ? (
        <div className={`${styles["filter-bar__filter-container"]}`}>
          <div className={`${styles["filter-bar__filter-list"]}  `}>
            <div className={`${styles["filter-label"]}`}>
              <span>{formatMessage({ id: "filterBar.filterTitle" })}</span>
              <span>
                <IconComponent icon={HandPointing} size={"20px"} />
              </span>
            </div>
            <div data-cy="filter_category">
              <SearchableCheckboxDropdown
                searchPlaceholder={formatMessage({
                  id: "filterBar.checkboxDropdown.categorySearchPlaceholder"
                })}
                deleteFilterLabel={formatMessage({
                  id: "filterBar.checkboxDropdown.categoryDeleteFilters"
                })}
                title={formatMessage({
                  id: "filterBar.checkboxDropdown.categoryTitle"
                })}
                showFilterName={formatMessage({
                  id: "filterBar.checkboxDropdown.categoryFilterName"
                })}
                items={categoryFilterItems}
                onChange={onCategoryFilterItemsChanged}
                onSearch={onCategoryFilterSearch}
                onSelection={onCategorySelection}
                onSelectionCleared={onCategorySelectionCleared}
                selectedItems={selectedCategoryFilterItems}
                totalSelectedItems={totalAmountOfMerchants}
                isFilterDropDown
                isRounded
              />
            </div>
            <div data-cy="filter_brand">
              <SearchableCheckboxDropdown
                searchPlaceholder={formatMessage({
                  id: "filterBar.checkboxDropdown.brandSearchPlaceholder"
                })}
                deleteFilterLabel={formatMessage({
                  id: "filterBar.checkboxDropdown.brandDeleteFilters"
                })}
                title={formatMessage({
                  id: "filterBar.checkboxDropdown.brandTitle"
                })}
                showFilterName={formatMessage({
                  id: "filterBar.checkboxDropdown.brandFilterName"
                })}
                items={brandFilterItems}
                onChange={onBrandFilterItemsChanged}
                onSearch={onBrandFilterSearch}
                onSelection={onBrandSelection}
                onSelectionCleared={onBrandSelectionCleared}
                selectedItems={selectedBrandFilterItems}
                totalSelectedItems={totalAmountOfMerchants}
                isFilterDropDown
                isRounded
              />
            </div>
            {totalNumberOfFilters > 0 && (
              <div data-cy="filter-bar_clear-filter" className={styles["filter-bar__clear-filter"]}>
                <span
                  role="link"
                  onClick={() => {
                    setSelectedBrandFilterItems([]);
                    setSelectedCategoryFilterItems([]);
                    dispatch(clearAllFilters());
                    dispatch(getBrandTopFilters([], platformId));
                    dispatch(getCategoryTopFilters([], platformId));

                    dispatch(getMerchants(0, FIRST_MERCHANT_TAKE, [], [], platformId));
                    setNewTotalAmountOfSelectedFilters([], []);
                    setAmountOfMerchants([], []);
                  }}
                >
                  <ResourceTextComponent
                    resourceKey={"filterBar.clearFilters"}
                    values={{
                      amountOfActiveFilters: totalNumberOfFilters
                    }}
                  />
                </span>
              </div>
            )}
          </div>

          <div className={styles["filter-bar__sort"]}>
            <div className={styles["filter__sort-filter"]}>
              <span className={styles["amount-of-shops"]}>
                <IconComponent icon={StoreIcon} size={"20px"} />
              </span>
              <ResourceTextComponent
                resourceKey={"filterBar.amountOfStores"}
                values={{
                  amountOfStores: totalResults
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className={`${styles["filter-bar__filter-container"]}`}>
          <>
            <SingleMobileFilter
              filters={categoryFilterItems}
              filterType="categories"
              onMobileFilterOpen={onMobileFilterOpenChanged}
              onMobileFilterSearch={onCategoryFilterSearch}
              onMobileFiltersChanged={onMobileCategoryFilterChanged}
              typeOfFilters="merchants"
              totalAmountOfResults={totalAmountOfMerchants}
            />
            <SingleMobileFilter
              filters={brandFilterItems}
              filterType="brands"
              onMobileFilterOpen={onMobileFilterOpenChanged}
              onMobileFilterSearch={onBrandFilterSearch}
              onMobileFiltersChanged={onMobileBrandFilterChanged}
              typeOfFilters="merchants"
              totalAmountOfResults={totalAmountOfMerchants}
            />
          </>
        </div>
      )}
    </div>
  );
};
