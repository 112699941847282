import { PlatformType } from "@app/constants/platform";

export const getCorrectUrl = (pageUrl: string, platformVersion: PlatformType): string => {
  let trimmedUrl = pageUrl.trim();
  const umtLinkIndex = trimmedUrl.indexOf("?");

  if (umtLinkIndex > -1) {
    return trimmedUrl.substring(0, umtLinkIndex);
  }

  if (trimmedUrl[trimmedUrl.length - 1] !== "/" && trimmedUrl !== "/") {
    return `${trimmedUrl}/`;
  }

  return trimmedUrl;
};
