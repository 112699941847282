import React, { useEffect, useState, useRef } from "react";
import clsx from "clsx";

import { ImageComponent } from "@app/core";
import ChevronRight from "@assets/icons/chevron-right-white.svg";
import {
  IOutSingleMerchantCampaignDTO,
  IOutSingleMerchantDTO
} from "@app/modules/merchant-single-header/merchant-single-header.interface";
import { RelAttributes } from "@app/constants";
import { ClickableComponent } from "../clickable";
import { CouponButtonComponent } from "./coupon-button.component";
import { CampaignStatusComponent } from "../campaign-status-label/campaign-status-label.component";
import { FeaturedFilterLabel } from "../featured-filter-label/featured-filter-label";
import styles from "./discount-card-component.module.scss";

interface IDiscountCardComponentProps {
  buttonLink: string;
  buttonText: string;
  campaign?: IOutSingleMerchantCampaignDTO;
  discountSubtitle: string;
  merchant?: IOutSingleMerchantDTO;
  showSticky?: boolean;
}

export const DiscountCardComponent = (props: IDiscountCardComponentProps) => {
  const { discountSubtitle, buttonText, buttonLink, campaign, merchant } = props;

  const [showStickyHeader, setShowStickyHeader] = useState<boolean>(false);

  const rootElement = useRef<HTMLDivElement>(null);
  const targetTooltip = useRef<HTMLButtonElement>(null);
  const targetTooltipSticky = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (!rootElement || !rootElement.current) return undefined;
    const rect = rootElement.current.getBoundingClientRect();
    const rectPosition = rect.bottom + window.scrollY;

    window.addEventListener("scroll", () => handleScrollChange(rectPosition - 100));
  }, []);

  const handleScrollChange = (scrollTarget: number) => {
    if (rootElement && rootElement.current) {
      const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

      if (winScroll >= scrollTarget) {
        return setShowStickyHeader(true);
      }

      return setShowStickyHeader(false);
    }
  };

  return (
    <div ref={rootElement} className={styles["discount-card"]}>
      <div
        className={clsx(
          showStickyHeader && styles["discount-card__sticky-opened"],
          styles["discount-card__sticky"],
          props.showSticky && styles["sticky-margin"]
        )}
      >
        <div className="uk-container">
          <div className={styles["discount-card__sticky__container"]}>
            <div className={styles["discount-card__sticky__content"]}>
              <div className={styles["discount-card__sticky__content__image"]}>
                <ImageComponent
                  alt={merchant?.logoImage?.alt}
                  title={merchant?.logoImage?.title}
                  sizes="56px"
                  src={merchant?.logoImage?.url}
                  forceImageTag
                  isBlocking
                />
              </div>
              <div className={styles["discount-card__sticky__content__merchant_info"]}>
                <div className={clsx(styles["discount-card__sticky__content__subtitle"], campaign?.couponCode)}>
                  {campaign?.excerpt}
                </div>
              </div>
            </div>
            <div className={styles["discount-card__sticky__actions"]}>
              {campaign?.couponCode && (
                <span className={clsx(styles["discount-subtitle"], campaign?.excerpt)}>{discountSubtitle}</span>
              )}
              {campaign?.couponCode && (
                <span ref={targetTooltipSticky} className={styles["actions-button-coupon"]}>
                  <CouponButtonComponent coupon={campaign.couponCode} hideIcon />
                </span>
              )}

              <span className={styles["actions-button-deals"]}>
                <ClickableComponent
                  iconRight={ChevronRight}
                  title={buttonText}
                  href={buttonLink}
                  target="_blank"
                  variant="primary-new-colored-medium"
                  forceExternal
                />
              </span>
            </div>
          </div>
        </div>
      </div>

      <a href={buttonLink} className={styles["discount-card__link"]} target="_blank" rel="sponsored">
        <div className={styles["discount-card__body"]}>
          <div className={styles["discount-card__campaignStatus"]}>
            <CampaignStatusComponent durationStatus={campaign?.durationStatus} />
          </div>
          {campaign?.featuredFilter?.name && (
            <div>
              <FeaturedFilterLabel
                className={styles["label"]}
                label={campaign?.featuredFilter?.label}
                text={campaign?.featuredFilter?.name}
                corners="both"
              />
            </div>
          )}
          <div className={styles["discount-card__image"]}>
            <ImageComponent
              alt={merchant?.logoImage?.alt}
              title={merchant?.logoImage?.title}
              src={merchant?.logoImage?.url}
              forceImageTag
              isBlocking
            />
          </div>
          <div className={styles["discount-card__content"]}>
            <div className={styles["discount-card__title"]}>{campaign?.excerpt}</div>
            {campaign?.couponCode && (
              <div className={clsx(styles["discount-card__subtitle"], "uk-visible@s")}>{discountSubtitle}</div>
            )}
          </div>
        </div>
      </a>

      <div className={styles["discount-card__action"]}>
        {campaign?.couponCode && (
          <span ref={targetTooltip}>
            <CouponButtonComponent coupon={campaign?.couponCode} />
          </span>
        )}
        <ClickableComponent
          iconRight={ChevronRight}
          title={buttonText}
          href={buttonLink}
          target="_blank"
          variant="primary-new-big"
          forceExternal
          relAttribute={RelAttributes.SPONSORED}
        />
      </div>
    </div>
  );
};
