import { JsonSchemaModule } from "./../../../../modules/json-schema/json-schema.module";
import { IModuleFetcher } from "../../module-selector";
import { IWPJsonSchemaFull } from "./json-schema.interface";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import Logger from "@app/util/logger";

export interface IJsonSchemaMapper extends IModuleFetcher {
  module: IWPJsonSchemaFull;
}

export const jsonSchemaModuleMapper = async (props: IJsonSchemaMapper): Promise<JsonSchemaModule | undefined> => {
  try {
    const {
      module: { data }
    } = props;
    if (!data) return moduleMapperThrowMessage("No data found in jsonSchemaMapper");

    return {
      id: "24",
      name: "jsonSchemaModule",
      jsonSchema: data?.jsonSchema
    } as JsonSchemaModule;
  } catch (e) {
    Logger.logError(e, "Error in: jsonSchemaModuleMapper");
    return undefined;
  }
};
