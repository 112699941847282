import { MenuItem } from "@app/api/core/menu-item/menu-item";
import { INavBarModule } from "@app/api/modules/nav-bar/navbar.module";
import { getMenuItems } from "@app/api/wp-page-fetcher/utils/get-wp-menu-items";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import { findPlatformContent } from "@app/api/wp-page-fetcher/utils/platform-content-array";
import { DealsApi, MerchantsApi } from "@app/bf-api";
import { formatMessage } from "@app/translations/intl";
import Logger from "@app/util/logger";
import { mapMerchantsToFeaturedMerchants } from "../featured-merchants-module";
import { getPlatformInformation } from "@app/util/api/get-platform-info";

export const navBarModuleMapper = async (platformId: number): Promise<INavBarModule | undefined> => {
  try {
    const promiseArray = await Promise.all([
      getPopularDeals(platformId),
      getPopularMerchants(platformId),
      getMenuItems(platformId),
      getPlatformInformation(platformId)
    ]);

    const popularDeals = promiseArray[0];
    const popularMerchants = promiseArray[1];
    const allMappedMenus = promiseArray[2];
    const platformInformation = promiseArray[3];

    if (!allMappedMenus) return moduleMapperThrowMessage("No menu items found in navBarModuleMapper");

    return {
      id: "11",
      name: "NavBarModule",
      background: { backgroundColour: "#f7f7f7" },
      logo: findPlatformContent(platformId).logo || emptyLogo,
      navBarTitle: platformInformation?.navBarTitle,
      navBarSubTitle: platformInformation?.navBarSubTitle,
      navBarLink: platformInformation?.navBarLink || formatMessage({ id: "global.homepageLink" }),
      popularDeals: popularDeals,
      popularMerchants: popularMerchants,
      menuItems: allMappedMenus?.header?.topMenu?.map(item => {
        return {
          title: item.title,
          url: item.url,
          children: item.children
        } as MenuItem;
      })
    };
  } catch (e) {
    Logger.logError(e, "Error in: newNavBarModuleMapper");
    return undefined;
  }
};

const emptyLogo = {
  src: "",
  alt: "",
  title: ""
};

const getPopularDeals = async (platformId: number) => {
  try {
    const dealsApi = new DealsApi();
    const result = await dealsApi.dealsGet(
      3,
      0,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      platformId
    );

    return result.deals ? result.deals : [];
  } catch (error) {
    Logger.logError("Error in get popularDeals", error as any);
  }
};

const getPopularMerchants = async (platformId: number) => {
  try {
    const merchantsApi = new MerchantsApi();
    const result = await merchantsApi.merchantsGet(3, 0, platformId, undefined, undefined);

    return result.merchants ? mapMerchantsToFeaturedMerchants(result.merchants) : [];
  } catch (error) {
    Logger.logError("Error in get popularDeals", error as any);
  }
};
