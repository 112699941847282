import React, { useState, useEffect } from "react";
import { OldCookiePopupComponent } from "./old-cookie-popup.component";
import { ResourceTextComponent } from "../resource-text/resource-text.component";
import { Link } from "react-router-dom";
import { PlatformType } from "@app/constants/platform";
import { formatMessage } from "@app/translations/intl";

export interface ICookiePolicyComponentProps {
  platformId: number;
}

export const OldCookiePolicyComponent = (props: ICookiePolicyComponentProps) => {
  const [isCookieModalOpen, setIsCookieModalOpen] = useState<boolean>(false);
  const platformIdsWithNoExternalLink: number[] = [
    PlatformType.NETHERLANDS,
    PlatformType.BELGIANFRENCH,
    PlatformType.BELGIANDUTCH
  ];
  const showExternalLink = !platformIdsWithNoExternalLink.includes(props.platformId);

  useEffect(() => {
    if (!localStorage.getItem("userAcceptedCookieModal")) {
      setIsCookieModalOpen(true);
    }
  }, []);

  const closeCookieModal = () => {
    localStorage.setItem("userAcceptedCookieModal", "true");
    setIsCookieModalOpen(false);
  };

  return (
    <div>
      <OldCookiePopupComponent
        isOpen={isCookieModalOpen}
        onClose={() => {
          closeCookieModal();
        }}
        closeText={formatMessage({
          id: "cookiePopup.buttonText"
        })}
      >
        <ResourceTextComponent resourceKey={"cookiePopup.text"} />{" "}
        {showExternalLink ? (
          <a
            href={formatMessage({
              id: "global.cookieLink"
            })}
          >
            <ResourceTextComponent resourceKey={"cookiePopup.linkText"} />
          </a>
        ) : (
          <Link
            to={formatMessage({
              id: "global.cookieLink"
            })}
          >
            <ResourceTextComponent resourceKey={"cookiePopup.linkText"} />
          </Link>
        )}
      </OldCookiePopupComponent>
    </div>
  );
};
