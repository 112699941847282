import React, { useEffect, useState, useRef } from "react";
import clsx from "clsx";
import BottomScrollListener from "react-bottom-scroll-listener";
import styles from "./deal-overview-component.module.scss";
import { BannerComponent } from "@app/core/banner";
import { PageProgressBarComponent } from "@app/core/page-progress-bar";
import { StickyContainer, Sticky } from "react-sticky";
import {
  IExtendedBannerDTO,
  getBannersFromLocalStorage,
  getOffset,
  saveBannerSeenInLocalStorage,
  usePrevious,
  useScrollPosition,
  addFiltersToGTM
} from "@app/util";

import { CtaSmallComponent } from "@app/core/cta-small/cta-small.component";
import { FilterItem } from "@app/api/core/filter/filter-item";
import { DealOverviewModule } from "@app/api/modules/deal-overview/deal-overview.module";
import {
  NAVIGATION_HEIGHT_DEFAULT,
  SHOW_AD_EVERY_LINES,
  FIRST_DEAL_TAKE,
  DEAL_TAKE,
  BOTTOM_SCROLL_DEBOUNCE,
  NAVIGATION_HEIGHT_MOBILE_SEARCH
} from "@app/constants/overviews-numbers";
import { AnimatedEllipsisLoader } from "@app/core/animated-ellipsis-loader/animated-ellipsis-loader.component";
import { FilterBarDeals } from "@app/core/filter-bar-deals";
import { DealCardWrapper } from "@app/modules/deal-card-wrapper/deal-card-wrapper";
import { useAppSelector } from "@app/redux/store";
import { useDispatch } from "react-redux";
import { getAllFilters, getBanners, getDeals } from "@app/redux/thunks/deal-overview.thunk";
import { clearAllFilters, setCurrentTotalDeals } from "@app/redux/reducers/deals";
import { ViewType } from "@app/redux/reducers/settings";
import { IOutFeaturedDealDTO } from "@app/core/new-deal-card";
import { OverviewTabsWrapper } from "@app/components/overview-tabs/overview-tabs-wrapper";
import { PlatformAlert } from "@app/core/platform-alert";
/* tslint:disable:no-magic-numbers */

export interface IDealOverviewComponentProps {
  dealOverviewModule: DealOverviewModule;
}

export interface IOverviewItem {
  banner: IExtendedBannerDTO | undefined;
  deal: IOutFeaturedDealDTO;
}

let previousSkip: undefined | number;

const DealOverview = (props: IDealOverviewComponentProps) => {
  const dispatch = useDispatch();
  const { screenSize, platformId } = useAppSelector(state => state.settings);
  const {
    categoryFilterItems,
    brandFilterItems,
    merchantFilterItems,
    deals,
    totalResults,
    orderByValues,
    sortBy,
    banners: rdxBanners
  } = useAppSelector(state => state.dealOverview);

  const { overviewMessages } = props.dealOverviewModule;
  const topDivRef = useRef<any>(null);
  const mainDivRef = useRef<any>(null);
  const isFirstRender = useRef(true);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [progressPage, setProgressPage] = useState<number>(1);
  const [overviewItems, setOverviewItems] = useState<IOverviewItem[] | undefined>(undefined);
  const [totalHeight, setTotalHeight] = useState<number>(0);
  const [showSticky, setShowSticky] = useState<boolean>(true);
  const [banners, setBanners] = useState<IExtendedBannerDTO[]>([]);
  const [isMobileFilterOpened, setIsMobileFilterOpened] = useState<boolean>(false);
  const bottomCallbackRef = useRef<HTMLDivElement>(null);
  const [offSet, setOffset] = useState<number>(0);

  const scrollPos = useScrollPosition(0);
  const prevScrollPosition = usePrevious(scrollPos) || 0;
  useEffect(() => {
    if (prevScrollPosition > scrollPos) {
      setShowSticky(true);
    } else if (prevScrollPosition < scrollPos) {
      setShowSticky(false);
    }

    setNewOffset();
  }, [scrollPos]);

  const viewType = screenSize ? screenSize.viewType : ViewType.Desktop;
  const isTablet =
    viewType === ViewType.Mobile ||
    viewType === ViewType.MobileBig ||
    viewType === ViewType.Tablet ||
    viewType === ViewType.Desktop;

  useEffect(() => {
    window.scroll(0, 0);
    setInitialValues();
    setShowSticky(false);

    isFirstRender.current = false;
  }, []);

  useEffect(() => {
    const height = getTotalHeight(viewType, totalResults);
    const amountOfBanners = Math.floor(totalResults / getAmountOfAdsPerItems(viewType));
    const bannerHeight = amountOfBanners * getBannerHeight(viewType);
    const allItemsHeight = height + bannerHeight;
    if (allItemsHeight > 0) {
      setTotalHeight(allItemsHeight);
    }
  }, [screenSize, totalResults]);

  useEffect(() => {
    if (
      filtersAreDifferent() ||
      allFiltersAndDealsAreEmpty([categoryFilterItems, brandFilterItems, merchantFilterItems], deals)
    ) {
      setProgressPage(1);
      previousSkip = 0;
      const allFiltersCombined = [
        ...categoryFilterItems.filter(item => item.isSelected),
        ...brandFilterItems.filter(item => item.isSelected),
        ...merchantFilterItems.filter(item => item.isSelected)
      ];
      addFiltersToGTM(allFiltersCombined);
      dispatch(getBanners(platformId, categoryFilterItems, brandFilterItems));

      if (!isFirstRender) dispatch(setCurrentTotalDeals(0));

      const actualScrollPosition = getActualScrollPosition();
      if (mainDivRef && mainDivRef.current && actualScrollPosition > 0) {
        window.scroll(0, 0);
      }
    }
  }, [brandFilterItems, categoryFilterItems, merchantFilterItems, sortBy]);

  const prevOrderBy = usePrevious(orderByValues);
  useEffect(() => {
    if (prevOrderBy !== null) {
      setProgressPage(1);
      previousSkip = 0;

      const actualScrollPosition = getActualScrollPosition();
      if (mainDivRef && mainDivRef.current && actualScrollPosition > 0) {
        window.scroll(0, 0);
      }

      dispatch(getBanners(platformId, categoryFilterItems, brandFilterItems));
    }
  }, [orderByValues]);

  useEffect(() => {
    if (deals.length > 0) {
      const overviewItemsResult = getOverviewItems(viewType, deals, banners);
      setOverviewItems(overviewItemsResult);
    } else {
      setIsLoading(false);
    }
  }, [deals, screenSize, banners]);

  useEffect(() => {
    const extendedBanners: IExtendedBannerDTO[] = getBannersFromLocalStorage(rdxBanners);

    setBanners(extendedBanners);
  }, [rdxBanners]);

  const bottomPageCallback = () => {
    const newSkip = FIRST_DEAL_TAKE + (progressPage - 1) * DEAL_TAKE;
    if (deals.length < totalResults && !isFirstRender.current && previousSkip !== newSkip) {
      // totalcurrentmerchants is async and will sometimes give the wrong amount;
      // const skip = props.totalCurrentDeals || 0;
      previousSkip = newSkip;
      setIsLoading(true);
      setProgressPage(progressPage + 1);
      dispatch(
        getDeals(
          newSkip,
          DEAL_TAKE,
          categoryFilterItems,
          brandFilterItems,
          merchantFilterItems,
          platformId,
          orderByValues ? orderByValues[1] : undefined,
          orderByValues ? orderByValues[0] : undefined
        )
      );
    } else {
      setIsLoading(false);
    }
  };

  const setNewOffset = () => {
    if (bottomCallbackRef?.current) {
      const distanceFromTop = bottomCallbackRef.current.offsetTop;
      const totalWindowHeight = window.document.body.offsetHeight;

      let newOffset = totalWindowHeight - distanceFromTop;
      newOffset += 500; // add 500 pixels to the actual offset from the bottom to load merchants before loader comes into view.

      if (newOffset !== offSet) {
        setOffset(newOffset);
      }
    }
  };

  const prevCategoryFilterItems = usePrevious(categoryFilterItems);
  const prevBrandFilterItems = usePrevious(brandFilterItems);
  const prevMerchantFilterItems = usePrevious(merchantFilterItems);
  const filtersAreDifferent = (): boolean => {
    if (!prevCategoryFilterItems && !prevBrandFilterItems && !prevMerchantFilterItems) {
      return false;
    }

    const categoryFiltersAreDifferent = singleFiltersAreDifferent(prevCategoryFilterItems || [], categoryFilterItems);
    const brandFiltersAreDifferent = singleFiltersAreDifferent(prevBrandFilterItems || [], brandFilterItems);
    const merchantFiltersAreDifferent = singleFiltersAreDifferent(prevMerchantFilterItems || [], merchantFilterItems);

    return categoryFiltersAreDifferent || brandFiltersAreDifferent || merchantFiltersAreDifferent;
  };

  const getActualScrollPosition = () => {
    const position = getOffset(mainDivRef.current);
    const currentScrollPosition = document.body.scrollTop || document.documentElement.scrollTop;
    const actualScrollPosition = currentScrollPosition - position;

    return actualScrollPosition;
  };

  const mobileFilterToggle = (isOpen: boolean) => {
    setIsMobileFilterOpened(isOpen);
  };

  const overviewItemsToUse = overviewItems || getOverviewItems(viewType, deals, []);

  const setInitialValues = () => {
    if (allFiltersAndDealsAreEmpty([categoryFilterItems, brandFilterItems, merchantFilterItems], deals, true)) {
      dispatch(getAllFilters(platformId));
    }

    dispatch(getBanners(platformId));
  };

  return (
    <div>
      <div className={styles["deals-overview__tab"]} ref={topDivRef}>
        <OverviewTabsWrapper
          tabs={props.dealOverviewModule.tabs}
          switchButtons={props.dealOverviewModule.switchButtons}
        />
      </div>
      <StickyContainer>
        <div ref={mainDivRef}>
          <Sticky>
            {({ style, isSticky }) => (
              <div
                style={{
                  ...style,
                  transform: isMobileFilterOpened ? "none" : "translateZ(0px)",
                  marginTop:
                    isSticky && showSticky
                      ? isTablet
                        ? NAVIGATION_HEIGHT_MOBILE_SEARCH
                        : NAVIGATION_HEIGHT_DEFAULT
                      : 0,
                  transition: "0s",
                  zIndex: 99
                }}
                className={clsx(styles.filterBar, styles.filterBarBackground)}
              >
                <FilterBarDeals onMobileFilterOpen={mobileFilterToggle} />
                {isSticky && <PageProgressBarComponent totalHeight={totalHeight} mainDivRef={mainDivRef} />}
              </div>
            )}
          </Sticky>
          <PlatformAlert platform={props.dealOverviewModule.platform} />
        </div>
        <div className={styles["deal-overview"]}>
          <div className="uk-container">
            {overviewMessages.onTop.isActive && (
              <div className={styles["no-black-friday"]}>
                <h2>{overviewMessages.onTop.message}</h2>
              </div>
            )}
            {deals && deals.length > 0 ? (
              <div className={styles["deals-overview__body__list"]}>
                {overviewItemsToUse.map((overviewItem, index) => {
                  const { deal, banner } = overviewItem;

                  return (
                    <React.Fragment key={index}>
                      <div
                        className={clsx(
                          styles[`deals-overview__body__cards`],
                          styles["deals-overview__body__cards__new"]
                        )}
                      >
                        <DealCardWrapper deal={deal} viewType={viewType} />
                      </div>
                      {banner && (
                        <div
                          className={clsx(
                            styles["deals-overview__body__banner"],
                            styles["deals-overview__body__banner__new"]
                          )}
                        >
                          <BannerComponent
                            banner={banner}
                            showAlternativeBanner={banner.showAlternativeBanner}
                            variant="default"
                          />
                        </div>
                      )}
                    </React.Fragment>
                  );
                })}
                <BottomScrollListener debounce={BOTTOM_SCROLL_DEBOUNCE} onBottom={bottomPageCallback} offset={offSet} />
              </div>
            ) : !allFiltersAndDealsAreEmpty([categoryFilterItems, brandFilterItems], deals) && !isLoading ? (
              <div>
                <CtaSmallComponent
                  buttonTitle={overviewMessages.noResultsWithFilter.buttonText}
                  onClick={() => dispatch(clearAllFilters())}
                  ctaText={overviewMessages.noResultsWithFilter.text}
                  icon={props.dealOverviewModule.emptyStateIcon}
                />
              </div>
            ) : (
              !isLoading &&
              overviewMessages.emptyResults.isActive && (
                <div className={styles["no-black-friday"]}>
                  <h2>{overviewMessages.emptyResults.message}</h2>
                </div>
              )
            )}
            {isLoading && (
              <div className={styles["loading-spinner"]}>
                <AnimatedEllipsisLoader />
              </div>
            )}
          </div>
        </div>
        <div ref={bottomCallbackRef} />
      </StickyContainer>
    </div>
  );
};

export const getTotalHeight = (viewType: ViewType, totalStores: number) => {
  const lineHeight = getLineHeight(viewType);
  const dealsPerLine = getAmountOfItemsPerLine(viewType);
  const totalNumberOfLines = getTotalNumberOfLines(dealsPerLine, totalStores);

  return lineHeight * totalNumberOfLines;
};

const getLineHeight = (viewType: ViewType): number => {
  switch (viewType) {
    case ViewType.Tablet:
    case ViewType.Mobile:
    case ViewType.MobileBig:
      return 182;
    default:
      return 336;
  }
};

const getAmountOfItemsPerLine = (viewType: ViewType): number => {
  switch (viewType) {
    case ViewType.DesktopFull:
      return 5;
    case ViewType.DesktopLarge:
      return 4;
    case ViewType.Desktop:
      return 3;
    case ViewType.Tablet:
      return 2;
    default:
      return 1;
  }
};

const getTotalNumberOfLines = (dealsPerLine: number, totalStores: number) => {
  let result = totalStores / dealsPerLine;
  result = Math.ceil(result);

  return result;
};

const getBannerHeight = (viewType: ViewType): number => {
  switch (viewType) {
    case ViewType.DesktopFull:
      return 224;
    case ViewType.DesktopLarge:
      return 210;
    case ViewType.Desktop:
      return 182;
    case ViewType.Tablet:
      return 324;
    case ViewType.MobileBig:
      return 300;
    default:
      return 310;
  }
};

const getAmountOfAdsPerItems = (viewType: ViewType) => {
  switch (viewType) {
    case ViewType.DesktopFull:
      return SHOW_AD_EVERY_LINES * 5;

    case ViewType.DesktopLarge:
      return SHOW_AD_EVERY_LINES * 4;

    case ViewType.Desktop:
      return SHOW_AD_EVERY_LINES * 3;

    case ViewType.Mobile:
    case ViewType.MobileBig:
    case ViewType.Tablet:
      return SHOW_AD_EVERY_LINES * 2;
    default:
      return 0;
  }
};

const getOverviewItems = (
  viewType: ViewType,
  deals: IOutFeaturedDealDTO[],
  banners: IExtendedBannerDTO[]
): IOverviewItem[] => {
  bannerIndex = 0;
  const overviewItemsResult: IOverviewItem[] = [];
  const showAdEvery = getAmountOfAdsPerItems(viewType);

  let dealIndex = 1;
  let showAlternativeBanner = false;
  deals.forEach(deal => {
    if (dealIndex === showAdEvery && banners.length > 0) {
      overviewItemsResult.push({
        banner: { ...getNextBanner(banners), showAlternativeBanner },
        deal
      });
      dealIndex = 1;
      showAlternativeBanner = !showAlternativeBanner;
    } else {
      overviewItemsResult.push({
        banner:
          deals.length < showAdEvery && banners.length > 0 && dealIndex === deals.length
            ? { ...getNextBanner(banners), showAlternativeBanner }
            : undefined,
        deal
      });
      dealIndex += 1;
    }
  });

  return overviewItemsResult;
};

let bannerIndex = 0;
const getNextBanner = (banners: IExtendedBannerDTO[]): IExtendedBannerDTO => {
  if (bannerIndex >= banners.length) {
    bannerIndex = 0;
  }
  const result = banners[bannerIndex];

  saveBannerSeenInLocalStorage(result.id);

  bannerIndex += 1;

  return result;
};

const allFiltersAndDealsAreEmpty = (
  allFilterItems: FilterItem[][],
  deals: IOutFeaturedDealDTO[],
  excludeDeals?: boolean
) => {
  const totalSelecteditems = allFilterItems.reduce(
    (count, filterItems) => count + filterItems.filter(item => item.isSelected).length,
    0
  );

  if (totalSelecteditems > 0) {
    return false;
  }

  if (excludeDeals || deals.length === 0) {
    return true;
  }

  return false;
};

const singleFiltersAreDifferent = (oldFilters: FilterItem[], newFilters: FilterItem[]) => {
  for (let i = 0; i < newFilters.length; i += 1) {
    if (!oldFilters[i]) {
      return false;
    }
    if (oldFilters[i].isSelected !== newFilters[i].isSelected) {
      return true;
    }
  }

  return false;
};

export { DealOverview };
