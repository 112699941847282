import { MenuItem as IMenuItem } from "@app/api/core/menu-item/menu-item";
import React from "react";

import { useAppSelector } from "@app/redux/store";
import { PlatformType } from "@app/constants/platform";

import { LanguageSwitcher } from "../language-switcher/language-switcher";
import { NewMenuItem } from "../new-menu-item/new-menu-item";
import styles from "../new-nav-bar-component.module.scss";

interface IProps {
  items?: IMenuItem[];
}

export const MenuItems = (props: IProps) => {
  const { platform } = useAppSelector(state => state.platform);
  const platformId = platform?.id;

  const renderLanguageSwitcher = platformId === PlatformType.BELGIANDUTCH || platformId === PlatformType.BELGIANFRENCH;

  return (
    <div>
      <div className={styles.menuItems}>
        <div className={styles.items}>
          {props.items?.map((item, idx) => (
            <NewMenuItem key={idx} item={item} index={idx} />
          ))}
        </div>
        {renderLanguageSwitcher && (
          <div className={styles.languageSwitcher}>
            <LanguageSwitcher />
          </div>
        )}
      </div>
    </div>
  );
};
