import { cleanAdSenseScript } from "@app/util/clean-adsense-script";
import React, { useEffect, useRef } from "react";

export interface IGoogleAdSenseProps {
  script: string;
}

const GoogleAdSense = ({ script }: IGoogleAdSenseProps): JSX.Element => {
  const cleanedScript = cleanAdSenseScript(script);
  const scriptRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    activateAd();
  }, [scriptRef]);

  const activateAd = () => {
    if (typeof window !== "undefined" && scriptRef.current) {
      const hasIns = Array.from(scriptRef?.current?.childNodes || {}).some(n => n.nodeName === "INS");

      if (hasIns) {
        const adsbygoogle = window.adsbygoogle || [];
        adsbygoogle.push({});
      }
    }
  };

  if (!cleanedScript) {
    return <></>;
  }

  return (
    <div
      ref={scriptRef}
      dangerouslySetInnerHTML={{
        // nosemgrep we clean the html so we can safely use dangerouslySetInnerHTML
        __html: cleanedScript
      }}
    />
  );
};

export { GoogleAdSense };
