import { desktopSpacing, mobileSpacing, IModuleSpacing } from "@app/api/modules/wordpress-module/wordpress-module";
import {
  IWPSpacing,
  IWPSpacingClean,
  IModuleSpacingIndexTypes,
  IWPModule
} from "@app/api/wp-page-fetcher/module-selector/main-wp.interface";

const defaultWPSpacing: IWPSpacingClean = {
  distancePaddingTop: "none",
  distanceMarginTop: "none",
  distancePaddingBottom: "default",
  distanceMarginBottom: "none"
};

export const getWPModuleSpacing = (data?: IWPSpacing, customSpacing?: boolean): IModuleSpacing => {
  const moduleSpacing: IWPSpacing = {
    distanceMarginBottom: data?.distanceMarginBottom,
    distanceMarginTop: data?.distanceMarginTop,
    distancePaddingBottom: data?.distancePaddingBottom,
    distancePaddingTop: data?.distancePaddingTop
  };

  const cleanModuleSpacing = customSpacing
    ? getCleanModuleSpacing(moduleSpacing)
    : getCleanModuleSpacing(defaultWPSpacing);

  const bottomMargin = getSpacing(cleanModuleSpacing, "distanceMarginBottom");
  const bottomPadding = getSpacing(cleanModuleSpacing, "distancePaddingBottom");
  const topMargin = getSpacing(cleanModuleSpacing, "distanceMarginTop");
  const topPadding = getSpacing(cleanModuleSpacing, "distancePaddingTop");

  return {
    ...(bottomMargin && { bottomMargin }),
    ...(bottomPadding && { bottomPadding }),
    ...(topMargin && { topMargin }),
    ...(topPadding && { topPadding })
  };
};

const getCleanModuleSpacing = (moduleSpacing?: IWPSpacing): IWPSpacingClean => ({
  distancePaddingTop: Array.isArray(moduleSpacing?.distancePaddingTop)
    ? moduleSpacing?.distancePaddingTop?.[0]
    : moduleSpacing?.distancePaddingTop,
  distanceMarginTop: Array.isArray(moduleSpacing?.distanceMarginTop)
    ? moduleSpacing?.distanceMarginTop?.[0]
    : moduleSpacing?.distanceMarginTop,
  distancePaddingBottom: Array.isArray(moduleSpacing?.distancePaddingBottom)
    ? moduleSpacing?.distancePaddingBottom?.[0]
    : moduleSpacing?.distancePaddingBottom,
  distanceMarginBottom: Array.isArray(moduleSpacing?.distanceMarginBottom)
    ? moduleSpacing?.distanceMarginBottom?.[0]
    : moduleSpacing?.distanceMarginBottom
});

const getSpacing = (moduleSpacing: IWPSpacingClean, indexType: IModuleSpacingIndexTypes) =>
  spacingMapper[(moduleSpacing && moduleSpacing[indexType]) || "none"];

const spacingMapper = {
  none: undefined,
  default: { desktopSpacing: desktopSpacing.medium, mobileSpacing: mobileSpacing.medium },
  small: { desktopSpacing: desktopSpacing.small, mobileSpacing: mobileSpacing.small },
  large: { desktopSpacing: desktopSpacing.large, mobileSpacing: mobileSpacing.large }
};
