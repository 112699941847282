import { IPlatformSpecificInfo } from "../platform";
import { WEBSITE_COLORS } from "../colors";
import Paper from "@assets/icons/paper.svg";
import instagramIcon from "@assets/icons/new-instagram.svg";
import facebookIcon from "@assets/icons/new-facebook.svg";
import mailIcon from "@assets/icons/new-mail.svg";
import BfSpainLogo from "@assets/logos/bf-es.svg";
import FavIconSpain from "@assets/favicons/favicon-bf-es.png";
import CheckmarkIcon from "@assets/icons/usp-check-mark.svg";

export const spainPlatform: IPlatformSpecificInfo = {
  id: 12,
  domain: "blackfriday.expert",
  merchantOverviewLink: "/tiendas/",
  dealOverviewLink: "/ofertas/",
  urlAddition: "es",
  isLandingPage: false,
  defaultBackButtonText: "Atrás",
  breadcrumbHomeName: "Hogar",
  breadcrumbHomeUrl: "/es/",
  readingTimeText: {
    singular: "Minuto de lectura",
    plural: "Minutos de tiempo de lectura"
  },
  newsletterButtonTitle: "Registro",
  newsletterModalTitle: "Suscríbete al boletín",
  fallBackHeaderImage: "https://media.blackfridaynederland.nl/wordpress/20200430114414/default-blog-image-nl.jpg",
  bottomFooter: {
    items: [
      {
        title: "Contacto",
        url: "/es/contacto/"
      },
      {
        title: "Privacy",
        url: "/es/privacy/"
      },
      {
        title: "Cookies",
        url: "/es/cookies/"
      },
      {
        title: "Sitemap",
        url: "/es/sitemap/"
      }
    ],
    logo: {
      src: BfSpainLogo,
      alt: "BlackFridayExpert Spain Logo",
      title: "BlackFridayExpert Spain Logo"
    },
    logoLink: "/"
  },
  middleFooter: {
    links: [
      {
        id: "TRENDING_BLOCK",
        title: "Más sobre Black Friday",
        links: []
      },
      {
        id: "SHOPS_BLOCK",
        title: "Paginas mas visitadas",
        links: []
      },
      {
        id: "THIRD_BLOCK",
        title: "Tiendas con las mejores ofertas",
        links: []
      }
    ],
    newsletter: {
      title: "Regístrese para recibir el boletín",
      text: "Y obtén las mejores ofertas",
      buttonText: "Boletin informativo",
      buttonIcon: Paper
    },
    socialMedia: {
      id: "SOCIAL_BLOCK",
      title: "¿Quieres seguirnos?",
      socialItem: [
        {
          image: {
            src: facebookIcon,
            alt: "Facebook Icon",
            title: "Facebook Icon"
          },
          link: "https://www.facebook.com/Black-Friday-Espa%C3%B1a-108457011341390/"
        },
        {
          image: {
            src: instagramIcon,
            alt: "Instagram Icon",
            title: "Instagram Icon"
          },
          link: "https://www.instagram.com/blackfridayexpert_es/"
        },
        {
          link: "mailto:info@blackfriday.expert",
          image: {
            src: mailIcon,
            alt: "Mail Icon",
            title: "Mail Icon"
          }
        }
      ]
    }
  },
  fallbackMerchantHeader: {
    src: "https://media.blackfridaynederland.nl/wordpress/20200421102650/Black-Friday-Winkels-Nederland.jpg",
    alt: "Salvar Encabezado",
    title: "Guardar encabezado de página"
  },
  logo: {
    src: BfSpainLogo,
    alt: "Black Friday Logo",
    title: "Black Friday Logo"
  },
  uspModule: {
    uniqueSellingPoints: [
      {
        icon: CheckmarkIcon,
        link: {
          url: "/tiendas/",
          title: "Todas las acciones de lectura en un resumen"
        }
      },
      {
        icon: CheckmarkIcon,
        link: {
          url: "/ofertas/",
          title: "Obtenga las mejores ofertas con facilidad"
        }
      },
      {
        icon: CheckmarkIcon,
        link: {
          url: "/newsletter/",
          title: "No te pierdas el Black Friday"
        }
      }
    ]
  },
  storeTabs: [
    {
      href: "/tiendas/",
      title: "Tiendas",
      isSelected: true
    },
    {
      href: "/ofertas/",
      title: "Ofertas"
    }
  ],
  dealTabs: [
    {
      href: "/tiendas/",
      title: "Tiendas"
    },
    {
      href: "/ofertas/",
      title: "Ofertas",
      isSelected: true
    }
  ],
  colors: {
    "--colour-version-website": WEBSITE_COLORS.ES_RED,
    "--colour-version-website-RGB": WEBSITE_COLORS.ES_RED_RGB,
    "--colour-version-website-darken7": WEBSITE_COLORS.ES_RED_DARKEN_7,
    "--colour-version-website-darken16": WEBSITE_COLORS.ES_RED_DARKEN_16,
    "--colour-version-website-darken35": WEBSITE_COLORS.ES_RED_DARKEN_35,
    "--colour-version-website-lighten51": WEBSITE_COLORS.ES_RED_LIGHTEN_51,
    "--colour-version-website-lightAccent": WEBSITE_COLORS.ES_LIGHT_ACCENT
  },
  locale: "es-ES",
  tagManagerScriptLocation: "/static/googletagmanager-es-es.js",
  weCanTrackScriptLocation: "/static/wecantrack-es-es.js",
  favIcon: FavIconSpain,
  favIconPublic: "https://media.blackfridaynederland.nl/static/FavIcons/favicon-bf-es.png",
  gtm: "GTM-KQNPBK2"
};
