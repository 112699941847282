import { OutMerchantDTO } from "@app/bf-api";
import { combineFilters } from "@app/api/wp-page-fetcher/utils/combine-filters";
import { getBulkMerchantsApi } from "@app/middleware/api/bulk-merchants-get.api";
import { getFeaturedMerchantsApi } from "@app/middleware/api/featured-merchants-get.api";
import { FeaturedMerchantsSelectionChoiceType } from "@app/api/modules/featured-merchants/featured-merchants.module";

const DEFAULT_AMOUNT_OF_MERCHANTS = 9;

export interface IGetFeaturedMerchantsMapper {
  filterIds?: string;
  selectionChoice?: FeaturedMerchantsSelectionChoiceType;
  merchantSelection?: string[];
  merchantSelectionCount?: number;
  platformId: number;
  labelFilter?: string;
}

export const getFeaturedMerchantsMapper = async (
  props: IGetFeaturedMerchantsMapper
): Promise<OutMerchantDTO[] | undefined> => {
  const {
    platformId,
    merchantSelectionCount = DEFAULT_AMOUNT_OF_MERCHANTS,
    filterIds,
    selectionChoice,
    merchantSelection,
    labelFilter
  } = props;

  if (selectionChoice === "merchants") {
    const merchantIds = combineFilters([merchantSelection]);
    if (!merchantIds) return undefined;

    return await getBulkMerchantsApi(platformId, undefined, merchantIds);
  }

  if (labelFilter && selectionChoice === "label") {
    return await getBulkMerchantsApi(platformId, labelFilter, "");
  }

  return await getFeaturedMerchantsApi(merchantSelectionCount, filterIds, platformId);
};
