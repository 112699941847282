import { landingPagePlatform } from "./platforms/landing-page";
import { belgiumNLPlatform } from "./platforms/belgium-nl";
import { netherlandsPlatform } from "./platforms/dutch";
import { unitedKingdomPlatform } from "./platforms/united-kingdom";
import { IImage } from "@app/api/core/image";
import { UspModule } from "@app/api/core/usp";
import { BottomFooter } from "@app/api/modules/footer/models/bottom-footer";
import { MiddleFooter } from "@app/api/modules/footer/models/middle-footer";
import { ITabLink } from "@app/core/old-tab";
import { belgiumFRPlatform } from "./platforms/belgium-fr";
import { francePlatform } from "./platforms/france";
import { germanyPlatform } from "./platforms/germany";
import { swedenPlatform } from "./platforms/sweden";
import { spainPlatform } from "./platforms/spain";
import { italyPlatform } from "./platforms/italy";

export enum PlatformType {
  NETHERLANDS = 1,
  BELGIANDUTCH = 2,
  BELGIANFRENCH = 3,
  FRENCH = 4,
  LANDINGPAGE = 8,
  UNITED_KINGDOM = 9,
  GERMANY = 10,
  SWEDEN = 11,
  SPAIN = 12,
  ITALY = 13
}

export const platforms: IPlatformSpecificInfo[] = [
  landingPagePlatform,
  netherlandsPlatform,
  belgiumNLPlatform,
  belgiumFRPlatform,
  francePlatform,
  unitedKingdomPlatform,
  germanyPlatform,
  swedenPlatform,
  spainPlatform,
  italyPlatform
];

export interface IPlatformSpecificInfo {
  id: number;
  domain: string;
  merchantOverviewLink: string;
  dealOverviewLink: string;
  urlAddition: string;
  isLandingPage?: boolean;
  defaultBackButtonText: string;
  breadcrumbHomeName: string;
  breadcrumbHomeUrl: string;
  readingTimeText: {
    singular: string;
    plural: string;
  };
  newsletterButtonTitle: string;
  newsletterModalTitle: string;
  fallBackHeaderImage: string;
  bottomFooter: BottomFooter;
  middleFooter: MiddleFooter;
  fallbackMerchantHeader: IImage;
  logo: IImage;
  uspModule: UspModule;
  storeTabs: ITabLink[];
  dealTabs: ITabLink[];
  colors: { [key: string]: string };
  locale: string;
  weCanTrackScriptLocation: string;
  tagManagerScriptLocation: string;
  favIcon: string;
  favIconPublic: string;
  gtm: string;
  customCtaButtonColor?: { [key: string]: string };
}
