import React from "react";

import styles from "./seo-text-component.module.scss";
import { HtmlRenderComponent } from "@app/core/html-render";
import { SeoTextModule } from "@app/api/modules/seo-text/seo-text";

export interface ISeoTextComponentProps {
  seoTextModule: SeoTextModule;
}

const SeoTextComponent = (props: ISeoTextComponentProps) => {
  const { pageText } = props.seoTextModule;

  return (
    <div className={`${styles.seoContainer} uk-container`}>
      <div id="seobodytext" className={styles.seoDescription}>
        <HtmlRenderComponent html={pageText ? pageText : ""} />
        {/* {props.merchant.seo ? props.merchant.seo.description : ""} */}
      </div>
    </div>
  );
};

export { SeoTextComponent };
