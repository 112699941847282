import * as React from "react";
import { useSelector } from "react-redux";

import { IOutFeaturedMerchantDTO } from "@app/core/new-merchant-card/featured-merchants.interface";
import { settingsState, ViewType } from "@app/redux/reducers/settings";
import { MerchantCardV3 } from "@app/components/merchant-cards/merchant-card-v3/merchant-card-v3";

export interface IMerchantCardWrapperProps {
  merchant: IOutFeaturedMerchantDTO;
  responsive?: boolean;
  viewType: ViewType;
  onClick?: () => void;
  variant?: "normal" | "mobile-search";
}

const MerchantCardWrapper = (props: IMerchantCardWrapperProps) => {
  const settings = useSelector(settingsState);
  const { platformId } = settings;

  return (
    <MerchantCardV3
      platformId={platformId}
      onClick={props.onClick}
      merchant={props.merchant}
      viewType={props.viewType}
      responsive={props.responsive}
      variant={props.variant}
    />
  );
};

export { MerchantCardWrapper };
