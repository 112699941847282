import * as React from "react";
import styles from "./arrow-panel-component.module.scss";
import { IconComponent, ImageComponent } from "@app/core";
import { Link as ILink } from "@app/api/core/link";
import { CHEVRON_RIGHT } from "@app/constants/icons";
import { Link } from "react-router-dom";
import { IImage } from "@app/api/core/image";
import { RelAttributes } from "@app/constants";
import { checkExternalUrl } from "@app/api/wp-page-fetcher/utils/check-same-domain";
import clsx from "clsx";

export interface IArrowPanelProps {
  icon?: string;
  image?: IImage;
  link: ILink;
  isExternalUrl?: boolean;
  rounded?: boolean;
  variant?: "categoryBig" | "categorySmall";
}

const ArrowPanel = (props: IArrowPanelProps) => {
  const { image, icon, link, rounded, variant } = props;
  const cardClassName = clsx(
    styles.card,
    rounded && styles["card--rounded"],
    variant === "categoryBig" && styles["card--big"],
    variant === "categorySmall" && styles["card--small"],
    variant && styles["card--category"]
  );

  const imageClassName = clsx(image ? styles["image-container__image"] : styles["image-container__icon"]);
  const imageSrc = image?.src || icon || "";
  const linkBody = getLinkBody(imageClassName, imageSrc, link.title, image, icon, variant !== undefined);

  if (link.newTab || checkExternalUrl(link.url) || props.isExternalUrl) {
    return (
      <a
        href={link.url}
        className={cardClassName}
        target={link.newTab ? "_blank" : undefined}
        rel={link.newTab ? RelAttributes.SPONSORED : ""}
      >
        {linkBody}
      </a>
    );
  }

  return (
    <Link to={link.url} className={cardClassName}>
      {linkBody}
    </Link>
  );
};

const getLinkBody = (
  imageClassName: string,
  imageSrc: string,
  linkTitle: string,
  image?: IImage,
  icon?: string,
  noChevron?: boolean
) => (
  <>
    <div className={styles["content"]}>
      {(image?.src || icon) && (
        <div className={styles["image-container"]}>
          <ImageComponent
            alt={(image && image.alt) || ""}
            title={(image && image.title) || ""}
            forceImageTag
            className={imageClassName}
            src={imageSrc}
            isBlocking
          />
        </div>
      )}
      <div className={styles["title"]}>{linkTitle}</div>
    </div>
    {!noChevron && (
      <div className={styles["icon"]}>
        <IconComponent icon={CHEVRON_RIGHT} size="10px" strokeColor="#474747" strokeWidth="3px" />
      </div>
    )}
  </>
);

export { ArrowPanel };
