import React, { ComponentType, useEffect, useState } from "react";
import { INavBarModule } from "@app/api/modules/nav-bar/navbar.module";
import styles from "./mobile-nav-bar-component.module.scss";
import { ImageComponent } from "@app/core";
import { formatMessage } from "@app/translations/intl";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import HamburgerIcon from "@assets/icons/hamburger.svg";
import clsx from "clsx";
import { Search } from "./search/search";
import { Sticky } from "react-sticky";
import { useScrollPosition, usePrevious } from "@app/util";
import { NAVIGATION_HEIGHT_MOBILE, NAVIGATION_HEIGHT_MOBILE_SEARCH } from "@app/constants/overviews-numbers";
import { isOverviewPageHook } from "@app/util/is-overview-page";
import { NewMenuItems } from "./new-menu-items/new-menu-items.component";

export interface INavBarModuleProps {
  navBarModule: INavBarModule;
}

export interface IMobileNavBarComponentProps extends INavBarModuleProps {
  menuIsOpen: boolean;
  searchIsOpen: boolean;
  setIsMenuOpen: (state: boolean) => void;
  setSearchIsOpen: (state: boolean) => void;
  showSticky: boolean;
}

// isolate NavBar as a stateless component,
// so that React.memo (within ImageComponent) won't re-render like it shouldn't.
const NavBar = (props: IMobileNavBarComponentProps & RouteComponentProps) => {
  const navBarItemsRef = React.useRef<HTMLDivElement>(null);
  const hideWhenSearchIsOpen = !props.searchIsOpen;

  const onHomeClick = () => {
    props.history.push(formatMessage({ id: "global.homepageLink" }));
    window.scroll(0, 0);
  };

  const closeSearch = () => {
    props.setSearchIsOpen(false);
  };

  const openSearch = () => {
    window.scroll(0, 0);
    props.setSearchIsOpen(true);
  };

  const closeMenu = () => {
    props.setIsMenuOpen(false);
  };

  const toggleMenu = () => {
    props.setIsMenuOpen(!props.menuIsOpen);
  };

  useEffect(() => {
    if (props.menuIsOpen) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [props.menuIsOpen]);

  useEffect(() => {
    if (props.searchIsOpen) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [props.searchIsOpen]);

  return (
    <div className={styles.mobileNavBar}>
      {hideWhenSearchIsOpen && (
        <div className={styles.top}>
          <div className={styles.wrapper}>
            <button className={styles.hamburger} onClick={toggleMenu}>
              <img src={HamburgerIcon} alt="menu" />
            </button>
            <div className={styles.logo}>
              <a role="button" onClick={onHomeClick}>
                <ImageComponent
                  src={props.navBarModule.logo.src}
                  alt={props.navBarModule.logo.alt}
                  title={props.navBarModule.logo.title}
                  isBlocking
                />
              </a>
            </div>
          </div>
          <Link to={props.navBarModule.navBarLink || ""} className={styles.rightText}>
            <span className={styles.title}>{props.navBarModule.navBarTitle}</span>
            <span className={styles.subtitle}>{props.navBarModule.navBarSubTitle}</span>
          </Link>
        </div>
      )}

      <Search
        popularMerchants={props.navBarModule.popularMerchants}
        popularDeals={props.navBarModule.popularDeals}
        isSearchOpen={props.searchIsOpen}
        openSearch={openSearch}
        closeSearch={closeSearch}
      />

      {props.menuIsOpen && (
        <>
          <div ref={navBarItemsRef} className={clsx(styles.menu)}>
            {<NewMenuItems menuItems={props.navBarModule.menuItems} closeMenu={closeMenu} />}
          </div>
          <div role="button" className={clsx(styles.overlay)} onClick={closeMenu} />
        </>
      )}
    </div>
  );
};

const component = (props: INavBarModuleProps & RouteComponentProps) => {
  const isOverviewPage = isOverviewPageHook();
  const [isStickyHook, setIsSticky] = useState<boolean>(false);
  const [showSticky, setShowSticky] = useState<boolean>(true);
  const [searchIsOpen, setSearchIsOpen] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [topOffset, setTopOffset] = useState("0px");

  const navigationHeight = isOverviewPage ? NAVIGATION_HEIGHT_MOBILE_SEARCH : NAVIGATION_HEIGHT_MOBILE;

  const scrollPos = useScrollPosition(0);

  const prevScrollPosition = usePrevious(scrollPos) || 0;
  useEffect(() => {
    if (scrollPos < navigationHeight) {
      setIsSticky(false);
    } else {
      setIsSticky(true);
    }

    if (prevScrollPosition > scrollPos) {
      setShowSticky(true);
    } else if (prevScrollPosition < scrollPos) {
      setShowSticky(false);
    }
  }, [scrollPos]);

  useEffect(() => {
    if (searchIsOpen) {
      setTopOffset("0px");

      // tslint:disable-next-line: return-undefined
      return;
    }

    if (isOverviewPage) {
      setTopOffset("-58px");

      // tslint:disable-next-line: return-undefined
      return;
    }

    setTopOffset("0px");
  }, [isOverviewPage, searchIsOpen]);

  const height = menuIsOpen || searchIsOpen;
  const transform = showSticky || menuIsOpen;

  // tslint:disable-next-line: no-shadowed-variable

  if (isStickyHook) {
    return (
      <Sticky>
        {({ style }) => (
          <div
            style={{
              ...style,
              zIndex: 500,
              transform: transform ? "translateY(0%)" : "translateY(-100%)",
              transition: "0s",
              height: height ? "100%" : "initial",
              width: "100%",
              top: topOffset
            }}
          >
            <NavBar
              menuIsOpen={menuIsOpen}
              searchIsOpen={searchIsOpen}
              setSearchIsOpen={setSearchIsOpen}
              setIsMenuOpen={setMenuIsOpen}
              navBarModule={props.navBarModule}
              showSticky={transform}
              history={props.history}
              location={props.location}
              match={props.match}
            />
          </div>
        )}
      </Sticky>
    );
  }

  return (
    <NavBar
      menuIsOpen={menuIsOpen}
      searchIsOpen={searchIsOpen}
      setSearchIsOpen={setSearchIsOpen}
      setIsMenuOpen={setMenuIsOpen}
      navBarModule={props.navBarModule}
      showSticky={transform}
      history={props.history}
      location={props.location}
      match={props.match}
    />
  );
};

export const MobileNavBar: ComponentType<INavBarModuleProps> = withRouter(component);
